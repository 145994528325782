import React, { useEffect, useState, useRef, useContext } from "react";
import DatePicker from "react-datepicker";
import { AuditLogTypeMap, extractDate, calculateLogTime } from "../../../util/helpers";
import { AppContext } from "../../../App";

const AuditLogs = ({ user, userType }) => {
    const [auditLog, setAuditLog] = useState([]);
    const [orderDir, setOrderDir] = useState("desc");
    const [filters, setFilters] = useState([]);
    const [createdAtRange, setCreatedAtRange] = useState([null, null]);

    const [actionDropdownOpen, setActionDropdownOpen] = useState(false);
    const [selectedActions, setSelectedActions] = useState([]);
    const actionDropdownRef = useRef(null);

    const [objectDropdownOpen, setObjectDropdownOpen] = useState(false);
    const [selectedObjects, setSelectedObjects] = useState([]);
    const objectDropdownRef = useRef(null);

    const [hasError, setHasError] = useState(null);

    const { request } = useContext(AppContext);

    const actions = ["created", "updated", "deleted"];

    const objects = ["user", "document", "note", "photo"];

    useEffect(() => {
        const fetchAuditLogs = async () => {
            let filterString = "";
            if (filters.length) {
                filters.forEach((filter) => {
                    if (filter.name.includes("range")) {
                        if (filter.value[0] !== null) {
                            filterString += `&${filter.name}_min=${filter.value[0]}`;
                        }
                        if (filter.value[1] !== null) {
                            filterString += `&${filter.name}_max=${filter.value[1]}`;
                        }
                    } else if (filter.name === "objects" || filter.name === "actions") {
                        filter.value.forEach((v) => {
                            filterString += `&${filter.name}=${v}`;
                        });
                    } else {
                        filterString += `&${filter.name}=${filter.value}`;
                    }
                });
            }

            try {
                const response = await request({
                    url: `/staff/${userType}/${user.id}/logs?orderDir=${orderDir}${filterString}`,
                    method: "GET",
                });

                const { logs } = response;
                if (logs) {
                    setAuditLog(logs);
                }
            } catch (err) {
                console.log(err);
                setHasError(err);
            }
        };

        fetchAuditLogs();
    }, [request, user, userType, orderDir, filters]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (actionDropdownRef.current && !actionDropdownRef.current.contains(event.target)) {
                setActionDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (objectDropdownRef.current && !objectDropdownRef.current.contains(event.target)) {
                setObjectDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleNameSearch = (event) => {
        const search = event.target.value;

        const filteredFilters = structuredClone(filters).filter((filter) => filter.name !== "name");

        if (search.length) {
            filteredFilters.push({
                name: "name",
                value: search,
            });
        }

        setFilters(filteredFilters);
    };

    const handleDateFilter = (name, update, setState) => {
        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        if (update[0] === null && update[1] === null) {
            setState([null, null]);
        } else {
            filteredFilters.push({
                name: name,
                value: [update[0] !== null ? update[0].getTime() : null, update[1] !== null ? update[1].getTime() : null],
            });

            setState(update);
        }

        setFilters(filteredFilters);
    };

    const handleSetArray = (array, setArray, name, value) => {
        let ns = [];
        if (array.includes(value)) {
            ns = structuredClone(array).filter((s) => s !== value); // Remove if already selected
        } else {
            ns = [...structuredClone(array), value]; // Add if not selected
        }

        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);
        if (ns.length) {
            filteredFilters.push({
                name: name,
                value: ns,
            });
        }

        setFilters(filteredFilters);
        setArray(ns);
    };

    const clearAllStatuses = () => {
        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== "actions" && filter.name !== "objects");
        setFilters(filteredFilters);
        setSelectedActions([]);
        setSelectedObjects([]);
    };

    let timeMap = {};

    return (
        <div className="mx-30 mb-10">
            <div className="mb-5 ml-auto">
                <div className="p-3">
                    <input className="p-1 inline-block border border-solid ml-2 rounded-lg" onChange={handleNameSearch} placeholder="Staff name..."></input>
                    <div className="p-1 inline-block border border-solid ml-2 rounded-lg">
                        <DatePicker
                            selected={createdAtRange[0]}
                            onChange={(update) => {
                                handleDateFilter("created_at_range", update, setCreatedAtRange);
                            }}
                            selectsRange
                            isClearable
                            startDate={createdAtRange[0]}
                            endDate={createdAtRange[1]}
                            placeholderText="Creation range"
                        />
                    </div>
                    <div className="relative p-1 inline-block ml-2 rounded-lg" ref={actionDropdownRef}>
                        <button
                            onClick={() => setActionDropdownOpen(!actionDropdownOpen)}
                            className="py-1 px-2 rounded-md border bg-white text-gray-700" // Style as needed
                        >
                            Filter by Action{" "}
                            <span className="mx-2" onClick={clearAllStatuses}>
                                x
                            </span>
                        </button>

                        {actionDropdownOpen && (
                            <div className="absolute mt-2 w-full left-0 right-0 bg-white rounded-md shadow-lg divide-y divide-dotted">
                                {actions.map((action) => (
                                    <div
                                        key={action}
                                        onClick={() => handleSetArray(selectedActions, setSelectedActions, "actions", action)}
                                        className={`px-4 py-2 cursor-pointer ${selectedActions.includes(action) ? "bg-gray-100" : ""}`}
                                    >
                                        {action}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="relative p-1 inline-block ml-2 rounded-lg" ref={objectDropdownRef}>
                        <button
                            onClick={() => setObjectDropdownOpen(!objectDropdownOpen)}
                            className="py-1 px-2 rounded-md border bg-white text-gray-700" // Style as needed
                        >
                            Filter by Object{" "}
                            <span className="mx-2" onClick={clearAllStatuses}>
                                x
                            </span>
                        </button>

                        {objectDropdownOpen && (
                            <div className="absolute mt-2 w-full left-0 right-0 bg-white rounded-md shadow-lg divide-y divide-dotted">
                                {objects.map((obj) => (
                                    <div
                                        key={obj}
                                        onClick={() => handleSetArray(selectedObjects, setSelectedObjects, "objects", obj)}
                                        className={`px-4 py-2 cursor-pointer ${selectedObjects.includes(obj) ? "bg-gray-100" : ""}`}
                                    >
                                        {obj}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="border-b border-dashed border-gray-300 flex items-center">
                <h1 className={`${userType === "split-members" ? "heading-underline-forest-50" : "heading-underline-goldenrod"} heading-underline mr-5 w-fit inline-block`}>Logs</h1>
                <span>{auditLog.length} events</span>
                <div onClick={() => (orderDir === "desc" ? setOrderDir("asc") : setOrderDir("desc"))} className="ml-auto text-sm flex items-center cursor-pointer">
                    <span>Sort: {orderDir === "desc" ? "Newest First" : "Oldest First"}</span>
                    {orderDir === "desc" ? (
                        <div className="inline-block">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </div>
                    ) : (
                        <div className="inline-block">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
                            </svg>
                        </div>
                    )}
                </div>
            </div>
            <div className="rounded-md h-full self-stretch -m-px overflow-y-auto">
                {auditLog.map((activity) => {
                    let noteTime = calculateLogTime(activity.created_at);
                    if (!timeMap[noteTime]) {
                        timeMap[noteTime] = 1;
                    } else {
                        timeMap[noteTime]++;
                    }

                    return (
                        <div key={activity.id} className="flex my-3 pt-1 pl-3 border-b border-dashed border-gray-300">
                            <div className="w-24 min-w-24 mr-5">{timeMap[noteTime] === 1 ? noteTime : null}</div>
                            <div className="w-7 mr-5 mt-1 flex">
                                <h1 className="flex items-center justify-center w-7 h-7 rounded-full px-2 py-1 text-black text-sm" style={{ backgroundColor: "#D2E9E6" }}>
                                    {activity.staff ? activity.staff.firstName.charAt(0).toUpperCase() : activity.user.firstName.charAt(0).toUpperCase()}
                                </h1>
                            </div>
                            <div className="flex-grow flex flex-col text-sm pb-2">
                                <div className="flex justify-between items-center">
                                    <span>
                                        <span className="font-semibold mr-1">
                                            {activity.staff
                                                ? `${activity.staff.firstName} ${activity.staff.lastName[0]}. (staff)`
                                                : activity.user.firstName
                                                ? `${activity.user.firstName} ${activity.user.lastName[0]}.`
                                                : activity.user.email}
                                        </span>
                                        <span className="mr-1">{activity.activity}</span>
                                        <span className="font-semibold">{activity.description ? activity.description : ""}</span>
                                        <span className="font-semibold">{!activity.description && activity.target_column ? activity.target_column : ""}</span>
                                        <span className="font-semibold">
                                            {!activity.description && !activity.target_column && AuditLogTypeMap[activity.target_type] ? AuditLogTypeMap[activity.target_type] : ""}
                                        </span>
                                        <span className="font-semibold">{!activity.description && activity.target_value ? ` to ${activity.target_value.value}` : ""}</span>
                                    </span>
                                </div>
                                <div className="mr-3 mt-1 text-xs font-normal text-gray-400">{extractDate(activity.created_at)}</div>
                                {activity.target_type === "notes" ? <div className="mt-2 text-gray-400 font-normal">{activity.note.note}</div> : null}
                            </div>
                        </div>
                    );
                })}
            </div>
            {/* <table className="table-fixed w-full">
                <thead>
                    <tr>
                        <th className="w-40 text-left py-2 border-b border-dashed border-gray-300">Time</th>
                        <th className="w-1/3 text-left py-2 border-b border-dashed border-gray-300">Person</th>
                        <th className="w-1/3 text-left py-2 border-b border-dashed border-gray-300">Action</th>
                        <th className="w-1/3 text-left py-2 border-b border-dashed border-gray-300">Target</th>
                    </tr>
                </thead>
                <tbody>
                    {auditLog.map((activity) => {
                        return (
                            <tr key={activity.id}>
                                <td className="w-40 text-left py-2 border-b border-dashed border-gray-300">{extractDate(activity.created_at)}</td>
                                <td className="w-1/3 text-left py-2 border-b border-dashed border-gray-300">{activity.staff ? `${activity.staff.firstName} ${activity.staff.lastName} (staff)` : `${activity.user.firstName} ${activity.user.lastName}`}</td>
                                <td className="w-1/3 text-left py-2 border-b border-dashed border-gray-300">{activity.activity}</td>
                                <td className="w-1/3 text-left py-2 border-b border-dashed border-gray-300">{activity.target}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table> */}
        </div>
    );
};

export default AuditLogs;
