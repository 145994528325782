import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { agreementStatuses, amhStatuses, callStatuses, CycleTypes, fmStatuses, migrationStatuses, scStatuses } from "../../../util/statuses";
import { AmericanStates, AmericanStatesMap, Ethnicities, EthnicitiesMap, JewishAncestries, RaceMap, SchoolRanking } from "../../../util/helpers";
import { InputFilter } from "../../filter/InputFilter";
import { Filter } from "../../filter/Filter";
import { DropdownSearchFilter } from "../../filter/DropdownSearchFilter";
import { DropdownSelectFilter } from "../../filter/DropdownSelectFilter";
import { FlagsFilter } from "../../filter/FlagsFilter";
import { DateFilter } from "../../filter/DateFilter";
import { MinMaxFilter } from "../../filter/MinMaxFilter";
import { MultiCheckboxFilter } from "../../filter/MultiCheckboxFilter";
import { MultiButtonFilter } from "../../filter/MultiButtonFilter";
import { MultiButtonGridFilter } from "../../filter/MultiButtonGridFilter";
import { DisclosureFilter } from "../../filter/DisclosureFilter";
import { AppContext } from "../../../App";
import { DropdownFilter } from "../../filter/DropdownFilter";
import { SingleSelectCheckboxFilter } from "../../filter/SingleSelectCheckboxFilter";

const SplitMembers = ({ handleSplitMemberCount }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const orderByParam = queryParams.get("orderBy");
    const orderDirParam = queryParams.get("orderDir");
    const statusParam = queryParams.getAll("status");
    const cycleTypeParam = queryParams.getAll("cycle_type");
    const cycleFirstStatusParam = queryParams.getAll("cycle_first_status");
    const callStatusParam = queryParams.getAll("call_status");
    const agreementStatusParam = queryParams.getAll("agreement_status");
    const amhStatusParam = queryParams.getAll("amh_status");
    const migrationStatusParam = queryParams.getAll("migration_status");
    const nameParam = queryParams.get("name");
    const donorCodeParam = queryParams.get("donor_code");
    const createRangeMinParam = queryParams.get("create_range_min");
    const createRangeMaxParam = queryParams.get("create_range_max");
    const updateRangeMinParam = queryParams.get("update_range_min");
    const updateRangeMaxParam = queryParams.get("update_range_max");
    const statesParam = queryParams.getAll("states");
    const heritagesParam = queryParams.getAll("heritages");
    const flagsParam = queryParams.getAll("flags");
    const raceParam = queryParams.getAll("race");
    const hairColorParam = queryParams.getAll("hair_color");
    const hairTypeParam = queryParams.getAll("hair_type");
    const hairTextureParam = queryParams.getAll("hair_texture");
    const eyeColorParam = queryParams.getAll("eye_color");
    const complexionParam = queryParams.getAll("complexion");
    const educationParam = queryParams.getAll("education_level");
    const rankingParam = queryParams.getAll("school_ranking");
    const personalityParam = queryParams.getAll("personality");
    const aptitudeParam = queryParams.getAll("aptitude");
    const jewishAncestryParam = queryParams.get("jewish_ancestry");
    const disclosureParam = queryParams.getAll("disclosure");
    const appMinParam = queryParams.get("app_min");
    const appMaxParam = queryParams.get("app_max");
    const amhMinParam = queryParams.get("amh_min");
    const amhMaxParam = queryParams.get("amh_max");
    const heightMinParam = queryParams.get("height_min");
    const heightMaxParam = queryParams.get("height_max");

    let filterParams = [];
    if (statusParam.length) {
        filterParams.push({ name: "status", value: statusParam });
    }

    if (cycleTypeParam.length) {
        filterParams.push({ name: "cycle_type", value: cycleTypeParam });
    }

    if (cycleFirstStatusParam.length) {
        filterParams.push({ name: "cycle_first_status", value: cycleFirstStatusParam });
    }

    if (callStatusParam.length) {
        filterParams.push({ name: "call_status", value: callStatusParam });
    }

    if (agreementStatusParam.length) {
        filterParams.push({ name: "agreement_status", value: agreementStatusParam });
    }

    if (amhStatusParam.length) {
        filterParams.push({ name: "amh_status", value: amhStatusParam });
    }

    if (migrationStatusParam.length) {
        filterParams.push({ name: "migration_status", value: migrationStatusParam });
    }

    if (nameParam) {
        filterParams.push({ name: "name", value: nameParam });
    }

    if (donorCodeParam) {
        filterParams.push({ name: "donor_code", value: donorCodeParam });
    }

    if (appMinParam) {
        filterParams.push({ name: "app_min", value: appMinParam });
    }

    if (appMaxParam) {
        filterParams.push({ name: "app_max", value: appMaxParam });
    }

    if (amhMinParam) {
        filterParams.push({ name: "amh_min", value: amhMinParam });
    }

    if (amhMaxParam) {
        filterParams.push({ name: "amh_max", value: amhMaxParam });
    }

    if (heightMinParam) {
        filterParams.push({ name: "height_min", value: heightMinParam });
    }

    if (heightMaxParam) {
        filterParams.push({ name: "height_max", value: heightMaxParam });
    }

    let createRangeInit = [null, null];
    if (createRangeMinParam || createRangeMaxParam) {
        let range = [null, null];
        if (createRangeMinParam) {
            range[0] = createRangeMinParam;
            const date = new Date(Number(createRangeMinParam));
            createRangeInit[0] = date.toString();
        }
        if (createRangeMaxParam) {
            range[1] = createRangeMaxParam;
            const date = new Date(Number(createRangeMaxParam));
            createRangeInit[1] = date.toString();
        }
        filterParams.push({ name: "create_range", value: range });
    }

    let updateRangeInit = [null, null];
    if (updateRangeMinParam || updateRangeMaxParam) {
        let range = [null, null];
        if (updateRangeMinParam) {
            range[0] = updateRangeMinParam;
            const date = new Date(Number(updateRangeMinParam));
            updateRangeInit[0] = date.toString();
        }
        if (updateRangeMaxParam) {
            range[1] = updateRangeMaxParam;
            const date = new Date(Number(updateRangeMaxParam));
            updateRangeInit[1] = date.toString();
        }
        filterParams.push({ name: "update_range", value: range });
    }

    const [splitMembers, setSplitMembers] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const [showFilters, setShowFilters] = useState(false);
    const [quickFilter, setQuickFilter] = useState(null);

    const [stateFilterFocus, setStateFilterFocus] = useState(false);
    const [stateInput, setStateInput] = useState("");
    const [selectedStates, setSelectedStates] = useState(statesParam);

    const [heritageFilterFocus, setHeritageFilterFocus] = useState(false);
    const [heritageInput, setHeritageInput] = useState("");
    const [selectedHeritages, setSelectedHeritages] = useState(heritagesParam);

    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
    const [frozenTypeDropdownOpen, setFrozenTypeDropdownOpen] = useState(false);
    const [frozenDropdownOpen, setFrozenDropdownOpen] = useState(false);
    const [callDropdownOpen, setCallDropdownOpen] = useState(false);
    const [agreementDropdownOpen, setAgreementDropdownOpen] = useState(false);
    const [amhDropdownOpen, setAmhDropdownOpen] = useState(false);
    const [migrationDropdownOpen, setMigrationDropdownOpen] = useState(false);
    const [filterOptions, setFilterOptions] = useState(null);
    const [filters, setFilters] = useState(filterParams);
    const [flagsFilter, setFlagsFilter] = useState(flagsParam);
    const [raceFilter, setRaceFilter] = useState(raceParam);
    const [hairColorFilter, setHairColorFilter] = useState(hairColorParam);
    const [hairTypeFilter, setHairTypeFilter] = useState(hairTypeParam);
    const [hairTextureFilter, setHairTextureFilter] = useState(hairTextureParam);
    const [eyeColorFilter, setEyeColorFilter] = useState(eyeColorParam);
    const [complexionFilter, setComplexionFilter] = useState(complexionParam);
    const [educationFilter, setEducationFilter] = useState(educationParam);
    const [rankingFilter, setRankingFilter] = useState(rankingParam);
    const [personalityFilter, setPersonalityFilter] = useState(personalityParam);
    const [aptitudeFilter, setAptitudeFilter] = useState(aptitudeParam);
    const [jewishAncestryFilter, setJewishAncestryFilter] = useState(jewishAncestryParam ? jewishAncestryParam : "");
    const [disclosureFilter, setDisclosureFilter] = useState(disclosureParam);

    const [createDateRange, setCreateDateRange] = useState(createRangeInit);
    const [updateDateRange, setUpdateDateRange] = useState(updateRangeInit);
    const [selectedStatuses, setSelectedStatuses] = useState(statusParam);
    const [frozenTypes, setFrozenTypes] = useState(cycleTypeParam);
    const [frozenStatuses, setFrozenStatuses] = useState(cycleFirstStatusParam);
    const [selectedCallStatuses, setSelectedCallStatuses] = useState(callStatusParam);
    const [selectedAgreementStatuses, setSelectedAgreementStatuses] = useState(agreementStatusParam);
    const [selectedAmhStatuses, setSelectedAmhStatuses] = useState(amhStatusParam);
    const [selectedMigrationStatuses, setSelectedMigrationStatuses] = useState(migrationStatusParam);

    const [hasError, setHasError] = useState(null);

    const [orderBy, setOrderBy] = useState(orderByParam ? orderByParam : "user_updated_at");
    const [orderDir, setOrderDir] = useState(orderDirParam ? orderDirParam : "desc");
    const stateDropdownRef = useRef(null);
    const filterRef = useRef(null);
    const heritageDropdownRef = useRef(null);
    const statusDropdownRef = useRef(null);
    const frozenTypeDropdownRef = useRef(null);
    const frozenDropdownRef = useRef(null);
    const callDropdownRef = useRef(null);
    const agreementDropdownRef = useRef(null);
    const amhDropdownRef = useRef(null);
    const migrationDropdownRef = useRef(null);

    const { request } = useContext(AppContext);

    const getFilterString = useCallback(() => {
        let filterString = "";

        if (filters.length) {
            filters.forEach((filter) => {
                if (filter.name.includes("range")) {
                    if (filter.value[0] !== null) {
                        filterString += `&${filter.name}_min=${filter.value[0]}`;
                    }
                    if (filter.value[1] !== null) {
                        filterString += `&${filter.name}_max=${filter.value[1]}`;
                    }
                } else if (["status", "cycle_type", "cycle_first_status", "call_status", "agreement_status", "amh_status", "migration_status"].includes(filter.name)) {
                    filter.value.forEach((v) => {
                        filterString += `&${filter.name}=${v}`;
                    });
                } else {
                    filterString += `&${filter.name}=${filter.value}`;
                }
            });
        }

        if (selectedStates.length) {
            selectedStates.forEach((item) => (filterString += `&states=${item}`));
        }
        if (selectedHeritages.length) {
            selectedHeritages.forEach((item) => (filterString += `&heritages=${item}`));
        }
        if (flagsFilter.length) {
            flagsFilter.forEach((item) => (filterString += `&flags=${item}`));
        }
        if (raceFilter.length) {
            raceFilter.forEach((item) => (filterString += `&race=${item}`));
        }
        if (hairColorFilter.length) {
            hairColorFilter.forEach((item) => (filterString += `&hair_color=${item}`));
        }
        if (hairTypeFilter.length) {
            hairTypeFilter.forEach((item) => (filterString += `&hair_type=${item}`));
        }
        if (hairTextureFilter.length) {
            hairTextureFilter.forEach((item) => (filterString += `&hair_texture=${item}`));
        }
        if (eyeColorFilter.length) {
            eyeColorFilter.forEach((item) => (filterString += `&eye_color=${item}`));
        }
        if (complexionFilter.length) {
            complexionFilter.forEach((item) => (filterString += `&complexion=${item}`));
        }
        if (educationFilter.length) {
            educationFilter.forEach((item) => (filterString += `&education_level=${item}`));
        }
        if (rankingFilter.length) {
            rankingFilter.forEach((item) => (filterString += `&school_ranking=${item}`));
        }
        if (personalityFilter.length) {
            personalityFilter.forEach((item) => (filterString += `&personality=${item}`));
        }
        if (aptitudeFilter.length) {
            aptitudeFilter.forEach((item) => (filterString += `&aptitude=${item}`));
        }
        if (jewishAncestryFilter.length) {
            filterString += `&jewish_ancestry=${jewishAncestryFilter}`;
        }
        if (disclosureFilter.length) {
            disclosureFilter.forEach((item) => (filterString += `&disclosure=${item}`));
        }

        window.history.replaceState(null, "", `?page=${page}${filterString}&orderBy=${orderBy}&orderDir=${orderDir}`);

        return filterString;
    }, [
        filters,
        flagsFilter,
        raceFilter,
        hairColorFilter,
        hairTypeFilter,
        hairTextureFilter,
        eyeColorFilter,
        complexionFilter,
        educationFilter,
        rankingFilter,
        personalityFilter,
        aptitudeFilter,
        jewishAncestryFilter,
        disclosureFilter,
        selectedStates,
        selectedHeritages,
        orderBy,
        orderDir,
        page,
    ]);

    useEffect(() => {
        document.title = "Staff | Split Members";
        const fetchSplitMembers = async () => {
            const filterString = getFilterString();

            try {
                const response = await request({
                    url: `/staff/split-members?page=${page}${filterString}&orderBy=${orderBy}&orderDir=${orderDir}`,
                    method: "GET",
                });

                const { splitMembers, splitMemberCount } = response;
                handleSplitMemberCount(splitMemberCount);

                splitMembers.forEach((member) => {
                    if (member.flags && member.flags.length) {
                        let counts = {
                            green: 0,
                            yellow: 0,
                            orange: 0,
                            red: 0,
                        };

                        member.flags.forEach((flag) => {
                            if (counts.hasOwnProperty(flag.color)) {
                                counts[flag.color]++;
                            }
                        });

                        member.greenFlags = counts.green;
                        member.yellowFlags = counts.yellow;
                        member.orangeFlags = counts.orange;
                        member.redFlags = counts.red;
                    }
                });

                if (page !== 1) {
                    setSplitMembers((prev) => [...prev, ...splitMembers]);
                } else {
                    setSplitMembers(splitMembers);
                }

                setHasMore(splitMembers.length > 0);
                // window.history.replaceState(null, '', `${window.location.pathname}?page=${page}${window.location.search}`)
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
                setHasError(error);
            }
        };

        fetchSplitMembers();
    }, [request, page, orderBy, orderDir, getFilterString, handleSplitMemberCount]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    useEffect(() => {
        const fetchFilterOptions = async () => {
            try {
                const response = await request({
                    url: `/staff/split-members/filter-options`,
                    method: "GET",
                });

                const { filterOptions } = response;
                setFilterOptions(filterOptions);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
            }
        };

        fetchFilterOptions();
    }, [request]);

    useEffect(() => {
        const handleScroll = () => {
            if (hasMore && Math.ceil(window.innerHeight + document.documentElement.scrollTop) >= document.documentElement.offsetHeight) {
                setPage((prevPage) => prevPage + 1);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [hasMore]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (stateDropdownRef.current && !stateDropdownRef.current.contains(event.target)) {
                setStateFilterFocus(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (migrationDropdownRef.current && !migrationDropdownRef.current.contains(event.target)) {
                setMigrationDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setShowFilters(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (heritageDropdownRef.current && !heritageDropdownRef.current.contains(event.target)) {
                setHeritageFilterFocus(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (statusDropdownRef.current && !statusDropdownRef.current.contains(event.target)) {
                setStatusDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (frozenTypeDropdownRef.current && !frozenTypeDropdownRef.current.contains(event.target)) {
                setFrozenTypeDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (frozenDropdownRef.current && !frozenDropdownRef.current.contains(event.target)) {
                setFrozenDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (callDropdownRef.current && !callDropdownRef.current.contains(event.target)) {
                setCallDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (agreementDropdownRef.current && !agreementDropdownRef.current.contains(event.target)) {
                setAgreementDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (amhDropdownRef.current && !amhDropdownRef.current.contains(event.target)) {
                setAmhDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleNameSearch = (name, event) => {
        const search = event.target.value;

        const filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        if (search.length) {
            filteredFilters.push({
                name: name,
                value: search,
            });
        }

        setPage(1);
        setFilters(filteredFilters);
    };

    const clearNameSearch = (name) => {
        const filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        setPage(1);
        setFilters(filteredFilters);
    };

    const handleInputChange = (event, setInputState) => {
        const input = event.target.value;

        setInputState(input);
    };

    const updateSelectedStates = (state) => {
        const filteredStates = structuredClone(selectedStates).filter((selected) => selected !== state);

        if (!selectedStates.includes(state)) {
            filteredStates.push(state);
        }

        setPage(1);
        setStateInput("");
        setSelectedStates(filteredStates);
        // setStateFilterFocus(false)
    };

    const updateSelectedHeritages = (heritage) => {
        const filteredHeritages = structuredClone(selectedHeritages).filter((selected) => selected !== heritage);

        if (!selectedHeritages.includes(heritage)) {
            filteredHeritages.push(heritage);
        }

        setPage(1);
        setHeritageInput("");
        setSelectedHeritages(filteredHeritages);
        setHeritageFilterFocus(false);
    };

    const handleDateFilter = (name, update, setState) => {
        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        if (update[0] === null && update[1] === null) {
            setState([null, null]);
        } else {
            filteredFilters.push({
                name: name,
                value: [update[0] !== null ? update[0].getTime() : null, update[1] !== null ? update[1].getTime() : null],
            });

            setState(update);
        }

        setPage(1);
        setFilters(filteredFilters);
    };

    const handleOrdering = (prop) => {
        if (orderBy === prop) {
            if (orderDir === "desc") {
                setOrderDir("asc");
            } else {
                setOrderDir("desc");
            }
        } else {
            if (prop === "last_name") {
                setOrderBy(prop);
                setOrderDir("asc");
            } else {
                setOrderBy(prop);
                setOrderDir("desc");
            }
        }
        setPage(1);
    };

    const handleStatusClick = (statusArray, setStatusArray, setDropdownOpen, name, status) => {
        let ns = [];
        if (statusArray.includes(status)) {
            ns = structuredClone(statusArray).filter((s) => s !== status); // Remove if already selected
        } else {
            ns = [...structuredClone(statusArray), status]; // Add if not selected
        }

        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);
        if (ns.length) {
            filteredFilters.push({
                name: name,
                value: ns,
            });
        }
        setPage(1);
        setFilters(filteredFilters);
        setStatusArray(ns);
        setDropdownOpen(false);
    };

    const clearAllStatuses = (name, setStatusArray) => {
        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);
        setPage(1);
        setFilters(filteredFilters);
        setStatusArray([]);
    };

    const setFilterArray = (stateArray, setStateArray, value) => {
        let filterArray = structuredClone(stateArray);

        if (filterArray.includes(value)) {
            filterArray = filterArray.filter((item) => item !== value);
        } else {
            filterArray.push(value);
        }

        setStateArray(filterArray);
        setPage(1);
    };

    const setMinMax = (name, event) => {
        let val = event.target.value;

        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        if (val.length) {
            if (name === "height_min" || name === "height_max") {
                val = val.replace(/[^0-9']/g, "");
                const h = filters.filter((f) => f.name === name);
                if (h.length && val.length < h[0].value.length && h[0].value.endsWith("'") && !val.endsWith("'")) {
                    // Do nothing, allow the single quote deletion
                } else if (val.length > 0 && !val.includes("'")) {
                    const lastChar = val[val.length - 1];
                    if (!isNaN(lastChar)) {
                        val += "'";
                    }
                }
            }

            filteredFilters.push({
                name: name,
                value: val,
            });
        }

        setPage(1);
        setFilters(filteredFilters);
    };

    const clearMinMax = (name) => {
        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        setPage(1);
        setFilters(filteredFilters);
    };

    const extractDate = (dateTimeString) => {
        const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
        const formattedDate = new Date(dateTimeString).toLocaleDateString("en-US", options);
        return formattedDate;
    };

    const clearAllFilters = () => {
        setFilters([]);
        setRaceFilter([]);
        setHairColorFilter([]);
        setHairTypeFilter([]);
        setHairTextureFilter([]);
        setEyeColorFilter([]);
        setComplexionFilter([]);
        setEducationFilter([]);
        setRankingFilter([]);
        setPersonalityFilter([]);
        setAptitudeFilter([]);
        setJewishAncestryFilter("");
        setDisclosureFilter([]);
        setCreateDateRange([null, null]);
        setUpdateDateRange([null, null]);
        setSelectedStatuses([]);
        setFrozenTypes([]);
        setFrozenStatuses([]);
        setSelectedCallStatuses([]);
        setSelectedAgreementStatuses([]);
        setSelectedAmhStatuses([]);
        setSelectedMigrationStatuses([]);
        setFlagsFilter([]);
        setStateInput("");
        setSelectedStates([]);
        setHeritageInput("");
        setSelectedHeritages([]);
        setQuickFilter(null);
        setPage(1);
    };

    const extractRaceEthnicity = (answers) => {
        const race = answers.filter((a) => a.question.slug === "race");
        const mother = answers.filter((a) => a.question.slug === "mothers_ethnicities");
        const father = answers.filter((a) => a.question.slug === "fathers_ethnicities");

        let seenEth = {};

        let full = "";
        if (race.length) {
            full += race[0].answer.value
                .map((x) => {
                    seenEth[x] = true;
                    return RaceMap[x].replace(" or ", "/");
                })
                .join(", ");
        }

        if (mother.length) {
            mother[0].answer.value.forEach((x) => {
                if (x.ethnicity === "african_american" && seenEth["black_african_american"]) {
                    return;
                } else if (x.ethnicity === "alaskan_native" && seenEth["indigenous_american_alaska_native"]) {
                    return;
                } else if (x.ethnicity === "indigenous_american" && seenEth["indigenous_american_alaska_native"]) {
                    return;
                }

                seenEth[x.ethnicity] = true;
                full += ", " + EthnicitiesMap[x.ethnicity];
            });
        }
        if (father.length) {
            father[0].answer.value.forEach((x) => {
                if (x.ethnicity === "african_american" && seenEth["black_african_american"]) {
                    return;
                } else if (x.ethnicity === "alaskan_native" && seenEth["indigenous_american_alaska_native"]) {
                    return;
                } else if (x.ethnicity === "indigenous_american" && seenEth["indigenous_american_alaska_native"]) {
                    return;
                } else if (seenEth[x.ethnicity]) {
                    return;
                }

                seenEth[x.ethnicity] = true;
                full += ", " + EthnicitiesMap[x.ethnicity];
            });
        }

        return full;
    };

    const clearDropdownSearch = (setInput, setSelected) => {
        setPage(1);
        setInput("");
        setSelected([]);
    };

    const configureQuickFilter = ({ name = "", status = [], cycleType = [], cycleStatus = [], callStatus = [], agreeStatus = [], amhStatus = [], createdAt = [] }) => {
        if (quickFilter && quickFilter === name) {
            clearAllFilters();
            return;
        }
        clearAllFilters();
        setQuickFilter(name);

        let filteredFilters = [];
        if (status.length) {
            filteredFilters.push({
                name: "status",
                value: status,
            });
        }

        if (cycleType.length) {
            filteredFilters.push({
                name: "cycle_type",
                value: cycleType,
            });
        }

        if (cycleStatus.length) {
            filteredFilters.push({
                name: "cycle_first_status",
                value: cycleStatus,
            });
        }

        if (callStatus.length) {
            filteredFilters.push({
                name: "call_status",
                value: callStatus,
            });
        }

        if (agreeStatus.length) {
            filteredFilters.push({
                name: "agreement_status",
                value: agreeStatus,
            });
        }

        if (amhStatus.length) {
            filteredFilters.push({
                name: "amh_status",
                value: amhStatus,
            });
        }

        if (createdAt.length) {
            filteredFilters.push({
                name: "create_range",
                value: createdAt,
            });
        } else {
            createdAt = [null, null];
        }

        setSelectedStatuses(status);
        setFrozenTypes(cycleType);
        setFrozenStatuses(cycleStatus);
        setSelectedCallStatuses(callStatus);
        setSelectedAgreementStatuses(agreeStatus);
        setSelectedAmhStatuses(amhStatus);
        setCreateDateRange(createdAt);
        setFilters(filteredFilters);
        setPage(1);
    };

    const statusValReturn = (target, statuses) => {
        return statuses.filter((s) => s.value === target)[0].value;
    };

    return (
        <div className="mx-10 mb-20">
            <div className="mb-5" style={{ position: "fixed", top: 80, right: 20, zIndex: 10 }}>
                <button
                    className="bg-white text-secondary border border-secondary rounded-full py-2 px-6 font-semibold hover:bg-green-100 focus:outline-none focus:ring focus:ring-green-200"
                    onClick={() => setShowFilters(!showFilters)}
                >
                    Filters
                </button>
                {showFilters ? (
                    <Filter isStaff={true} filterRef={filterRef} clearAllFilters={clearAllFilters} showFilters={showFilters} setShowFilters={setShowFilters} haveFilters={true}>
                        {[
                            <InputFilter name="name" title="Name" placeholder="Patient Name..." clearState={clearNameSearch} clearValue="name" filters={filters} handleNameSearch={handleNameSearch} />,
                            <DropdownSearchFilter
                                title="State"
                                placeholder="State..."
                                dropdownRef={stateDropdownRef}
                                filterFocus={stateFilterFocus}
                                setFilterFocus={setStateFilterFocus}
                                input={stateInput}
                                setInput={setStateInput}
                                handleInputChange={handleInputChange}
                                options={AmericanStates}
                                optionsMap={AmericanStatesMap}
                                selected={selectedStates}
                                setSelected={setSelectedStates}
                                updateSelected={updateSelectedStates}
                                clearSelected={clearDropdownSearch}
                            />,
                            <DropdownSelectFilter
                                title="Status"
                                name="status"
                                label="Filter by status"
                                dropdownRef={statusDropdownRef}
                                dropdownOpen={statusDropdownOpen}
                                setDropdownOpen={setStatusDropdownOpen}
                                options={fmStatuses}
                                selected={selectedStatuses}
                                setSelected={setSelectedStatuses}
                                handleSelection={handleStatusClick}
                                clearSelected={clearAllStatuses}
                            />,
                            <DropdownSelectFilter
                                title="Cycle Type"
                                name="cycle_type"
                                label="Filter by frozen type"
                                dropdownRef={frozenTypeDropdownRef}
                                dropdownOpen={frozenTypeDropdownOpen}
                                setDropdownOpen={setFrozenTypeDropdownOpen}
                                options={CycleTypes}
                                selected={frozenTypes}
                                setSelected={setFrozenTypes}
                                handleSelection={handleStatusClick}
                                clearSelected={clearAllStatuses}
                            />,
                            <DropdownSelectFilter
                                title="Frozen Status"
                                name="cycle_first_status"
                                label="Filter by frozen status"
                                dropdownRef={frozenDropdownRef}
                                dropdownOpen={frozenDropdownOpen}
                                setDropdownOpen={setFrozenDropdownOpen}
                                options={scStatuses}
                                selected={frozenStatuses}
                                setSelected={setFrozenStatuses}
                                handleSelection={handleStatusClick}
                                clearSelected={clearAllStatuses}
                            />,
                            <DropdownSelectFilter
                                title="Call Status"
                                name="call_status"
                                label="Filter by call"
                                dropdownRef={callDropdownRef}
                                dropdownOpen={callDropdownOpen}
                                setDropdownOpen={setCallDropdownOpen}
                                options={callStatuses}
                                selected={selectedCallStatuses}
                                setSelected={setSelectedCallStatuses}
                                handleSelection={handleStatusClick}
                                clearSelected={clearAllStatuses}
                            />,
                            <DropdownSelectFilter
                                title="Agreement Status"
                                name="agreement_status"
                                label="Filter by agreement"
                                dropdownRef={agreementDropdownRef}
                                dropdownOpen={agreementDropdownOpen}
                                setDropdownOpen={setAgreementDropdownOpen}
                                options={agreementStatuses}
                                selected={selectedAgreementStatuses}
                                setSelected={setSelectedAgreementStatuses}
                                handleSelection={handleStatusClick}
                                clearSelected={clearAllStatuses}
                            />,
                            <DropdownSelectFilter
                                title="AMH Status"
                                name="amh_status"
                                label="Filter by amh"
                                dropdownRef={amhDropdownRef}
                                dropdownOpen={amhDropdownOpen}
                                setDropdownOpen={setAmhDropdownOpen}
                                options={amhStatuses}
                                selected={selectedAmhStatuses}
                                setSelected={setSelectedAmhStatuses}
                                handleSelection={handleStatusClick}
                                clearSelected={clearAllStatuses}
                            />,
                            <DropdownSelectFilter
                                title="Migration Status"
                                name="migration_status"
                                label="Filter by migration status"
                                dropdownRef={migrationDropdownRef}
                                dropdownOpen={migrationDropdownOpen}
                                setDropdownOpen={setMigrationDropdownOpen}
                                options={migrationStatuses}
                                selected={selectedMigrationStatuses}
                                setSelected={setSelectedMigrationStatuses}
                                handleSelection={handleStatusClick}
                                clearSelected={clearAllStatuses}
                            />,
                            <FlagsFilter setFilterArray={setFilterArray} flagsFilter={flagsFilter} setFlagsFilter={setFlagsFilter} />,
                            <DateFilter
                                name="create_range"
                                title="Created at"
                                placeholder="Created at"
                                dateRange={createDateRange}
                                setDateRange={setCreateDateRange}
                                handleDateFilter={handleDateFilter}
                            />,
                            <DateFilter
                                name="update_range"
                                title="Last updated"
                                placeholder="Last updated"
                                dateRange={updateDateRange}
                                setDateRange={setUpdateDateRange}
                                handleDateFilter={handleDateFilter}
                            />,
                            <MinMaxFilter title="App Completion" filters={filters} nameMin="app_min" nameMax="app_max" setMinMax={setMinMax} clearState={clearMinMax} />,
                            <MinMaxFilter title="AMH Level" filters={filters} nameMin="amh_min" nameMax="amh_max" setMinMax={setMinMax} clearState={clearMinMax} />,
                            <InputFilter
                                name="donor_code"
                                title="Donor Code"
                                placeholder="Input 3 letter donor code..."
                                clearState={clearNameSearch}
                                clearValue="donor_code"
                                filters={filters}
                                handleNameSearch={handleNameSearch}
                            />,
                            <MultiCheckboxFilter title="Race" options={filterOptions.races} grid={false} setFilterArray={setFilterArray} selected={raceFilter} setSelected={setRaceFilter} />,
                            <DropdownSearchFilter
                                title="Heritages"
                                placeholder="Heritage..."
                                dropdownRef={heritageDropdownRef}
                                filterFocus={heritageFilterFocus}
                                setFilterFocus={setHeritageFilterFocus}
                                input={heritageInput}
                                setInput={setHeritageInput}
                                handleInputChange={handleInputChange}
                                options={Ethnicities}
                                optionsMap={EthnicitiesMap}
                                selected={selectedHeritages}
                                setSelected={setSelectedHeritages}
                                updateSelected={updateSelectedHeritages}
                                clearSelected={clearDropdownSearch}
                            />,
                            <SingleSelectCheckboxFilter title="Jewish Ancestry" options={JewishAncestries} setPage={setPage} selected={jewishAncestryFilter} setSelected={setJewishAncestryFilter} />,
                            // <DropdownFilter title="Jewish Ancestry" options={JewishAncestries} selected={jewishAncestryFilter} defaultOption={"All donors"} setSelected={setJewishAncestryFilter} />,
                            <MinMaxFilter title="Height" isText={true} filters={filters} nameMin="height_min" nameMax="height_max" setMinMax={setMinMax} clearState={clearMinMax} />,
                            <MultiCheckboxFilter
                                title="Hair Color"
                                options={filterOptions.hairColors}
                                grid={true}
                                setFilterArray={setFilterArray}
                                selected={hairColorFilter}
                                setSelected={setHairColorFilter}
                            />,
                            <MultiButtonFilter
                                title="Hair Style"
                                options={filterOptions.hairTypes}
                                width="w-[20%]"
                                setFilterArray={setFilterArray}
                                selected={hairTypeFilter}
                                setSelected={setHairTypeFilter}
                            />,
                            <MultiButtonFilter
                                title="Hair Texture"
                                options={filterOptions.hairTextures}
                                width="w-[30%]"
                                setFilterArray={setFilterArray}
                                selected={hairTextureFilter}
                                setSelected={setHairTextureFilter}
                            />,
                            <MultiCheckboxFilter
                                title="Eye Color"
                                options={filterOptions.eyeColors}
                                grid={true}
                                setFilterArray={setFilterArray}
                                selected={eyeColorFilter}
                                setSelected={setEyeColorFilter}
                            />,
                            <MultiCheckboxFilter
                                title="Complexion"
                                options={filterOptions.complexions}
                                grid={true}
                                setFilterArray={setFilterArray}
                                selected={complexionFilter}
                                setSelected={setComplexionFilter}
                            />,
                            <MultiCheckboxFilter
                                title="Level of Education"
                                options={filterOptions.education}
                                grid={false}
                                setFilterArray={setFilterArray}
                                selected={educationFilter}
                                setSelected={setEducationFilter}
                            />,
                            <MultiCheckboxFilter title="School Ranking" options={SchoolRanking} grid={false} setFilterArray={setFilterArray} selected={rankingFilter} setSelected={setRankingFilter} />,
                            <MultiButtonGridFilter
                                title="Personality Traits"
                                items={[{ slug: "logical" }, { slug: "creative" }, { slug: "silly" }, { slug: "serious" }, { slug: "introverted" }, { slug: "extroverted" }]}
                                setFilterArray={setFilterArray}
                                selected={personalityFilter}
                                setSelected={setPersonalityFilter}
                            />,
                            <MultiButtonGridFilter
                                title="Aptitude"
                                items={[
                                    { slug: "mathematical_ability", icon: "math_filter_icon" },
                                    { slug: "scientific_ability", icon: "science_filter_icon" },
                                    { slug: "athletic_ability", icon: "athletic_filter_icon" },
                                    { slug: "singing_ability", icon: "singing_filter_icon" },
                                    { slug: "artistic_ability", icon: "art_filter_icon" },
                                ]}
                                setFilterArray={setFilterArray}
                                selected={aptitudeFilter}
                                setSelected={setAptitudeFilter}
                            />,
                            <DisclosureFilter options={filterOptions.disclosures} setFilterArray={setFilterArray} selected={disclosureFilter} setSelected={setDisclosureFilter} />,
                        ]}
                    </Filter>
                ) : null}
            </div>
            <div className="flex gap-2 flex-wrap w-[90%]">
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        quickFilter === "app_sub" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() => {
                        const now = new Date();
                        const oneYearAgo = new Date();
                        oneYearAgo.setFullYear(now.getFullYear() - 1);
                        configureQuickFilter({ name: "app_sub", status: [statusValReturn("application_submission", fmStatuses)], createdAt: [oneYearAgo.getTime(), null] });
                    }}
                >
                    App Sub.
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        quickFilter === "app_sub_call" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() =>
                        configureQuickFilter({ name: "app_sub_call", status: [statusValReturn("application_submission", fmStatuses)], callStatus: [statusValReturn("call_scheduled", callStatuses)] })
                    }
                >
                    App Sub. Call
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        quickFilter === "app_sub_no_call" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() => {
                        const now = new Date();
                        const oneYearAgo = new Date();
                        oneYearAgo.setFullYear(now.getFullYear() - 1);

                        configureQuickFilter({
                            name: "app_sub_no_call",
                            status: [statusValReturn("application_submission", fmStatuses)],
                            callStatus: [statusValReturn("call_not_scheduled", callStatuses)],
                            createdAt: [oneYearAgo.getTime(), null],
                        });
                    }}
                >
                    App Sub. No Call
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        quickFilter === "app_subhold" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() => configureQuickFilter({ name: "app_subhold", status: [statusValReturn("application_subhold", fmStatuses)] })}
                >
                    App Sub. On Hold
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        quickFilter === "app_apprv_call" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() =>
                        configureQuickFilter({ name: "app_apprv_call", status: [statusValReturn("application_approved", fmStatuses)], callStatus: [statusValReturn("call_scheduled", callStatuses)] })
                    }
                >
                    App Apprv. Call
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        quickFilter === "app_apprv_no_call" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() =>
                        configureQuickFilter({
                            name: "app_apprv_no_call",
                            status: [statusValReturn("application_approved", fmStatuses)],
                            callStatus: [statusValReturn("call_not_scheduled", callStatuses)],
                        })
                    }
                >
                    App Apprv. No Call
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        quickFilter === "frozen_needs_review" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() =>
                        configureQuickFilter({
                            name: "frozen_needs_review",
                            status: [statusValReturn("application_approved", fmStatuses)],
                            cycleStatus: [statusValReturn("not_yet_reviewed_for_frozen", scStatuses)],
                        })
                    }
                >
                    FZvFS - 1stPass
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        quickFilter === "frozen_confirmation" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() =>
                        configureQuickFilter({
                            name: "frozen_confirmation",
                            status: [statusValReturn("application_approved", fmStatuses)],
                            cycleStatus: [statusValReturn("pending_review_for_frozen", scStatuses)],
                        })
                    }
                >
                    FZvFS - Confirmation
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        quickFilter === "pending_sig" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() =>
                        configureQuickFilter({
                            name: "pending_sig",
                            callStatus: [statusValReturn("call_completed", callStatuses)],
                            agreeStatus: [statusValReturn("agreement_not_sent", agreementStatuses), statusValReturn("agreement_sent", agreementStatuses)],
                        })
                    }
                >
                    Pending Sig.
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        quickFilter === "signed_pp" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() =>
                        configureQuickFilter({
                            name: "signed_pp",
                            agreeStatus: [statusValReturn("agreement_completed", agreementStatuses)],
                            status: [statusValReturn("application_approved", fmStatuses), statusValReturn("pp_completed", fmStatuses), statusValReturn("pp_revisions", fmStatuses)],
                        })
                    }
                >
                    Signed - PP
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        quickFilter === "signed_pr" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() =>
                        configureQuickFilter({
                            name: "signed_pr",
                            agreeStatus: [statusValReturn("agreement_completed", agreementStatuses)],
                            status: [statusValReturn("pp_under_review", fmStatuses), statusValReturn("pp_qa", fmStatuses)],
                        })
                    }
                >
                    Signed - PR
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        quickFilter === "amh_lab_req" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() =>
                        configureQuickFilter({
                            name: "amh_lab_req",
                            status: ["application_approved", "pp_completed", "pp_under_review", "pp_revisions", "pp_qa", "active", "matched", "on_hold", "pdf_only"],
                            agreeStatus: [statusValReturn("agreement_completed", agreementStatuses)],
                            amhStatus: [statusValReturn("amh_not_started", amhStatuses)],
                        })
                    }
                >
                    AMH Lab Order Req
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        quickFilter === "amh_pending_member" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() =>
                        configureQuickFilter({
                            name: "amh_pending_member",
                            status: ["application_approved", "pp_completed", "pp_under_review", "pp_revisions", "pp_qa", "active", "matched", "on_hold", "pdf_only"],
                            agreeStatus: [statusValReturn("agreement_completed", agreementStatuses)],
                            amhStatus: [statusValReturn("amh_lab_order_sent", amhStatuses), statusValReturn("amh_test_scheduled", amhStatuses), statusValReturn("amh_results_pending", amhStatuses)],
                        })
                    }
                >
                    AMH Pending Member
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        quickFilter === "active_amh_passed" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() =>
                        configureQuickFilter({
                            name: "active_amh_passed",
                            status: [statusValReturn("active", fmStatuses)],
                            amhStatus: [statusValReturn("amh_test_passed_fr", amhStatuses), statusValReturn("amh_test_passed_frf", amhStatuses)],
                        })
                    }
                >
                    Active - AMH Passed
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        quickFilter === "screening_cf_um" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() =>
                        configureQuickFilter({
                            name: "screening_cf_um",
                            status: [statusValReturn("active", fmStatuses), statusValReturn("on_hold", fmStatuses), "pdf_only"],
                            cycleStatus: [statusValReturn("frozen_screening", scStatuses)],
                        })
                    }
                >
                    Screen - FZ UM
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        quickFilter === "cycling_cf_um" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() =>
                        configureQuickFilter({
                            name: "cycling_cf_um",
                            status: [statusValReturn("active", fmStatuses), statusValReturn("on_hold", fmStatuses), "pdf_only"],
                            cycleStatus: [statusValReturn("frozen_first_cycling", scStatuses), statusValReturn("frozen_second_cycling", scStatuses)],
                        })
                    }
                >
                    Cycling - FZ UM
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        quickFilter === "pdf_sent_cf_um" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() =>
                        configureQuickFilter({
                            name: "pdf_sent_cf_um",
                            status: [statusValReturn("pdf_only", fmStatuses)],
                            cycleStatus: [statusValReturn("frozen_screening", scStatuses), statusValReturn("frozen_first_cycling", scStatuses), statusValReturn("frozen_second_cycling", scStatuses)],
                        })
                    }
                >
                    PDF only - FZ UM
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        quickFilter === "active_cf" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() =>
                        configureQuickFilter({
                            name: "active_cf",
                            status: [statusValReturn("active", fmStatuses)],
                            cycleStatus: [
                                statusValReturn("frozen_screening", scStatuses),
                                statusValReturn("frozen_first_cycling", scStatuses),
                                statusValReturn("frozen_second_cycling", scStatuses),
                                statusValReturn("frozen_cycle_complete", scStatuses),
                            ],
                        })
                    }
                >
                    Active - FZ
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        quickFilter === "active_fresh" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() =>
                        configureQuickFilter({
                            name: "active_fresh",
                            status: [statusValReturn("active", fmStatuses)],
                            cycleStatus: [statusValReturn("not_yet_reviewed_for_frozen", scStatuses), statusValReturn("not_a_candidate_for_frozen", scStatuses)],
                        })
                    }
                >
                    Active - FS
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        quickFilter === "on_hold_fresh" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() =>
                        configureQuickFilter({
                            name: "on_hold_fresh",
                            status: [statusValReturn("on_hold", fmStatuses)],
                            cycleStatus: [statusValReturn("not_yet_reviewed_for_frozen", scStatuses), statusValReturn("not_a_candidate_for_frozen", scStatuses)],
                        })
                    }
                >
                    On Hold - FS
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        quickFilter === "matched_fresh" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() =>
                        configureQuickFilter({
                            name: "matched_fresh",
                            status: [statusValReturn("matched", fmStatuses)],
                            cycleStatus: [statusValReturn("not_yet_reviewed_for_frozen", scStatuses), statusValReturn("not_a_candidate_for_frozen", scStatuses)],
                        })
                    }
                >
                    Matched - FS
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        quickFilter === "matched_cf" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() =>
                        configureQuickFilter({
                            name: "matched_cf",
                            status: [statusValReturn("matched", fmStatuses)],
                            cycleStatus: [
                                statusValReturn("frozen_screening", scStatuses),
                                statusValReturn("frozen_first_cycling", scStatuses),
                                statusValReturn("frozen_second_cycling", scStatuses),
                                statusValReturn("frozen_cycle_complete", scStatuses),
                            ],
                        })
                    }
                >
                    Matched - FZ
                </button>
            </div>
            <table className="table-fixed w-full mt-10">
                <thead>
                    <tr className="my-5">
                        <th className="w-[13%] text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("user_updated_at")} className="flex items-center">
                                <span className="mr-1">Last Updated</span>
                                <span className="flex items-center">
                                    {orderBy === "user_updated_at" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "user_updated_at" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-1/6 text-left py-2 border-b border-dashed border-gray-300">
                            <span className="flex items-center">
                                <span onClick={() => handleOrdering("last_name")} className="mr-1">
                                    Split Member
                                </span>
                                <span className="flex items-center">
                                    {orderBy === "last_name" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "last_name" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-1/6 text-left py-2 border-b border-dashed border-gray-300">Location</th>
                        <th className="w-1/6 text-left py-2 border-b border-dashed border-gray-300">Race/Ethnicity</th>
                        <th className="w-[250px] xl:w-[450px] text-left py-2 border-b border-dashed border-gray-300">Statuses</th>
                        <th className="w-[70px] text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("completion")} className="flex items-center">
                                <span className="mr-1">App %</span>
                                <span className="flex items-center">
                                    {orderBy === "completion" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "completion" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-[70px] text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("flags")} className="flex items-center">
                                <span className="mr-1">Flags</span>
                                <span className="flex items-center">
                                    {orderBy === "flags" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "flags" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-[70px] text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("amh_level")} className="flex items-center">
                                <span className="mr-1">AMH</span>
                                <span className="flex items-center">
                                    {orderBy === "amh_level" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "amh_level" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {splitMembers &&
                        splitMembers.map((member) => {
                            const fmStatus = fmStatuses.filter((status) => status.value === member.freeze_member.status)[0];
                            const cfStatus = scStatuses.filter((status) => status.value === member.freeze_member.cycle_first_status)[0];
                            const callStatus = callStatuses.filter((status) => status.value === member.freeze_member.call_status)[0];
                            const agreementStatus = agreementStatuses.filter((status) => status.value === member.freeze_member.agreement_status)[0];
                            const amhStatus = amhStatuses.filter((status) => status.value === member.freeze_member.amh_status)[0];
                            const migrationStatus = member.migration_status ? migrationStatuses.filter((status) => status.value === member.migration_status)[0] : null;

                            return (
                                <tr key={member.id} className="my-5">
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">{extractDate(member.updatedAt)}</td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300 text-secondary cursor-pointer">
                                        <Link className="flex" to={`/staff/split-members/${member.id}`}>
                                            <span className={`${member.user_photos ? "flex justify-center" : ""}`}>
                                                <span
                                                    className={`${member.user_photos ? "relative" : ""} w-6 h-6 rounded-full px-2 py-1 text-black text-sm mr-2`}
                                                    style={{ backgroundColor: "#D2E9E6" }}
                                                >
                                                    {member.user_photos ? (
                                                        <img
                                                            src={member.user_photos[0].cropped_source ? member.user_photos[0].cropped_source : member.user_photos[0].source}
                                                            alt={member.user_photos[0].name}
                                                            className="absolute inset-0 w-full h-full object-cover rounded-full"
                                                        />
                                                    ) : member.firstName ? (
                                                        member.firstName.charAt(0).toUpperCase()
                                                    ) : (
                                                        "?"
                                                    )}
                                                </span>
                                            </span>
                                            <span className="font-medium">
                                                {member.donorCode ? `[${member.donorCode}] ` : null} {member.firstName} {member.lastName}
                                            </span>
                                        </Link>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">
                                        <span>
                                            {member.city ? member.city : null}
                                            {member.state ? `, ${member.state}` : null}
                                        </span>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300 pr-5">
                                        <span>{member.answers ? extractRaceEthnicity(member.answers) : null}</span>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">
                                        <div className="flex flex-col xl:w-full xl:grid xl:grid-cols-2 xl:gap-x-5 gap-y-2 pr-4">
                                            <div className="text-secondary px-2 py-1 rounded-md font-medium text-sm" style={{ backgroundColor: fmStatus.bg, color: fmStatus.color }}>
                                                {fmStatus.label}
                                            </div>
                                            <div className="text-secondary px-2 py-1 rounded-md font-medium text-sm" style={{ backgroundColor: cfStatus.bg, color: cfStatus.color }}>
                                                {cfStatus.label}
                                            </div>
                                            <div className="text-secondary px-2 py-1 rounded-md font-medium text-sm" style={{ backgroundColor: callStatus.bg, color: callStatus.color }}>
                                                {callStatus.label}
                                            </div>
                                            <div className="text-secondary px-2 py-1 rounded-md font-medium text-sm" style={{ backgroundColor: agreementStatus.bg, color: agreementStatus.color }}>
                                                {agreementStatus.label}
                                            </div>
                                            <div className="text-secondary px-2 py-1 rounded-md font-medium text-sm" style={{ backgroundColor: amhStatus.bg, color: amhStatus.color }}>
                                                {amhStatus.label}
                                            </div>
                                            {migrationStatus ? (
                                                <div className="text-secondary px-2 py-1 rounded-md font-medium text-sm" style={{ backgroundColor: migrationStatus.bg, color: migrationStatus.color }}>
                                                    {migrationStatus.label}
                                                </div>
                                            ) : null}
                                        </div>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">{member.applications ? Math.floor((member.applications.length / 9) * 100) : "0"}%</td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">
                                        <div>
                                            {member.redFlags && member.redFlags !== 0 ? (
                                                <span className="flex items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" className="bi bi-flag-fill mr-2" viewBox="0 0 16 16">
                                                        <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                                                    </svg>
                                                    {member.redFlags}
                                                </span>
                                            ) : null}
                                            {member.orangeFlags && member.orangeFlags !== 0 ? (
                                                <span className="flex items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="orange" className="bi bi-flag-fill mr-2" viewBox="0 0 16 16">
                                                        <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                                                    </svg>
                                                    {member.orangeFlags}
                                                </span>
                                            ) : null}
                                            {member.yellowFlags && member.yellowFlags !== 0 ? (
                                                <span className="flex items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gold" className="bi bi-flag-fill mr-2" viewBox="0 0 16 16">
                                                        <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                                                    </svg>
                                                    {member.yellowFlags}
                                                </span>
                                            ) : null}
                                        </div>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">{member.freeze_member.amh_level}</td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};

export default SplitMembers;
