import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { IPDashboardContext } from "./Dashboard";
import DatePicker from "react-datepicker";
import { DonorRelationship, IdealTimeframe, PronounOptions } from "../../util/helpers";

const IPSettings = () => {
    const [currentTab, setCurrentTab] = useState("personal-info");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [preferredName, setPreferredName] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [pronouns, setPronouns] = useState("");
    const [pronounsOther, setPronounsOther] = useState("");

    const [partnerFirstName, setPartnerFirstName] = useState("");
    const [partnerLastName, setPartnerLastName] = useState("");
    const [partnerPreferredName, setPartnerPreferredName] = useState("");
    const [partnerDateOfBirth, setPartnerDateOfBirth] = useState(null);
    const [partnerPronouns, setPartnerPronouns] = useState("");
    const [partnerPronounsOther, setPartnerPronounsOther] = useState("");

    const [phone, setPhone] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [homeState, setHomeState] = useState("");
    const [zipcode, setZipcode] = useState("");

    const [clinic, setClinic] = useState("");
    const [doctor, setDoctor] = useState("");
    const [insurance, setInsurance] = useState("");
    const [provider, setProvider] = useState("");

    const [donorQualities, setDonorQualities] = useState("");
    const [timeframe, setTimeframe] = useState("");
    const [donorMeeting, setDonorMeeting] = useState("");
    const [donorRelationship, setDonorRelationship] = useState("");

    const [emailPref, setEmailPref] = useState("");

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const { loggedInUser, request } = useContext(AppContext);
    const { setCurrentPage } = useContext(IPDashboardContext);

    setCurrentPage("settings");

    useEffect(() => {
        setFirstName(loggedInUser.firstName);
        setLastName(loggedInUser.lastName);
        if (loggedInUser.preferredName) setPreferredName(loggedInUser.preferredName);
        if (loggedInUser.dateOfBirth) setDateOfBirth(loggedInUser.dateOfBirth);
        if (loggedInUser.pronouns) setPronouns(loggedInUser.pronouns);
        if (loggedInUser.other_pronouns) setPronounsOther(loggedInUser.other_pronouns);

        if (loggedInUser.intended_parent.partner_first_name) setPartnerFirstName(loggedInUser.intended_parent.partner_first_name);
        if (loggedInUser.intended_parent.partner_last_name) setPartnerLastName(loggedInUser.intended_parent.partner_last_name);
        if (loggedInUser.intended_parent.partner_preferred_name) setPartnerPreferredName(loggedInUser.intended_parent.partner_preferred_name);
        if (loggedInUser.intended_parent.partner_dob) setPartnerDateOfBirth(loggedInUser.intended_parent.partner_dob);
        if (loggedInUser.intended_parent.partner_pronouns) setPartnerPronouns(loggedInUser.intended_parent.partner_pronouns);
        if (loggedInUser.intended_parent.partner_other_pronouns) setPartnerPronounsOther(loggedInUser.intended_parent.partner_other_pronouns);

        if (loggedInUser.phoneNumber) setPhone(loggedInUser.phoneNumber);
        if (loggedInUser.address1) setAddress1(loggedInUser.address1);
        if (loggedInUser.address2) setAddress2(loggedInUser.address2);
        if (loggedInUser.city) setCity(loggedInUser.city);
        if (loggedInUser.state) setHomeState(loggedInUser.state);
        if (loggedInUser.zipcode) setZipcode(loggedInUser.zipcode);

        if (loggedInUser.intended_parent.family.intake_clinic) setClinic(loggedInUser.intended_parent.family.intake_clinic);
        if (loggedInUser.intended_parent.family.intake_doctor) setDoctor(loggedInUser.intended_parent.family.intake_doctor);
        if (loggedInUser.intended_parent.family.insurance_provider) setInsurance(loggedInUser.intended_parent.family.insurance_provider);
        if (loggedInUser.intended_parent.family.fertility_benefit_prov) setProvider(loggedInUser.intended_parent.family.fertility_benefit_prov);

        if (loggedInUser.intended_parent.family.donor_preferences) setDonorQualities(loggedInUser.intended_parent.family.donor_preferences);
        if (loggedInUser.intended_parent.family.ideal_timeframe) setTimeframe(loggedInUser.intended_parent.family.ideal_timeframe);
        if (loggedInUser.intended_parent.family.donor_meeting) setDonorMeeting(loggedInUser.intended_parent.family.donor_meeting);
        if (loggedInUser.intended_parent.family.donor_relationship) setDonorRelationship(loggedInUser.intended_parent.family.donor_relationship);

        if (loggedInUser.intended_parent.donor_email_pref) setEmailPref(loggedInUser.intended_parent.donor_email_pref);
    }, [loggedInUser]);

    const handleInputChange = (event, setState) => {
        setState(event.target.value);
    };

    const saveContactEdits = async () => {
        try {
            await request({
                url: `/family/${loggedInUser.id}/personal-info`,
                method: "PUT",
                data: {
                    firstName,
                    lastName,
                    preferredName,
                    dateOfBirth,
                    pronouns,
                    pronounsOther,
                    phoneNumber: phone,
                    partnerFirstName,
                    partnerLastName,
                    partnerPreferredName,
                    partnerDateOfBirth,
                    partnerPronouns,
                    partnerPronounsOther,
                    phone,
                    address1,
                    address2,
                    city,
                    state: homeState,
                    zipcode,
                },
            });
        } catch (err) {
            console.log(err);
        }
    };

    const updateEmailPreferences = async () => {
        try {
            await request({
                url: `/family/${loggedInUser.id}/email-preferences`,
                method: "PUT",
                data: {
                    emailPref: emailPref,
                },
            });
        } catch (err) {
            console.log(err);
        }
    };

    const updateInsurance = async () => {
        try {
            await request({
                url: `/family/${loggedInUser.id}/insurance`,
                method: "PUT",
                data: {
                    clinic,
                    doctor,
                    insurance,
                    provider,
                },
            });
        } catch (err) {
            console.log(err);
        }
    };

    const saveMatchPreferences = async () => {
        try {
            await request({
                url: `/family/${loggedInUser.id}/match-preferences`,
                method: "PUT",
                data: {
                    donorQualities,
                    timeframe,
                    donorMeeting,
                    donorRelationship,
                },
            });
        } catch (err) {
            console.log(err);
        }
    };

    const handleString = (s, delimter = " ") => {
        if (typeof s !== "string") {
            return s;
        }

        if (s.includes("_")) {
            return s
                .split("_")
                .map((word, i) => {
                    if (i === 0) {
                        return word[0].toUpperCase() + word.substring(1);
                    }

                    return word;
                })
                .join(delimter);
        } else if (s.length > 1) {
            return s[0].toUpperCase() + s.substring(1);
        }

        return s;
    };

    const changeTab = (tab) => {
        setCurrentTab(tab);
        setMobileMenuOpen(false);
    };

    const saveChanges = () => {
        if (currentTab === "personal-info") {
            saveContactEdits();
        } else if (currentTab === "clinic-insurance") {
            updateInsurance();
        } else if (currentTab === "match-preferences") {
            saveMatchPreferences();
        } else if (currentTab === "email-preferences") {
            updateEmailPreferences();
        } else {
            console.log("error: no matching save for tab");
        }
    };

    const handlePronounSelect = (e, setState, setStateOther) => {
        if (e.target.value !== "other") {
            setStateOther("");
        }
        setState(e.target.value);
    };

    return (
        <div>
            <div className="w-full sm:hidden flex justify-between items-center bg-[#D9CAF5] fixed top-16 sm:top-20 z-10">
                <div className="text-secondary ml-4 font-semibold">Settings</div>
                <span onClick={() => setMobileMenuOpen(!mobileMenuOpen)} className={`${mobileMenuOpen ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </span>
            </div>
            {mobileMenuOpen ? (
                <div className="w-full bg-[#AB99CB] fixed top-24 sm:top-28 z-10">
                    <div className="ml-4 cursor-pointer text-secondary" onClick={() => changeTab("personal-info")}>
                        Personal info
                    </div>
                    <div className="ml-4 cursor-pointer text-secondary" onClick={() => changeTab("clinic-insurance")}>
                        Clinic & insurance
                    </div>
                    <div className="ml-4 cursor-pointer text-secondary" onClick={() => changeTab("match-preferences")}>
                        Match preferences
                    </div>
                    <div className="ml-4 cursor-pointer text-secondary" onClick={() => changeTab("email-preferences")}>
                        Email preferences
                    </div>
                </div>
            ) : null}
            <div className="w-full min-h-screen mx-auto bg-gray-100 p-8 rounded-lg flex justify-center">
                <div className="w-full sm:w-3/4 lg:w-1/2 mt-10 sm:grid grid-cols-7">
                    <div className="hidden col-span-2 sm:flex flex-col">
                        <h1>
                            <span className="heading-underline">Settings</span>
                        </h1>
                        <h4 className="my-3">
                            <span onClick={() => setCurrentTab("personal-info")} className={`${currentTab === "personal-info" ? "font-semibold text-secondary" : ""} cursor-pointer`}>
                                Personal info
                            </span>
                        </h4>
                        <h4 className="my-3">
                            <span onClick={() => setCurrentTab("clinic-insurance")} className={`${currentTab === "clinic-insurance" ? "font-semibold text-secondary" : ""} cursor-pointer`}>
                                Clinic & insurance
                            </span>
                        </h4>
                        <h4 className="my-3">
                            <span onClick={() => setCurrentTab("match-preferences")} className={`${currentTab === "match-preferences" ? "font-semibold text-secondary" : ""} cursor-pointer`}>
                                Match preferences
                            </span>
                        </h4>
                        <h4 className="my-3">
                            <span onClick={() => setCurrentTab("email-preferences")} className={`${currentTab === "email-preferences" ? "font-semibold text-secondary" : ""} cursor-pointer`}>
                                Email preferences
                            </span>
                        </h4>
                    </div>
                    <div className="col-span-5 w-full sm:w-2/3">
                        {currentTab === "personal-info" ? (
                            <div className="flex flex-col">
                                <h4>Personal information</h4>
                                <div className="md:grid grid-cols-2 gap-2 my-2">
                                    <div>
                                        <div className="my-2">Legal first name</div>
                                        <input
                                            onChange={(event) => handleInputChange(event, setFirstName)}
                                            value={firstName}
                                            className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black"
                                        />
                                    </div>
                                    <div>
                                        <div className="my-2">Legal last name</div>
                                        <input onChange={(e) => handleInputChange(e, setLastName)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={lastName} />
                                    </div>
                                    <div className="col-span-2">
                                        <div className="my-2">Preferred name</div>
                                        <input
                                            onChange={(e) => handleInputChange(e, setPreferredName)}
                                            className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black"
                                            value={preferredName}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <div className="my-2">Pronouns</div>
                                        <select
                                            className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black"
                                            value={pronouns}
                                            onChange={(e) => handlePronounSelect(e, setPronouns, setPronounsOther)}
                                        >
                                            <option value="" disabled hidden>
                                                Pronouns...
                                            </option>
                                            {PronounOptions.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                        {pronouns === "other" ? (
                                            <input
                                                onChange={(e) => handleInputChange(e, setPronounsOther)}
                                                className="bg-white p-4 w-full py-2 rounded-lg mt-4 border border-black"
                                                value={handleString(pronounsOther)}
                                                placeholder="Other pronouns..."
                                            />
                                        ) : null}
                                    </div>
                                    <div className="col-span-2">
                                        <div className="my-2">Date of birth</div>
                                        <DatePicker
                                            selected={dateOfBirth}
                                            className="border border-solid border-black rounded-lg p-2 pr-10"
                                            popperPlacement="bottom-end"
                                            popperModifiers={{
                                                offset: ({ reference, popper }) => {
                                                    const referenceRect = reference.getBoundingClientRect();
                                                    const popperRect = popper.getBoundingClientRect();
                                                    const offsetX = referenceRect.width / 4 - popperRect.width / 2;

                                                    return [offsetX, 5];
                                                },
                                                preventOverflow: {
                                                    enabled: true,
                                                    boundariesElement: "viewport",
                                                },
                                            }}
                                            onChange={(update) => {
                                                setDateOfBirth(update);
                                            }}
                                            isClearable
                                            startDate={dateOfBirth}
                                            placeholderText={"Date of birth"}
                                        />
                                    </div>
                                </div>
                                <div className="w-full border-b border-solid border-gray-300 my-4"></div>
                                <h4>My partner</h4>
                                <div className="md:grid grid-cols-2 gap-2 my-2">
                                    <div>
                                        <div className="my-2">Legal first name</div>
                                        <input
                                            onChange={(event) => handleInputChange(event, setPartnerFirstName)}
                                            value={partnerFirstName}
                                            className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black"
                                        />
                                    </div>
                                    <div>
                                        <div className="my-2">Legal last name</div>
                                        <input
                                            onChange={(e) => handleInputChange(e, setPartnerLastName)}
                                            className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black"
                                            value={partnerLastName}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <div className="my-2">Preferred name</div>
                                        <input
                                            onChange={(e) => handleInputChange(e, setPartnerPreferredName)}
                                            className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black"
                                            value={partnerPreferredName}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <div className="my-2">Pronouns</div>
                                        <select
                                            className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black"
                                            value={partnerPronouns}
                                            onChange={(e) => handlePronounSelect(e, setPartnerPronouns, setPartnerPronounsOther)}
                                        >
                                            <option value="" disabled hidden>
                                                Pronouns...
                                            </option>
                                            {PronounOptions.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                        {partnerPronouns === "other" ? (
                                            <input
                                                onChange={(e) => handleInputChange(e, setPartnerPronounsOther)}
                                                className="bg-white p-4 w-full py-2 rounded-lg mt-4 border border-black"
                                                value={handleString(partnerPronounsOther)}
                                                placeholder="Other pronouns..."
                                            />
                                        ) : null}
                                    </div>
                                    <div className="col-span-2">
                                        <div className="my-2">Date of birth</div>
                                        <DatePicker
                                            selected={partnerDateOfBirth}
                                            className="border border-solid border-black rounded-lg p-2 pr-10"
                                            popperPlacement="bottom-end"
                                            popperModifiers={{
                                                offset: ({ reference, popper }) => {
                                                    const referenceRect = reference.getBoundingClientRect();
                                                    const popperRect = popper.getBoundingClientRect();
                                                    const offsetX = referenceRect.width / 4 - popperRect.width / 2;

                                                    return [offsetX, 5];
                                                },
                                                preventOverflow: {
                                                    enabled: true,
                                                    boundariesElement: "viewport",
                                                },
                                            }}
                                            onChange={(update) => {
                                                setPartnerDateOfBirth(update);
                                            }}
                                            isClearable
                                            startDate={partnerDateOfBirth}
                                            placeholderText={"Date of birth"}
                                        />
                                    </div>
                                </div>
                                <div className="w-full border-b border-solid border-gray-300 my-4"></div>
                                <h4>Contact information</h4>
                                <div>
                                    <div className="my-2">Address line 1</div>
                                    <input onChange={(e) => handleInputChange(e, setAddress1)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={address1} />
                                </div>
                                <div>
                                    <div className="my-2">Address line 2</div>
                                    <input onChange={(e) => handleInputChange(e, setAddress2)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={address2} />
                                </div>
                                <div className="md:grid grid-cols-3 gap-2 my-2">
                                    <div>
                                        <div>Zip code</div>
                                        <input onChange={(e) => handleInputChange(e, setZipcode)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={zipcode} />
                                    </div>
                                    <div>
                                        <div>City</div>
                                        <input onChange={(e) => handleInputChange(e, setCity)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={city} />
                                    </div>
                                    <div>
                                        <div>State</div>
                                        <input onChange={(e) => handleInputChange(e, setHomeState)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={homeState} />
                                    </div>
                                </div>
                                <div className="my-2">
                                    <div>Phone number</div>
                                    <input onChange={(e) => handleInputChange(e, setPhone)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={phone} />
                                </div>
                            </div>
                        ) : null}

                        {currentTab === "clinic-insurance" ? (
                            <div className="flex flex-col">
                                <h4>Clinic & insurance</h4>
                                <h6>My clinic</h6>
                                <div>
                                    <div className="my-2">Clinic name</div>
                                    <input onChange={(e) => handleInputChange(e, setClinic)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={clinic} />
                                </div>
                                <div>
                                    <div className="my-2">Doctor's name</div>
                                    <input onChange={(e) => handleInputChange(e, setDoctor)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={doctor} />
                                </div>
                                <div className="w-full border-b border-solid border-gray-300 my-4"></div>
                                <h6>My insurance</h6>
                                <div>
                                    <div className="my-2">Insurance</div>
                                    <input
                                        onChange={(e) => handleInputChange(e, setInsurance)}
                                        placeholder="i.e. Carrot, Progyny, BCBS, Cigna..."
                                        className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black"
                                        value={insurance}
                                    />
                                </div>
                                <div>
                                    <div className="my-2">Fertility benefit provider</div>
                                    <input
                                        onChange={(e) => handleInputChange(e, setProvider)}
                                        placeholder="i.e. Carrot, Progyny, BCBS, Cigna..."
                                        className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black"
                                        value={provider}
                                    />
                                </div>
                            </div>
                        ) : null}

                        {currentTab === "match-preferences" ? (
                            <div className="flex flex-col">
                                <h4>Match preferences</h4>
                                <div>
                                    <div className="mt-4 mb-1">What are you looking for in an egg donor?</div>
                                    <input onChange={(e) => handleInputChange(e, setDonorQualities)} className="bg-white p-4 w-full py-2 rounded-lg border border-black" value={donorQualities} />
                                    <div className="text-sm">This helps your member advocate help you find your ideal match.</div>
                                </div>
                                <div>
                                    <div className="mt-4 mb-1">What is your ideal timeframe?</div>
                                    <select className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={timeframe} onChange={(e) => setTimeframe(e.target.value)}>
                                        <option value="" disabled hidden>
                                            Timeframe...
                                        </option>
                                        {IdealTimeframe.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {/* <div>
                                    <div className="mt-4 mb-1">Are you interested in meeting the donor as a part of the match process?</div>
                                    <input onChange={(e) => handleInputChange(e, setDonorMeeting)} className="bg-white p-4 w-full py-2 rounded-lg border border-black" value={donorMeeting} />
                                    <div className="text-sm">Contact information does not need to be exchanged to facilitate this.</div>
                                </div> */}
                                <div>
                                    <div className="mt-4 mb-1">What kind of relationship are you hoping to have with the donor post-match?</div>
                                    <select
                                        className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black break-words"
                                        value={donorRelationship}
                                        onChange={(e) => setDonorRelationship(e.target.value)}
                                    >
                                        <option value="" disabled hidden>
                                            Relationship...
                                        </option>
                                        {DonorRelationship.map((option) => (
                                            <option className="break-words w-1/3" key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="text-sm">Each relationship is unique and depends on the desires of the family and donor and what's best for the future donor-conceived child.</div>
                                </div>
                            </div>
                        ) : null}

                        {currentTab === "email-preferences" ? (
                            <div className="flex flex-col">
                                <h4>Email preferences</h4>
                                <h6>Receive emails when new donors are added</h6>
                                <div className="flex w-full">
                                    <input type="checkbox" value={"criteria_match"} onChange={(e) => handleInputChange(e, setEmailPref)} className="mr-3" checked={emailPref === "criteria_match"} />
                                    <div className="my-2 w-full md:text-nowrap">Whenever a new donor joins who matches my saved filters</div>
                                </div>
                                <div className="flex w-full">
                                    <input type="checkbox" value={"new_donors"} onChange={(e) => handleInputChange(e, setEmailPref)} className="mr-3" checked={emailPref === "new_donors"} />
                                    <div className="my-2 w-full md:text-nowrap">Whenever any new donor joins</div>
                                </div>
                                <div className="flex w-full">
                                    <input type="checkbox" value={"weekly"} onChange={(e) => handleInputChange(e, setEmailPref)} className="mr-3" checked={emailPref === "weekly"} />
                                    <div className="my-2 w-full md:text-nowrap">Once a week, with a summary of all new donors</div>
                                </div>
                                <div className="flex w-full">
                                    <input type="checkbox" value={"never"} onChange={(e) => handleInputChange(e, setEmailPref)} className="mr-3" checked={emailPref === "never"} />
                                    <div className="my-2 w-full md:text-nowrap">Never</div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="w-full flex justify-center items-center bg-white border-t-2 border-t-gray-500 fixed bottom-0 z-10">
                <button onClick={saveChanges} className="mt-5 text-secondary font-medium bg-primary border-2 border-secondary rounded-full px-3 py-2 w-3/4 sm:w-1/4 mb-3">
                    Save changes
                </button>
            </div>
        </div>
    );
};

export default IPSettings;
