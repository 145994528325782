import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { redirectToFormsort } from "../../../util/helpers";
import { AppContext } from "../../../App";

const EditRequests = () => {
    const [editRequests, setEditRequests] = useState([]);
    const [refetchEditRequests, setRefetchEditRequests] = useState(0);
    const [hasError, setHasError] = useState(null);
    // TODO: redo as individual states setting values to asc/desc
    const { loggedInUser, refreshUser, request } = useContext(AppContext);
    const navigate = useNavigate();
    const [filterBy, setFilterBy] = useState("pending");

    useEffect(() => {
        const refresh = async () => {
            await refreshUser();
        };

        if (loggedInUser === null) {
            refresh();
        }
    }, [loggedInUser, refreshUser]);

    useEffect(() => {
        document.title = "Staff | SM | Edit Requests";
        const fetchEditRequests = async () => {
            try {
                const response = await request({
                    url: `/staff/split-members/edit-requests?status=${filterBy}`,
                    method: "GET",
                });

                const { editRequests } = response;

                setEditRequests(editRequests);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
                setHasError(error);
            }
        };

        fetchEditRequests();
    }, [request, filterBy, refetchEditRequests]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    const updateEditRequest = async (requestID, decision) => {
        try {
            const response = await request({
                url: `/staff/split-members/edit-requests`,
                method: "PUT",
                data: {
                    edit_request_id: requestID,
                    decision,
                },
            });

            setRefetchEditRequests((prev) => prev + 1);
        } catch (error) {
            // TODO: handle this error
            console.error("Error fetching checklist:", error);
        }
    };

    const navigateToSplitMemberPage = (userId) => {
        navigate(`/staff/split-members/${userId}`);
    };

    const extractDate = (dateTimeString) => {
        const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
        const formattedDate = new Date(dateTimeString).toLocaleDateString("en-US", options);
        return formattedDate;
    };

    const handleString = (s) => {
        if (typeof s !== "string") {
            return s;
        }

        if (s.includes("_")) {
            return s
                .split("_")
                .map((word, i) => {
                    if (i === 0) {
                        return word[0].toUpperCase() + word.substring(1);
                    }

                    return word;
                })
                .join(" ");
        } else if (s.length > 1) {
            return s[0].toUpperCase() + s.substring(1);
        }

        return s;
    };

    const formatArray = (a) => {
        let str = a.join(", ");

        return handleString(str);
    };

    const formatAnswer = (request) => {
        let result = [];

        const answer = request.answer.answer.value;
        if (request.group_id) {
            let obj = [];
            request.group.questions.forEach((q) => {
                if (q.answer) {
                    const qa = q.answer.answer.value;

                    if (request.group.label === "Grandparents") {
                        const prefix = request.answer.question.slug.split("_living")[0];

                        if (q.slug.includes(prefix)) {
                            obj.push(
                                <div className="my-2">
                                    <span className="font-normal mr-2">{handleString(q.label)}:</span>
                                    <span>{handleString(qa)}</span>
                                </div>
                            );
                        }
                    } else if (Array.isArray(qa)) {
                        if (qa.length && typeof qa[0] === "object" && qa[0] !== null) {
                            qa.forEach((o) => {
                                let sec = [];
                                for (const [key, val] of Object.entries(o).sort(([keyA], [keyB]) => keyA.localeCompare(keyB))) {
                                    sec.push(
                                        <div className="">
                                            <span className="font-normal mr-2">{handleString(key)}:</span>
                                            <span>{handleString(val)}</span>
                                        </div>
                                    );
                                }

                                obj.push(<div className="my-2">{sec}</div>);
                            });
                        } else {
                            obj.push(
                                <div>
                                    <span className="font-normal mr-2">{handleString(q.label)}:</span>
                                    <span>{handleString(qa.join(", "))}</span>
                                </div>
                            );
                        }
                    } else if (typeof qa === "object") {
                        for (const [key, val] of Object.entries(qa)) {
                            obj.push(
                                <div>
                                    <span className="font-normal mr-2">{handleString(key)}:</span>
                                    <span>{handleString(val)}</span>
                                </div>
                            );
                        }
                    } else {
                        obj.push(
                            <div>
                                <span className="font-normal mr-2">{handleString(q.label)}:</span>
                                <span>{handleString(qa)}</span>
                            </div>
                        );
                    }
                }
            });
            result.push(<div>{obj}</div>);
            return result;
        } else if (Array.isArray(answer) && answer.length) {
            if (typeof answer[0] === "object" && answer[0] !== null) {
                answer.forEach((o) => {
                    let obj = [];
                    for (const [key, val] of Object.entries(o)) {
                        obj.push(
                            <div className="">
                                <span className="font-normal mr-2">{handleString(key)}:</span>
                                <span>{handleString(val)}</span>
                            </div>
                        );
                    }

                    result.push(<div className="my-2">{obj}</div>);
                });

                return <div>{result}</div>;
            } else {
                result.push(<div>{formatArray(answer)}</div>);

                return <div>{result}</div>;
            }
        } else if (typeof answer === "object" && answer !== null) {
            let obj = [];
            for (const [key, val] of Object.entries(answer)) {
                obj.push(
                    <div>
                        <span className="font-normal mr-2">{handleString(key)}:</span>
                        <span>{handleString(val)}</span>
                    </div>
                );
            }

            result.push(<div>{obj}</div>);
            return result;
        }

        return handleString(answer);
    };

    const setStatusStyle = (status) => {
        if (status === "rejected") {
            return {
                backgroundColor: "#E0605A",
                color: "white",
            };
        } else if (status === "approved") {
            return {
                backgroundColor: "#D2FC99",
                color: "black",
            };
        }

        return {
            backgroundColor: "#D2E9E6",
            color: "secondary",
        };
    };

    const formatUpdate = (update) => {
        let result = [];
        let parts = update.split("\n");
        parts.forEach((p) => {
            if (p.includes(":")) {
                let bits = p.split(":");
                result.push(
                    <div>
                        <span className="font-normal mr-2">{bits[0]}:</span>
                        <span>{bits[1]}</span>
                    </div>
                );
            } else {
                result.push(<div>{p}</div>);
            }
        });

        return <div>{result}</div>;
    };

    const formatForFormsortRedirect = (request) => {
        let { form } = request.application;
        form.application = request.application;
        redirectToFormsort({ form, user: request.user, staff: loggedInUser, newTab: true });
    };

    const formatGroup = (request) => {
        let group = request.group;
        let criteria = request.answer.question.criteria;

        let label = group.label;
        const isGrandparents = group.label === "Grandparents";
        let prefix = "";

        if (isGrandparents) {
            prefix = request.answer.question.slug.split("_living")[0];
            label = handleString(prefix);
        }

        let result = [
            <div className="font-medium flex items-center">
                {criteria ? (
                    <div className="w-4 mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mr-2 bi bi-flag" viewBox="0 0 16 16">
                            <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                        </svg>
                    </div>
                ) : null}
                <span>{label}</span>
            </div>,
        ];

        group.questions.forEach((q) => {
            if (isGrandparents) {
                if (q.slug.includes(prefix)) result.push(<div className="my-2">{q.label}</div>);
            } else {
                result.push(<div className="my-2">{q.label}</div>);
            }
        });

        return <div>{result}</div>;
    };

    const setQuickFilter = (status) => {
        setEditRequests([]);
        setFilterBy(status);
    };

    return (
        <div className="mx-10 mb-20">
            <div className="flex gap-2 flex-wrap w-[90%]">
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        filterBy === "pending" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() => setQuickFilter("pending")}
                >
                    Pending
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        filterBy === "rejected" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() => setQuickFilter("rejected")}
                >
                    Rejected
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        filterBy === "approved" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() => setQuickFilter("approved")}
                >
                    Approved
                </button>
            </div>
            <table className="table-fixed w-full mt-10">
                <thead>
                    <tr className="my-5">
                        <th className="w-1/7 text-left py-2 border-b border-dashed border-gray-300">Submitted</th>
                        <th className="w-1/7 text-left py-2 border-b border-dashed border-gray-300">Donor Name</th>
                        <th className="w-[70px] text-left py-2 border-b border-dashed border-gray-300">Flow</th>
                        <th className="w-1/7 text-left py-2 border-b border-dashed border-gray-300">Question</th>
                        <th className="w-1/7 text-left py-2 border-b border-dashed border-gray-300">Current Answer</th>
                        <th className="w-1/7 text-left py-2 border-b border-dashed border-gray-300">Requested Edit</th>
                        <th className="w-1/7 text-left py-2 border-b border-dashed border-gray-300">Status</th>
                        <th className="w-1/7 text-left py-2 border-b border-dashed border-gray-300">Actions</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-dotted">
                    {editRequests &&
                        editRequests.map((request) => {
                            return (
                                <tr key={request.id}>
                                    <td className="py-2 pr-4 border-b border-dashed border-gray-300">{extractDate(request.created_at)}</td>
                                    <td className="py-2 pr-4 border-b border-dashed border-gray-300 text-secondary">
                                        <div onClick={() => navigateToSplitMemberPage(request.user_id)} className="cursor-pointer">
                                            {request.user.firstName} {request.user.lastName}
                                        </div>
                                    </td>
                                    <td className="py-2 pr-4 border-b border-dashed border-gray-300">
                                        <div className="flex items-center cursor-pointer text-secondary">
                                            <svg
                                                onClick={() => formatForFormsortRedirect(request)}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-box-arrow-up-right"
                                                viewBox="0 0 16 16"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
                                                />
                                                <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z" />
                                            </svg>
                                        </div>
                                    </td>
                                    <td className="py-2 pr-4 border-b border-dashed border-gray-300">
                                        {request.group_id ? (
                                            formatGroup(request)
                                        ) : (
                                            <div className="font-medium flex items-center">
                                                {request.answer.question.criteria ? (
                                                    <div className="w-4 mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="mr-2 bi bi-flag" viewBox="0 0 16 16">
                                                            <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                                                        </svg>
                                                    </div>
                                                ) : null}
                                                {request.answer.question.label}
                                            </div>
                                        )}
                                    </td>
                                    <td className="py-2 pr-4 border-b border-dashed border-gray-300">{formatAnswer(request)}</td>
                                    <td className="py-2 pr-4 border-b border-dashed border-gray-300 whitespace-pre-line">{formatUpdate(request.updated_answer)}</td>
                                    <td className="py-2 pr-4 border-b border-dashed border-gray-300 font-semibold">
                                        <div
                                            className="h-7 text-secondary px-2 py-1 w-3/4 rounded-md border font-medium text-sm flex justify-center items-center"
                                            style={setStatusStyle(request.status)}
                                        >
                                            <span>{handleString(request.status)}</span>
                                        </div>
                                    </td>
                                    <td className="py-2 pr-4 border-b border-dashed border-gray-300">
                                        {filterBy === "pending" ? (
                                            <div>
                                                <button
                                                    onClick={() => updateEditRequest(request.id, "rejected")}
                                                    className="bg-red-500 text-white font-normal py-2 px-4 hover:bg-red-600 w-full h-12 my-2 rounded-full"
                                                >
                                                    Reject
                                                </button>
                                                <button
                                                    onClick={() => updateEditRequest(request.id, "approved")}
                                                    className="bg-secondary text-white font-normal py-2 px-4 hover:text-primary w-full h-12 my-2 rounded-full"
                                                >
                                                    Approve
                                                </button>
                                            </div>
                                        ) : (
                                            <button
                                                onClick={() => updateEditRequest(request.id, "pending")}
                                                className="bg-[#FFDA56] hover:bg-[#ebc230] text-black font-normal py-2 px-4 w-full h-12 my-2 rounded-full"
                                            >
                                                Undo
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};

export default EditRequests;
