import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Filter } from "../../filter/Filter";
import { InputFilter } from "../../filter/InputFilter";
import { DateFilter } from "../../filter/DateFilter";
import { DropdownSearchFilter } from "../../filter/DropdownSearchFilter";
import { AmericanStates, AmericanStatesMap } from "../../../util/helpers";
import { AppContext } from "../../../App";

const Clinics = () => {
    const [clinics, setClinics] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [filters, setFilters] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [joinDateRange, setJoinDateRange] = useState([null, null]);
    const [orderBy, setOrderBy] = useState("created_at");
    const [orderDir, setOrderDir] = useState("desc");
    const navigate = useNavigate();
    const { request } = useContext(AppContext);

    const [hasError, setHasError] = useState(null);

    const [stateFilterFocus, setStateFilterFocus] = useState(false);
    const [stateInput, setStateInput] = useState("");
    const [selectedStates, setSelectedStates] = useState([]);

    const filterRef = useRef(null);
    const stateDropdownRef = useRef(null);

    useEffect(() => {
        document.title = "Staff | Clinics";
        const fetchClinics = async () => {
            try {
                let filterString = "";
                if (filters.length) {
                    filters.forEach((filter) => {
                        if (filter.name.includes("range")) {
                            if (filter.value[0] !== null) {
                                filterString += `&${filter.name}_min=${filter.value[0]}`;
                            }
                            if (filter.value[1] !== null) {
                                filterString += `&${filter.name}_max=${filter.value[1]}`;
                            }
                        } else if (filter.name === "status") {
                            filter.value.forEach((v) => {
                                filterString += `&${filter.name}=${v}`;
                            });
                        } else {
                            filterString += `&${filter.name}=${filter.value}`;
                        }
                    });
                }

                if (selectedStates.length) {
                    selectedStates.forEach((state) => (filterString += `&states=${state}`));
                }

                const response = await request({
                    url: `/staff/clinics?page=${page}${filterString}&orderBy=${orderBy}&orderDir=${orderDir}`,
                    method: "GET",
                });

                const { results } = response;

                if (page !== 1) {
                    setClinics((prev) => [...prev, ...results]);
                } else {
                    setClinics(results);
                }
                setHasMore(results.length > 0);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
                setHasError(error);
            }
        };

        fetchClinics();
    }, [request, page, orderBy, orderDir, filters, selectedStates]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    useEffect(() => {
        const handleScroll = () => {
            if (hasMore && Math.ceil(window.innerHeight + document.documentElement.scrollTop) >= document.documentElement.offsetHeight) {
                setPage((prevPage) => prevPage + 1);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [hasMore]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (stateDropdownRef.current && !stateDropdownRef.current.contains(event.target)) {
                setStateFilterFocus(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setShowFilters(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const navigateToClinicPage = (clinicId) => {
        navigate(`/staff/clinics/${clinicId}`);
    };

    const extractDate = (dateTimeString) => {
        const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
        const formattedDate = new Date(dateTimeString).toLocaleDateString("en-US", options);
        return formattedDate;
    };

    const handleOrdering = (prop) => {
        if (orderBy === prop) {
            if (orderDir === "desc") {
                setOrderDir("asc");
            } else {
                setOrderDir("desc");
            }
        } else {
            if (prop.includes("last_name")) {
                setOrderBy(prop);
                setOrderDir("asc");
            } else {
                setOrderBy(prop);
                setOrderDir("desc");
            }
        }
        setPage(1);
    };

    const handleNameSearch = (name, event) => {
        const search = event.target.value;

        const filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        if (search.length) {
            filteredFilters.push({
                name: name,
                value: search,
            });
        }

        setPage(1);
        setFilters(filteredFilters);
    };

    const clearNameSearch = (name) => {
        const filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        setPage(1);
        setFilters(filteredFilters);
    };

    const handleInputChange = (setInputState, event) => {
        const input = event.target.value;

        setInputState(input);
    };

    const updateSelectedStates = (state) => {
        const filteredStates = structuredClone(selectedStates).filter((selected) => selected !== state);

        if (!selectedStates.includes(state)) {
            filteredStates.push(state);
        }

        setPage(1);
        setStateInput("");
        setSelectedStates(filteredStates);
        // setStateFilterFocus(false)
    };

    const clearDropdownSearch = (setInput, setSelected) => {
        setPage(1);
        setInput("");
        setSelected([]);
    };

    const handleDateFilter = (name, update, setState) => {
        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        if (update[0] === null && update[1] === null) {
            setState([null, null]);
        } else {
            filteredFilters.push({
                name: name,
                value: [update[0] !== null ? update[0].getTime() : null, update[1] !== null ? update[1].getTime() : null],
            });

            setState(update);
        }

        setPage(1);
        setFilters(filteredFilters);
    };

    const clearAllFilters = () => {
        setFilters([]);
        setJoinDateRange([null, null]);
        setSelectedStates([]);
        setPage(1);
    };

    return (
        <div className="mx-10 my-20">
            <div className="mb-5" style={{ position: "fixed", top: 40, right: 20, zIndex: 10 }}>
                <button
                    className="bg-white text-secondary border border-secondary rounded-full py-2 px-6 font-semibold hover:bg-green-100 focus:outline-none focus:ring focus:ring-green-200"
                    onClick={() => setShowFilters(!showFilters)}
                >
                    Filters
                </button>
                {showFilters ? (
                    <Filter isStaff={true} filterRef={filterRef} clearAllFilters={clearAllFilters} showFilters={showFilters} setShowFilters={setShowFilters}>
                        {[
                            <InputFilter
                                name="name"
                                title="Clinic Name"
                                placeholder="Clinic Name..."
                                clearState={clearNameSearch}
                                clearValue="name"
                                filters={filters}
                                handleNameSearch={handleNameSearch}
                            />,
                            <DateFilter name="join_range" title="Joined" placeholder="Joined..." dateRange={joinDateRange} setDateRange={setJoinDateRange} handleDateFilter={handleDateFilter} />,
                            <InputFilter name="city" title="City" placeholder="City..." clearState={clearNameSearch} clearValue="city" filters={filters} handleNameSearch={handleNameSearch} />,
                            <DropdownSearchFilter
                                title="State"
                                placeholder="State..."
                                dropdownRef={stateDropdownRef}
                                filterFocus={stateFilterFocus}
                                setFilterFocus={setStateFilterFocus}
                                input={stateInput}
                                setInput={setStateInput}
                                handleInputChange={handleInputChange}
                                options={AmericanStates}
                                optionsMap={AmericanStatesMap}
                                selected={selectedStates}
                                setSelected={setSelectedStates}
                                updateSelected={updateSelectedStates}
                                clearSelected={clearDropdownSearch}
                            />,
                            <InputFilter
                                name="zipcode"
                                title="Zipcode"
                                placeholder="Zipcode..."
                                clearState={clearNameSearch}
                                clearValue="zipcode"
                                filters={filters}
                                handleNameSearch={handleNameSearch}
                            />,
                            <InputFilter name="phone" title="Phone" placeholder="Phone..." clearState={clearNameSearch} clearValue="phone" filters={filters} handleNameSearch={handleNameSearch} />,
                        ]}
                    </Filter>
                ) : null}
            </div>
            <table className="table-fixed w-full">
                <thead>
                    <tr>
                        <th className="w-[14%] text-left py-2 border-b border-dashed border-gray-300 px-5">
                            <span onClick={() => handleOrdering("created_at")} className="flex items-center">
                                <span className="mr-1">Joined</span>
                                <span className="flex items-center">
                                    {orderBy === "created_at" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "created_at" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-[14%] text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("intended_parents.last_name")} className="flex items-center">
                                <span className="mr-1">Clinic</span>
                                <span className="flex items-center">
                                    {orderBy === "intended_parents.last_name" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "intended_parents.last_name" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-[14%] text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("freeze_members.last_name")} className="flex items-center">
                                <span className="mr-1">City</span>
                                <span className="flex items-center">
                                    {orderBy === "freeze_members.last_name" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "freeze_members.last_name" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-[14%] text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("member_advocates.last_name")} className="flex items-center">
                                <span className="mr-1">State</span>
                                <span className="flex items-center">
                                    {orderBy === "member_advocates.last_name" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "member_advocates.last_name" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-[14%] text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("clinics.name")} className="flex items-center">
                                <span className="mr-1">Country</span>
                                <span className="flex items-center">
                                    {orderBy === "clinics.name" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "clinics.name" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-[14%] text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("clinic_staff.last_name")} className="flex items-center">
                                <span className="mr-1">Zipcode</span>
                                <span className="flex items-center">
                                    {orderBy === "clinic_staff.last_name" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "clinic_staff.last_name" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-[14%] text-left py-2 border-b border-dashed border-gray-300">
                            <span className="flex items-center">
                                <span className="mr-1">Phone</span>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {clinics &&
                        clinics.map((clinic) => {
                            return (
                                <tr key={clinic.id} className="w-full">
                                    <td className="text-left py-2 border-b border-dashed border-gray-300 px-5">{extractDate(clinic.created_at)}</td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300 text-secondary cursor-pointer">
                                        <span onClick={() => navigateToClinicPage(clinic.id)} className="font-medium">
                                            {clinic.name}
                                        </span>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">
                                        <span>{clinic.city}</span>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">
                                        <span>{clinic.state}</span>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">
                                        <span>{clinic.country}</span>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">
                                        <span>{clinic.zipcode}</span>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">
                                        <span>{clinic.phone_number}</span>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};

export default Clinics;
