import { useNavigate } from "react-router-dom";

const LandingPage = () => {
    const navigate = useNavigate();

    let logo = <img className="mx-auto" height="46.5" width="222" alt="Cofertility logo" src={`${process.env.PUBLIC_URL}/images/logo.svg`} />;

    return (
        <div id="inner-body" className="min-h-screen flex items-center justify-center">
            <div className="mx-auto w-full max-w-md flex flex-col relative">
                <div className="m-3 sm:m-0 flex flex-col gap-8 justify-center">
                    {logo}
                    <button type="button" className={`button-freeze-signup group`} onClick={() => navigate("/freeze/login")}>
                        <div className="flex gap-3 items-center text-lg whitespace-nowrap">
                            <span className={`font-normal`}>Log in to Freeze</span>
                            <span className="w-7 h-7">
                                <svg viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M29.0725 15.4322C24.7347 15.4322 21.208 11.9056 21.208 7.56777C21.208 7.25306 20.9549 7 20.6402 7C20.3255 7 20.0725 7.25306 20.0725 7.56777C20.0725 10.9452 21.9445 13.8944 24.7022 15.4322H4.06777C3.75306 15.4322 3.5 15.6853 3.5 16C3.5 16.3147 3.75306 16.5678 4.06777 16.5678H24.7055C21.9445 18.1056 20.0757 21.0548 20.0757 24.4322C20.0757 24.7469 20.3288 25 20.6435 25C20.9582 25 21.2112 24.7469 21.2112 24.4322C21.2112 20.0944 24.7379 16.5678 29.0757 16.5678C29.3904 16.5678 29.6435 16.3147 29.6435 16C29.6435 15.6853 29.3904 15.4322 29.0757 15.4322H29.0725Z"
                                        className={`fill-secondary group-hover:fill-white`}
                                    ></path>
                                </svg>
                            </span>
                        </div>
                    </button>
                    <div className="relative">
                        <div className="absolute inset-0 flex items-center">
                            <div className="w-full border-t border-gray-300"></div>
                        </div>
                        <div className="relative flex justify-center text-sm min-w-full">
                            <span className="px-4 py bg-white-200 border border-gray-300 text-gray-700 uppercase text-[10px] rounded-full font-bold">Or</span>
                        </div>
                    </div>
                    <button type="button" className={`button-family-signup group`} onClick={() => navigate("/family/login")}>
                        <div className="flex gap-3 items-center text-lg whitespace-nowrap">
                            <span className={`font-normal`}>Log in to Family</span>
                            <span className="w-7 h-7">
                                <svg viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M29.0725 15.4322C24.7347 15.4322 21.208 11.9056 21.208 7.56777C21.208 7.25306 20.9549 7 20.6402 7C20.3255 7 20.0725 7.25306 20.0725 7.56777C20.0725 10.9452 21.9445 13.8944 24.7022 15.4322H4.06777C3.75306 15.4322 3.5 15.6853 3.5 16C3.5 16.3147 3.75306 16.5678 4.06777 16.5678H24.7055C21.9445 18.1056 20.0757 21.0548 20.0757 24.4322C20.0757 24.7469 20.3288 25 20.6435 25C20.9582 25 21.2112 24.7469 21.2112 24.4322C21.2112 20.0944 24.7379 16.5678 29.0757 16.5678C29.3904 16.5678 29.6435 16.3147 29.6435 16C29.6435 15.6853 29.3904 15.4322 29.0757 15.4322H29.0725Z"
                                        className={`fill-black group-hover:fill-white`}
                                    ></path>
                                </svg>
                            </span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
