import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DisqualificatinReasons, fetchUser, redirectToFormsort, SchoolRanking } from "../../../../util/helpers";
import { CycleTypes, matchStages, matchStatuses } from "../../../../util/statuses";
import { SimpleModal } from "../../../modals/SimpleModal";
import { convertFromUnixDate, convertToUnixDate } from "../../../../util/helpers";
import { AppContext } from "../../../../App";

const Overview = ({ userId, refreshHeader, setRefreshHeader, setShowNoteModal }) => {
    const [user, setUser] = useState(null);
    const [refetchUser, setRefetchUser] = useState(1);
    const [gq, setGq] = useState(null);
    const [ack, setAck] = useState(null);
    const [pm, setPM] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [personalInfoModal, setPersonalInfoModal] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [preferredName, setPreferredName] = useState("");
    const [pronouns, setPronouns] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [bio, setBio] = useState("");

    const [currentModal, setCurrentModal] = useState(null);
    const [profileBioModal, setProfileBioModal] = useState(false);
    const [profileBioModalText, setProfileBioModalText] = useState("");
    const [profileBio, setProfileBio] = useState("");
    const [matchPreferencesModal, setMatchPreferencesModal] = useState(false);
    const [matchPreferencesModalText, setMatchPreferencesModalText] = useState("");
    const [matchPreferences, setMatchPreferences] = useState("");
    const [donorVideoModal, setDonorVideoModal] = useState(false);
    const [donorVideoModalText, setDonorVideoModalText] = useState("");
    const [donorVideo, setDonorVideo] = useState("");
    const [timingModal, setTimingModal] = useState(false);
    const [timingModalText, setTimingModalText] = useState("");
    const [timingConstraint, setTimingConstraint] = useState("");
    const [secondCycleModal, setSecondCycleModal] = useState(false);
    const [secondCycleModalText, setSecondCycleModalText] = useState("");
    const [secondCycle, setSecondCycle] = useState("");
    const [travelAvailability, setTravelAvailability] = useState("");
    const [travelModal, setTravelModal] = useState(false);
    const [travelModalText, setTravelModalText] = useState("");

    const [disqualReasons, setDisqualReasons] = useState([]);
    const [disqualSearchInput, setDisqualSearchInput] = useState("");
    const [disqualModal, setDisqualModal] = useState(false);
    const [filterFocus, setFilterFocus] = useState(false);
    const disqualDropdownRef = useRef(null);

    const [schoolRanking, setSchoolRanking] = useState(null);
    const [rankingDropdownOpen, setRankingDropdownOpen] = useState(false);
    const rankingDropdownRef = useRef(null);

    const [cycleType, setCycleType] = useState(null);
    const [cycleTypeDropdownOpen, setCycleTypeDropdownOpen] = useState(false);
    const cycleTypeDropdownRef = useRef(null);

    const [amhModal, setAMHModal] = useState(false);
    const [amhLevel, setAMHLevel] = useState(null);
    const [lastTested, setLastTested] = useState(null);

    const [clinicModal, setClinicModal] = useState(false);
    const [clinic, setClinic] = useState("");
    const [doc, setDoc] = useState("");
    const [hasError, setHasError] = useState(null);

    const { loggedInUser, refreshUser, request } = useContext(AppContext);
    const navigate = useNavigate();
    const debounceTimeoutRef = useRef(null);

    useEffect(() => {
        const refresh = async () => {
            await refreshUser();
        };

        if (loggedInUser === null) {
            refresh();
        }
    }, [loggedInUser, refreshUser]);

    useEffect(() => {
        const fetchOverview = async () => {
            try {
                const response = await request({
                    url: `/staff/split-members/${userId}/overview`,
                    method: "GET",
                });

                const { splitMember, generalQuestions, acknowledgements, personalityMotivations } = response;

                if (splitMember.flags) {
                    splitMember.flags = splitMember.flags.sort((a, b) => {
                        const colorOrder = ["red", "orange", "yellow"];

                        const aColorIndex = colorOrder.indexOf(a.color);
                        const bColorIndex = colorOrder.indexOf(b.color);

                        return aColorIndex - bColorIndex;
                    });
                }

                setUser(splitMember);
                setGq(generalQuestions);
                setAck(acknowledgements);
                setPM(personalityMotivations);

                setFirstName(splitMember.firstName);
                setLastName(splitMember.lastName);
                if (splitMember.dateOfBirth) setDateOfBirth(splitMember.dateOfBirth);
                else setDateOfBirth("");
                if (splitMember.preferredName) setPreferredName(splitMember.preferredName);
                else setPreferredName("");
                if (splitMember.pronouns) setPronouns(splitMember.pronouns);
                else setPronouns("");

                if (splitMember.freeze_member.amh_level) setAMHLevel(splitMember.freeze_member.amh_level);
                else setAMHLevel(null);
                if (splitMember.freeze_member.amh_last_tested) {
                    const unix = convertToUnixDate(splitMember.freeze_member.amh_last_tested);
                    const label = convertFromUnixDate(unix);
                    setLastTested({ date: unix, label: label });
                } else setLastTested(null);

                if (splitMember.freeze_member.clinic) setClinic(splitMember.freeze_member.clinic);
                else setClinic("");
                if (splitMember.freeze_member.doctor) setDoc(splitMember.freeze_member.doctor);
                else setDoc("");

                if (splitMember.freeze_member.profile_bio) setProfileBio(splitMember.freeze_member.profile_bio);
                else setProfileBio("");
                if (splitMember.freeze_member.match_preferences) setMatchPreferences(splitMember.freeze_member.match_preferences);
                else setMatchPreferences("");
                if (splitMember.freeze_member.donor_video) setDonorVideo(splitMember.freeze_member.donor_video);
                else setDonorVideo("");
                if (splitMember.freeze_member.cycle_type) setCycleType(CycleTypes.filter((s) => s.value === splitMember.freeze_member.cycle_type)[0]);
                else setCycleType("");
                if (splitMember.freeze_member.second_cycle_interest) setSecondCycle(splitMember.freeze_member.second_cycle_interest);
                else setSecondCycle("");
                if (splitMember.freeze_member.timing_constraints) setTimingConstraint(splitMember.freeze_member.timing_constraints);
                else setTimingConstraint("");
                if (splitMember.freeze_member.travel_availability) setTravelAvailability(splitMember.freeze_member.travel_availability);
                else setTravelAvailability("");
                if (splitMember.freeze_member.disqual_reason) {
                    setDisqualReasons(splitMember.freeze_member.disqual_reason.split(";"));
                    setDisqualSearchInput("");
                }

                if (splitMember.freeze_member.school_ranking) setSchoolRanking(SchoolRanking.filter((s) => s.value === splitMember.freeze_member.school_ranking)[0]);
                else setSchoolRanking(null);

                setIsLoading(false);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
                setHasError(error);
            }
        };

        fetchOverview();
    }, [userId, request, refetchUser]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (rankingDropdownRef.current && !rankingDropdownRef.current.contains(event.target)) {
                setRankingDropdownOpen(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (cycleTypeDropdownRef.current && !cycleTypeDropdownRef.current.contains(event.target)) {
                setCycleTypeDropdownOpen(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (disqualDropdownRef.current && !disqualDropdownRef.current.contains(event.target)) {
                setFilterFocus(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    const calculateAge = (dateOfBirth) => {
        if (!dateOfBirth) {
            return "";
        }

        const today = new Date();
        const birthDate = new Date(dateOfBirth);

        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    if (isLoading) {
        return <div></div>;
    }

    const extractDate = (dateTimeString) => {
        const dateParts = dateTimeString.split("T")[0]; // Split on 'T' and take the first part
        return dateParts;
    };

    const handleInputChange = (event, setState) => {
        setState(event.target.value);
    };

    const closePersonalInfo = () => {
        setFirstName(user.firstName);
        setLastName(user.lastName);
        if (user.dateOfBirth) setDateOfBirth(user.dateOfBirth);
        else setDateOfBirth("");
        if (user.preferredName) setPreferredName(user.preferredName);
        else setPreferredName("");
        if (user.pronouns) setPronouns(user.pronouns);
        else setPronouns("");
        setBio("");
        setPersonalInfoModal(false);
    };

    const savePersonalInfo = async () => {
        try {
            await request({
                url: `/staff/split-members/${userId}/personal-info`,
                method: "PUT",
                data: {
                    firstName,
                    lastName,
                    preferredName,
                    pronouns,
                    dateOfBirth,
                    bio,
                },
            });

            setRefetchUser(refetchUser + 1);
            setRefreshHeader(refreshHeader + 1);
            setPersonalInfoModal(false);
        } catch (err) {
            console.log(err);
        }
    };

    const closeAMH = () => {
        if (user.freeze_member.amh_level) setAMHLevel(user.freeze_member.amh_level);
        else setAMHLevel("");
        if (user.freeze_member.amh_last_tested) {
            const unix = convertToUnixDate(user.freeze_member.amh_last_tested);
            const label = convertFromUnixDate(unix);
            setLastTested({ date: unix, label: label });
        } else setLastTested(null);

        setAMHModal(false);
    };

    const saveAMH = async () => {
        try {
            await request({
                url: `/staff/split-members/${userId}/amh`,
                method: "PUT",
                data: {
                    lastTested: lastTested ? lastTested.date : null,
                    amhLevel,
                },
            });

            setRefetchUser(refetchUser + 1);
            setAMHModal(false);
        } catch (err) {
            console.log(err);
        }
    };

    const closeClinic = () => {
        if (user.freeze_member.clinic) setClinic(user.freeze_member.clinic);
        else setClinic("");
        if (user.freeze_member.doc) setDoc(user.freeze_member.doc);
        else setDoc("");
        setClinicModal(false);
    };

    const saveClinic = async () => {
        try {
            await request({
                url: `/staff/split-members/${userId}/clinic`,
                method: "PUT",
                data: {
                    clinic,
                    doctor: doc,
                },
            });

            setRefetchUser(refetchUser + 1);
            setClinicModal(false);
        } catch (err) {
            console.log(err);
        }
    };

    const pinNote = async (note) => {
        try {
            await request({
                url: `/staff/split-members/${user.id}/pin-note`,
                method: "PUT",
                data: {
                    noteID: note.id,
                    pinned: !note.pinned,
                },
            });

            setRefetchUser(refetchUser + 1);
        } catch (err) {
            console.log(err);
        }
    };

    const shorthandDate = (dateTimeString) => {
        const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
        const formattedDate = new Date(dateTimeString).toLocaleDateString("en-US", options);
        return formattedDate;
    };

    const navigateToIPPage = (userId) => {
        navigate(`/staff/intended-parents/${userId}`);
    };

    const formatAndSetAMHLevel = (event, setValue) => {
        const inputValue = event.target.value;

        if (!isNaN(parseFloat(inputValue)) && isFinite(inputValue)) {
            setValue(parseFloat(inputValue)); // Update if valid
        } else {
            setValue(null);
        }
    };

    const saveTextModal = async (value) => {
        try {
            const response = await request({
                url: `/staff/split-members/${user.id}/${currentModal.name}`,
                method: "PUT",
                data: {
                    update: value,
                },
            });

            currentModal.setOriginal(value);
            closeTextModal();
        } catch (error) {
            console.error("Error updating field:", error);
        }
    };

    const openTextModal = (modal) => {
        setCurrentModal(modal);
        modal.setText(modal.original);
        modal.open(true);
    };

    const closeTextModal = () => {
        currentModal.setText("");
        currentModal.open(false);
        setCurrentModal(null);
    };

    const handleString = (s) => {
        if (typeof s !== "string") {
            return s;
        }

        if (s.includes("_")) {
            return s
                .split("_")
                .map((word, i) => {
                    if (i === 0) {
                        return word[0].toUpperCase() + word.substring(1);
                    }

                    return word;
                })
                .join(" ");
        } else if (s.length > 1) {
            return s[0].toUpperCase() + s.substring(1);
        }

        return s;
    };

    const formatArray = (a) => {
        let str = a.join(", ");

        return handleString(str);
    };

    const formatFlag = (answer) => {
        let result = [];

        if (Array.isArray(answer) && answer.length) {
            if (typeof answer[0] === "object" && answer[0] !== null) {
                answer.forEach((o) => {
                    let obj = [];
                    for (const [key, val] of Object.entries(o)) {
                        obj.push(
                            <div>
                                <span className="font-normal mr-2">{handleString(key)}:</span>
                                <span>{handleString(val)}</span>
                            </div>
                        );
                    }

                    result.push(<div>{obj}</div>);
                });

                return <div>{result}</div>;
            } else {
                result.push(<div>{formatArray(answer)}</div>);

                return <div>{result}</div>;
            }
        }

        return handleString(answer);
    };

    const handleDateFilter = (update) => {
        if (!update) {
            setLastTested(null);
            return;
        }

        setLastTested({ date: update.getTime(), label: convertFromUnixDate(update) });
    };

    const handleRankingUpdate = async (rank) => {
        try {
            await request({
                url: `/staff/split-members/${userId}/school-ranking`,
                method: "PUT",
                data: {
                    update: rank.value,
                },
            });

            setRefetchUser(refetchUser + 1);
            setRankingDropdownOpen(false);
        } catch (err) {
            console.log(err);
        }
    };

    const handleCycleTypeUpdate = async (type) => {
        try {
            await request({
                url: `/staff/split-members/${userId}/cycle-type`,
                method: "PUT",
                data: {
                    update: type.value,
                },
            });

            setRefetchUser(refetchUser + 1);
            setRefreshHeader(refreshHeader + 1);
            setCycleTypeDropdownOpen(false);
        } catch (err) {
            console.log(err);
        }
    };

    const handleDisqualUpdate = async () => {
        try {
            await request({
                url: `/staff/split-members/${userId}/disqual`,
                method: "PUT",
                data: {
                    updates: disqualReasons,
                },
            });

            setRefetchUser(refetchUser + 1);
            setFilterFocus(false);
            setDisqualSearchInput("");
            setDisqualModal(false);
        } catch (err) {
            console.log(err);
        }
    };

    const updateDisqualReason = (reason) => {
        const filteredReasons = structuredClone(disqualReasons).filter((selected) => selected !== reason);

        if (!disqualReasons.includes(reason)) {
            filteredReasons.push(reason);
        }

        setDisqualReasons(filteredReasons);
    };

    return (
        <div>
            <div className="flex mx-30 mb-10">
                <div className="w-2/3 mr-5">
                    <div className="col-span-4 border border-gray-300 rounded-md flex flex-col divide-y divide-dashed mb-5">
                        {/* <div className="flex justify-between items-center col-span-2 px-5 pt-3">
                            <h3 className="heading-underline-forest-50 font-medium mr-5">Personal Info</h3>
                            <span onClick={() => setPersonalInfoModal(true)} className="text-secondary flex items-center cursor-pointer">
                                <div className="inline-block mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                    </svg>
                                </div>
                                Edit
                            </span>
                        </div> */}
                        <div className="flex flex-col py-7 lg:grid grid-cols-2 gap-x-6 gap-y-4 rounded-md h-full self-stretch -m-px">
                            <div className="grid grid-cols-7 items-center col-span-2 mx-5">
                                <span className="col-span-3 text-sm mr-5">Name</span>
                                <span className="col-span-4 text-sm font-semibold">
                                    {user.firstName} {user.lastName}
                                </span>
                            </div>

                            <div className="grid grid-cols-7 items-center col-span-2 mx-5">
                                <span className="col-span-3 text-sm mr-5">Preferred Name</span>
                                <span className="col-span-4 text-sm font-semibold">{user.preferredName ? user.preferredName : <span className="italic text-gray-300 font-normal">Empty</span>}</span>
                            </div>

                            <div className="grid grid-cols-7 items-center col-span-2 mx-5">
                                <span className="col-span-3 text-sm mr-5">Pronouns</span>
                                <span className="col-span-4 text-sm font-semibold">
                                    {user.pronouns ? user.pronouns.split("_").join("/") : <span className="italic text-gray-300 font-normal">Empty</span>}
                                </span>
                            </div>

                            <div className="grid grid-cols-7 items-center col-span-2 mx-5">
                                <span className="col-span-3 text-sm mr-5">Date of Birth</span>
                                {user.dateOfBirth ? (
                                    <span className="col-span-4 text-sm">
                                        <span className="font-semibold">{extractDate(user.dateOfBirth)}</span> ({calculateAge(user.dateOfBirth)} years old)
                                    </span>
                                ) : (
                                    <span className="col-span-4 text-sm">
                                        <span className="italic text-gray-300 font-normal">Empty</span>
                                    </span>
                                )}
                            </div>

                            <div className="grid grid-cols-7 items-center col-span-2 mx-5">
                                <span className="col-span-3 text-sm mr-5">Profile Bio</span>
                                <span className="flex col-span-4 text-sm font-semibold">
                                    {profileBio ? profileBio : <span className="italic text-gray-300 font-normal">Empty</span>}
                                    <span
                                        onClick={() =>
                                            openTextModal({
                                                name: "profile-bio",
                                                open: setProfileBioModal,
                                                setText: setProfileBioModalText,
                                                original: profileBio,
                                                setOriginal: setProfileBio,
                                            })
                                        }
                                        className="mx-4 text-secondary"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                        </svg>
                                    </span>
                                </span>
                            </div>

                            <div className="grid grid-cols-7 items-center col-span-2 mx-5">
                                <span className="col-span-3 text-sm mr-5">Match Note</span>
                                <span className="flex col-span-4 text-sm font-semibold">
                                    {matchPreferences ? matchPreferences : <span className="italic text-gray-300 font-normal">Empty</span>}
                                    <span
                                        onClick={() =>
                                            openTextModal({
                                                name: "match-preferences",
                                                open: setMatchPreferencesModal,
                                                setText: setMatchPreferencesModalText,
                                                original: matchPreferences,
                                                setOriginal: setMatchPreferences,
                                            })
                                        }
                                        className="mx-4 text-secondary"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                        </svg>
                                    </span>
                                </span>
                            </div>

                            <div className="grid grid-cols-7 items-center col-span-2 mx-5">
                                <span className="col-span-3 text-sm mr-5">Donor Video</span>
                                <span className="flex col-span-4 text-sm font-semibold break-all">
                                    {donorVideo ? donorVideo : <span className="italic text-gray-300 font-normal">Empty</span>}
                                    <span
                                        onClick={() =>
                                            openTextModal({
                                                name: "donor-video",
                                                open: setDonorVideoModal,
                                                setText: setDonorVideoModalText,
                                                original: donorVideo,
                                                setOriginal: setDonorVideo,
                                            })
                                        }
                                        className="mx-4 text-secondary"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                        </svg>
                                    </span>
                                </span>
                            </div>

                            <div className="grid grid-cols-7 items-center col-span-2 mx-5">
                                <span className="col-span-3 text-sm mr-5">School Ranking</span>
                                <span className="flex col-span-4 text-sm font-semibold break-all">
                                    <div className="relative p-1 w-full" ref={rankingDropdownRef}>
                                        <button
                                            onClick={() => setRankingDropdownOpen(!rankingDropdownOpen)}
                                            className="w-3/4 py-2 px-2 rounded-md border bg-white text-gray-700 border-solid border-black flex justify-between"
                                        >
                                            {schoolRanking ? (
                                                <span key={schoolRanking.value} className={`px-2 py-1 rounded-md font-medium text-xs mx-1 flex items-center w-full`}>
                                                    {schoolRanking.label}
                                                </span>
                                            ) : (
                                                <span className={`px-2 py-1 rounded-md font-medium text-xs mx-1 flex items-center w-full`}>Empty</span>
                                            )}
                                        </button>

                                        {rankingDropdownOpen && (
                                            <div className="absolute mt-1 ml-1 w-[73%] py-2 left-0 right-0 bg-white rounded-md shadow-lg" style={{ zIndex: 1000 }}>
                                                {SchoolRanking.map((option) => (
                                                    <div
                                                        key={option.value}
                                                        onClick={() => handleRankingUpdate(option)}
                                                        className={`w-11/12 px-2 py-1 rounded-md font-medium text-xs my-2 mx-auto ${
                                                            schoolRanking && schoolRanking.value === option.value ? "border-2 border-solid border-black" : ""
                                                        }`}
                                                    >
                                                        {option.label}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </span>
                            </div>

                            {disqualReasons ? (
                                <div className="grid grid-cols-7 items-center col-span-2 mx-5">
                                    <span className="col-span-3 text-sm mr-5">Disqual reasons</span>
                                    <span className="flex col-span-4 text-sm font-semibold break-all">
                                        {disqualReasons ? disqualReasons.join(", ") : <span className="italic text-gray-300 font-normal">Empty</span>}
                                        <span onClick={() => setDisqualModal(true)} className="mx-4 text-secondary">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                            </svg>
                                        </span>
                                    </span>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-span-4 border border-gray-300 rounded-md flex flex-col divide-y divide-dashed mb-5">
                        <div className="flex justify-between items-center col-span-2 px-5 pt-3">
                            <h3 className="heading-underline-forest-50 font-medium mr-5">Match Preferences & Availability</h3>
                            <span onClick={() => redirectToFormsort({ form: ack, user, staff: loggedInUser, newTab: true })} className="text-secondary flex items-center cursor-pointer">
                                <div className="inline-block mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                    </svg>
                                </div>
                                Edit
                            </span>
                        </div>
                        <div className="flex flex-col py-7 lg:grid grid-cols-2 gap-x-6 gap-y-4 rounded-md h-full self-stretch -m-px">
                            <div className="grid grid-cols-7 items-center col-span-2 mx-5">
                                <p className="col-span-3 text-sm mr-5">Communication Preferences</p>
                                <p className="col-span-4 text-sm font-semibold">
                                    {user.freeze_member.communication ? handleString(user.freeze_member.communication) : <span className="italic text-gray-300 font-normal">Empty</span>}
                                </p>
                            </div>

                            <div className="grid grid-cols-7 items-center col-span-2 mx-5">
                                <p className="col-span-3 text-sm mr-5">Relationship Preferences</p>
                                <p className="col-span-4 text-sm font-semibold">
                                    {user.freeze_member.relationship ? handleString(user.freeze_member.relationship) : <span className="italic text-gray-300 font-normal">Empty</span>}
                                </p>
                            </div>

                            {/* <div className="grid grid-cols-7 items-center col-span-2 mx-5">
                                <p className="col-span-3 text-sm mr-5">Engagement Preferences</p>
                                <p className="col-span-4 text-sm font-semibold">
                                    {user.freeze_member.engagement ? handleString(user.freeze_member.engagement) : <span className="italic text-gray-300 font-normal">Empty</span>}
                                </p>
                            </div> */}

                            <div className="grid grid-cols-7 items-center col-span-2 mx-5">
                                <p className="col-span-3 text-sm mr-5">Recipient Families</p>
                                <p className="col-span-4 text-sm font-semibold">
                                    {ack && ack.application && ack.application.response && ack.application.response.answers && ack.application.response.answers.recipient_families ? (
                                        ack.application.response.answers.recipient_families.map((x) => handleString(x)).join(", ")
                                    ) : (
                                        <span className="italic text-gray-300 font-normal">Empty</span>
                                    )}
                                </p>
                            </div>

                            <div className="grid grid-cols-7 items-center col-span-2 mx-5">
                                <span className="col-span-3 text-sm mr-5">Cycle Type</span>
                                <span className="flex col-span-4 text-sm font-semibold break-all">
                                    <div className="relative p-1 w-full" ref={cycleTypeDropdownRef}>
                                        <button
                                            onClick={() => setCycleTypeDropdownOpen(!cycleTypeDropdownOpen)}
                                            className="w-3/4 py-2 px-2 rounded-md border bg-white text-gray-700 border-solid border-black flex justify-between"
                                        >
                                            {cycleType ? (
                                                <span key={cycleType.value} className={`px-2 py-1 rounded-md font-medium text-xs mx-1 flex items-center w-full`}>
                                                    {cycleType.label}
                                                </span>
                                            ) : (
                                                <span className={`px-2 py-1 rounded-md font-medium text-xs mx-1 flex items-center w-full`}>Empty</span>
                                            )}
                                        </button>

                                        {cycleTypeDropdownOpen && (
                                            <div className="absolute mt-1 ml-1 w-[73%] py-2 left-0 right-0 bg-white rounded-md shadow-lg" style={{ zIndex: 1000 }}>
                                                {CycleTypes.map((option) => (
                                                    <div
                                                        key={option.value}
                                                        onClick={() => handleCycleTypeUpdate(option)}
                                                        className={`w-11/12 px-2 py-1 rounded-md font-medium text-xs my-2 mx-auto ${
                                                            cycleType && cycleType.value === option.value ? "border-2 border-solid border-black" : ""
                                                        }`}
                                                    >
                                                        {option.label}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </span>
                            </div>

                            <div className="grid grid-cols-7 items-center col-span-2 mx-5">
                                <p className="col-span-3 text-sm mr-5">Second Cycle Interest</p>
                                <span className="flex col-span-4 text-sm font-semibold">
                                    {secondCycle ? secondCycle : <span className="italic text-gray-300 font-normal">Empty</span>}
                                    <span
                                        onClick={() =>
                                            openTextModal({
                                                name: "second-cycle",
                                                open: setSecondCycleModal,
                                                setText: setSecondCycleModalText,
                                                original: secondCycle,
                                                setOriginal: setSecondCycle,
                                            })
                                        }
                                        className="mx-4 text-secondary"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                        </svg>
                                    </span>
                                </span>
                            </div>

                            {/* <div className="grid grid-cols-7 items-center col-span-2 mx-5">
                                <p className="col-span-3 text-sm mr-5">Timing Constraints</p>
                                <span className="flex col-span-4 text-sm font-semibold">
                                    {timingConstraint ? timingConstraint : <span className="italic text-gray-300 font-light">Empty</span>}
                                    <span
                                        onClick={() =>
                                            openTextModal({
                                                name: "timing",
                                                open: setTimingModal,
                                                setText: setTimingModalText,
                                                original: timingConstraint,
                                                setOriginal: setTimingConstraint,
                                            })
                                        }
                                        className="mx-4 text-secondary"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                        </svg>
                                    </span>
                                </span>
                            </div> */}

                            <div className="grid grid-cols-7 items-center col-span-2 mx-5">
                                <p className="col-span-3 text-sm mr-5">Travel Availability/Timing Constraints</p>
                                <span className="flex col-span-4 text-sm font-semibold">
                                    {travelAvailability ? travelAvailability : <span className="italic text-gray-300 font-normal">Empty</span>}
                                    <span
                                        onClick={() =>
                                            openTextModal({
                                                name: "travel-availability",
                                                open: setTravelModal,
                                                setText: setTravelModalText,
                                                original: travelAvailability,
                                                setOriginal: setTravelAvailability,
                                            })
                                        }
                                        className="mx-4 text-secondary"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                        </svg>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-4 border border-gray-300 rounded-md flex flex-col divide-y divide-dashed max-h-[300px] pb-5">
                        <div className="flex justify-between items-center col-span-2 px-5 pt-3">
                            <h3 className="heading-underline-forest-50 font-medium mr-5">Notes</h3>
                            <span onClick={() => setShowNoteModal(true)} className="text-secondary">
                                + Add Note
                            </span>
                        </div>
                        <div className={`rounded-md px-6 py-2 h-full self-stretch -m-px overflow-y-auto min-h-16 ${!user.notes ? "flex items-center" : ""}`}>
                            {user.notes ? (
                                user.notes
                                    .sort((a, b) => {
                                        if (a.pinned !== b.pinned) {
                                            return a.pinned ? -1 : 1;
                                        }

                                        return new Date(b.created_at) - new Date(a.created_at);
                                    })
                                    .map((note) => (
                                        <div key={note.id} className={`flex my-3 bg-gray-100 rounded-md ${note.pinned ? "bg-yellow-100" : "bg-gray-100"} pt-3 pl-3`}>
                                            <div className="w-7 mr-2 mt-1 flex">
                                                <h1 className="flex items-center justify-center w-7 h-7 rounded-full px-2 py-1 text-black text-sm" style={{ backgroundColor: "#D2E9E6" }}>
                                                    {note.staff.firstName.charAt(0).toUpperCase()}
                                                </h1>
                                            </div>
                                            <div className="flex-grow flex flex-col text-sm">
                                                <div className="flex justify-between items-center">
                                                    <span>
                                                        <span className="font-semibold ">
                                                            {note.staff.firstName} {note.staff.lastName[0]}.
                                                        </span>
                                                        <span className="text-gray-400 ml-2">| Note</span>
                                                    </span>
                                                    <span className="mr-3 text-gray-400">
                                                        <span className="mr-2">
                                                            <button onClick={() => pinNote(note)} className="focus:outline-none">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className={`h-3 w-3 fill-current ${note.pinned ? "text-yellow-600" : "text-gray-500"} hover:text-yellow-600`}
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
                                                                </svg>
                                                            </button>
                                                        </span>
                                                        {extractDate(note.created_at)}
                                                    </span>
                                                </div>
                                                <div className="pt-2 pb-4 whitespace-pre-line">{note.note}</div>
                                            </div>
                                        </div>
                                    ))
                            ) : (
                                <div className="italic text-gray-300">None</div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="w-1/3">
                    <div className="col-span-4 border border-gray-300 rounded-md flex flex-col divide-y divide-dashed mb-5">
                        <div className="flex justify-between items-center col-span-2 px-5 pt-3">
                            <h3 className="heading-underline-forest-50 font-medium mr-5">AMH</h3>
                            <span onClick={() => setAMHModal(true)} className="text-secondary flex items-center cursor-pointer">
                                <div className="inline-block mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                    </svg>
                                </div>
                                Edit
                            </span>
                        </div>
                        <div className="flex flex-col py-7 lg:grid grid-cols-2 gap-x-6 gap-y-4 rounded-md h-full self-stretch -m-px">
                            <div className="flex items-center col-span-2 mx-5">
                                <p className="w-2/5 text-sm mr-5">AMH test date</p>
                                <p className="w-3/5 text-sm">
                                    {lastTested ? (
                                        <span className="font-semibold">{lastTested.label}</span>
                                    ) : user.freeze_member.amh_status === "amh_results_pending" ? (
                                        <span className="italic text-gray-300">Pending</span>
                                    ) : (
                                        <span className="italic text-gray-300">Empty</span>
                                    )}
                                </p>
                            </div>
                            <div className="flex items-center col-span-2 mx-5">
                                <p className="w-2/5 text-sm mr-5">AMH level</p>
                                <p className="w-3/5 text-sm">
                                    {amhLevel ? (
                                        <span className="font-semibold">{amhLevel} ng/mL</span>
                                    ) : user.freeze_member.amh_status === "amh_results_pending" ? (
                                        <span className="italic text-gray-300">Pending</span>
                                    ) : (
                                        <span className="italic text-gray-300">Empty</span>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className={`col-span-4 border border-gray-300 rounded-md flex flex-col mb-5 ${user.freeze_member_matches ? "bg-[#D2E9E6]" : ""}`}>
                        <div className="flex justify-between items-center col-span-2 px-5 pt-3">
                            <h3 className="font-medium mr-5">
                                <span className="heading-underline-forest-50 mr-2">Match History</span>({user.freeze_member_matches ? user.freeze_member_matches.length : 0})
                            </h3>
                        </div>
                        <div className={`rounded-md px-6 py-2 self-stretch -m-px overflow-y-auto ${!user.freeze_member_matches ? "flex items-center min-h-16" : "min-h-[250px] max-h-[490px]"}`}>
                            {user.freeze_member_matches ? (
                                user.freeze_member_matches
                                    .sort((a, b) => {
                                        if (a.status === "cancelled" && b.status !== "cancelled") {
                                            return 1;
                                        }

                                        return -1;
                                    })
                                    .map((match) => (
                                        <div key={match.id} className={`flex w-full mb-4 rounded-lg border border-solid border-black bg-white box-border`}>
                                            <div className="flex-grow flex flex-col text-sm">
                                                <div className="flex justify-between items-center p-3">
                                                    <div className="flex">
                                                        <span className="text-xs text-secondary">
                                                            <span
                                                                onClick={() => navigate(`/staff/matches/${match.id}`)}
                                                                className="text-secondary text-sm font-normal mx-2 cursor-pointer flex items-center"
                                                            >
                                                                #{match.match_number}
                                                            </span>
                                                        </span>
                                                        <span className="text-xs text-secondary">
                                                            <span className="text-secondary text-sm font-normal mx-2 cursor-pointer flex items-center">
                                                                Cu
                                                                <div className="inline-block ml-2">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="16"
                                                                        height="16"
                                                                        fill="currentColor"
                                                                        className="bi bi-arrow-up-right"
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </span>
                                                        </span>
                                                    </div>

                                                    <div>
                                                        <span className="text-gray-400 ml-2">
                                                            <span
                                                                className="text-secondary px-2 py-1 rounded-md borde w-1/2 font-medium text-xs"
                                                                style={{
                                                                    backgroundColor: matchStatuses.filter((status) => status.value === match.status)[0].bg,
                                                                    color: matchStatuses.filter((status) => status.value === match.status)[0].color,
                                                                }}
                                                            >
                                                                {matchStatuses.filter((status) => status.value === match.status)[0].label}
                                                            </span>
                                                        </span>
                                                        <span className="text-gray-400 ml-2">
                                                            <span
                                                                className="text-secondary px-2 py-1 rounded-md borde w-1/2 font-medium text-xs"
                                                                style={{
                                                                    backgroundColor: matchStages.filter((status) => status.value === match.stage)[0].bg,
                                                                    color: matchStages.filter((status) => status.value === match.stage)[0].color,
                                                                }}
                                                            >
                                                                {matchStages.filter((status) => status.value === match.stage)[0].label}
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="border-b border-solid border-black w-full"></div>
                                                <div className="w-full mt-4 flex justify-center items-center">
                                                    <div className="inline-block mx-2">
                                                        <h1 className="flex items-center justify-center w-16 h-16 rounded-full px-2 py-1 text-black text-lg" style={{ backgroundColor: "#D2E9E6" }}>
                                                            {user.firstName.charAt(0).toUpperCase()}
                                                        </h1>
                                                        <div>{user.donorCode}</div>
                                                    </div>
                                                    <div onClick={() => navigateToIPPage(match.family.intended_parents[0].user.id)} className="inline-block mx-2 cursor-pointer">
                                                        <h1 className="flex items-center justify-center w-16 h-16 rounded-full px-2 py-1 text-black text-lg" style={{ backgroundColor: "#D2E9E6" }}>
                                                            {match.family.intended_parents[0].user.firstName.charAt(0).toUpperCase()}
                                                        </h1>
                                                        <div>
                                                            {match.family.intended_parents[0].user.firstName} {match.family.intended_parents[0].user.lastName}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="border-b border-dashed border-gray-500 w-full mt-4"></div>
                                                <div className="flex flex-col py-4 gap-x-2 gap-y-2">
                                                    <div className="flex items-center justify-center col-span-2 mx-5">
                                                        <p className="w-1/3 text-left text-sm mr-2">Match Date</p>
                                                        <p className="w-1/3 text-left text-sm">{shorthandDate(match.created_at)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            ) : (
                                <div className="italic text-gray-300">None</div>
                            )}
                        </div>
                    </div>

                    <div className="col-span-4 border border-gray-300 rounded-md flex flex-col pb-5 mb-5">
                        <div className="flex justify-between items-center col-span-2 px-5 pt-3">
                            <h3 className="heading-underline-forest-50 font-medium mr-5">Flags</h3>
                        </div>
                        {user.flags ? (
                            <div className="pb-7 mx-5 max-h-[300px] overflow-y-auto border-t border-dashed border-gray-300">
                                <table className="table-fixed w-full border-gray-300">
                                    <thead></thead>
                                    <tbody>
                                        {user.flags &&
                                            user.flags.map((flag) => {
                                                return (
                                                    <tr key={flag.id} className="border-b border-dashed">
                                                        <td className="w-1/2 text-left py-2 border-b border-dashed border-gray-300">
                                                            <span className="flex items-center mx-1">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="16"
                                                                    fill={`${flag.color === "yellow" ? "gold" : flag.color}`}
                                                                    class="bi bi-flag-fill mr-3"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                                                                </svg>
                                                                {flag.answer.question.slug
                                                                    .split("_")
                                                                    .map((word) => word[0].toUpperCase() + word.substring(1))
                                                                    .join(" ")}
                                                            </span>
                                                        </td>
                                                        <td className="w-1/2 text-left py-2 border-b border-dashed border-gray-300">
                                                            {typeof flag.answer.answer.value === "string" ? handleString(flag.answer.answer.value) : formatFlag(flag.answer.answer.value)}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div className="flex items-center col-span-2 mx-5 min-h-16">
                                <div className="italic text-gray-300">None</div>
                            </div>
                        )}
                    </div>

                    <div className="col-span-4 border border-gray-300 rounded-md flex flex-col divide-y divide-dashed mb-5">
                        <div className="flex justify-between items-center col-span-2 px-5 pt-3">
                            <h3 className="heading-underline-forest-50 font-medium mr-5">Cofertility Communications</h3>
                            <span onClick={() => redirectToFormsort({ form: gq, user, staff: loggedInUser, newTab: true })} className="text-secondary flex items-center cursor-pointer">
                                <div className="inline-block mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                    </svg>
                                </div>
                                Edit
                            </span>
                        </div>
                        <div className="flex flex-col py-7 lg:grid grid-cols-2 gap-x-6 gap-y-4 rounded-md h-full self-stretch -m-px">
                            <div className="flex items-center col-span-2 mx-5">
                                <p className="w-2/5 text-sm mr-5">Texting</p>
                                <p className="w-3/5 text-sm font-semibold">
                                    {gq && gq.application && gq.application.response ? (
                                        handleString(gq.application.response.answers.text_consent)
                                    ) : (
                                        <span className="italic text-gray-300 font-normal">Empty</span>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="col-span-4 border border-gray-300 rounded-md flex flex-col divide-y divide-dashed">
                    <div className="flex justify-between items-center col-span-2 px-5 pt-3">
                        <h3 className="heading-underline-forest-50 font-medium mr-5">Clinic</h3><span onClick={() => setClinicModal(true)} className="text-secondary">[ i ] Edit</span>
                    </div>
                    <div className="flex flex-col py-7 lg:grid grid-cols-2 gap-x-6 gap-y-4 rounded-md h-full self-stretch -m-px">
                        <div className="flex items-center col-span-2 mx-5">
                            <p className="w-2/5 text-sm mr-5">Clinic Name</p>
                            <p className="w-3/5 text-sm font-semibold">{ user.freeze_member.clinic ? user.freeze_member.clinic : null }</p>
                        </div>

                        <div className="flex items-center col-span-2 mx-5">
                            <p className="w-2/5 text-sm mr-5">Doctor</p>
                            <p className="w-3/5 text-sm font-semibold">{ user.freeze_member.doctor ? user.freeze_member.doctor : null }</p>
                        </div>
                    </div>
                </div> */}
            </div>

            {disqualModal ? (
                <div
                    className="modal"
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 10,
                    }}
                >
                    <div
                        className="modal-content w-full max-w-3xl"
                        style={{
                            backgroundColor: "white",
                            padding: "40px",
                            borderRadius: "5px",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
                        }}
                    >
                        <h3 className="text-2xl font-semibold mb-4 flex justify-between items-center">
                            <span className="heading-underline-forest-50 ">Disqualification Reason</span>
                        </h3>
                        <div ref={disqualDropdownRef} className={`relative flex justify-between p-2 w-3/4 bg-white rounded-lg ${filterFocus ? "ring-2 ring-blue-700" : "border-black border"}`}>
                            <input
                                value={disqualSearchInput}
                                onFocus={() => {
                                    setFilterFocus(true);
                                    setDisqualSearchInput("");
                                }}
                                className="w-3/4 focus:outline-none focus:ring-0"
                                onChange={(event) => handleInputChange(event, setDisqualSearchInput)}
                                placeholder={"Search for disqaul reason..."}
                            ></input>
                            {filterFocus ? (
                                <div className="absolute mt-10 w-full py-2 left-0 bg-white rounded-md shadow-lg overflow-y-auto max-h-[300px] divide-y divide-dashed" style={{ zIndex: 1000 }}>
                                    {DisqualificatinReasons.map((option) => {
                                        if (option.label.toLowerCase().includes(disqualSearchInput.toLowerCase())) {
                                            return (
                                                <div
                                                    onClick={() => {
                                                        setDisqualSearchInput("");
                                                        updateDisqualReason(option.slug);
                                                        setFilterFocus(false);
                                                    }}
                                                    key={option.slug}
                                                    className={`ml-2 p-1`}
                                                >
                                                    {option.label}
                                                </div>
                                            );
                                        }

                                        return null;
                                    })}
                                </div>
                            ) : null}
                        </div>
                        {disqualReasons.length ? (
                            <div className="mt-2">
                                {disqualReasons.map((option) => (
                                    <div
                                        key={option}
                                        className={`px-2 py-1 rounded-md font-medium text-xs mx-1 w-fit flex items-center my-1`}
                                        style={{ backgroundColor: "#D2E9E6", color: "secondary" }}
                                    >
                                        <span>{option}</span>
                                        <div onClick={() => updateDisqualReason(option)} className="ml-1 inline-block">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x cursor-pointer" viewBox="0 0 16 16">
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                            </svg>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : null}
                        <div className="grid grid-cols-2 gap-x-6 gap-y-4 rounded-md p-4">
                            <div className="col-span-2 flex justify-end mt-4">
                                <button
                                    onClick={() => {
                                        user.freeze_member.disqual_reason ? setDisqualReasons(user.freeze_member.disqual_reason.split(";")) : setDisqualReasons([]);
                                        setDisqualSearchInput("");
                                        setDisqualModal(false);
                                    }}
                                    className="mr-2 px-4 py-2 rounded-md bg-gray-300 hover:bg-gray-400"
                                >
                                    Close
                                </button>
                                <button onClick={handleDisqualUpdate} className="px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            {personalInfoModal ? (
                <SimpleModal
                    title="Edit Personal Info"
                    inputs={[
                        { title: "First Name", value: firstName, setInputState: setFirstName, handleChange: handleInputChange },
                        { title: "Last Name", value: lastName, setInputState: setLastName, handleChange: handleInputChange },
                        { title: "Preferred Name", value: preferredName, setInputState: setPreferredName, handleChange: handleInputChange },
                        { title: "Pronouns", value: pronouns, setInputState: setPronouns, handleChange: handleInputChange },
                        { title: "Date of Birth", value: extractDate(dateOfBirth), setInputState: setDateOfBirth, handleChange: handleInputChange },
                    ]}
                    close={closePersonalInfo}
                    save={savePersonalInfo}
                />
            ) : null}

            {profileBioModal ? (
                <SimpleModal
                    title="Edit Profile Bio"
                    inputs={[{ textArea: true, value: profileBioModalText, setInputState: setProfileBioModalText, handleChange: handleInputChange }]}
                    close={closeTextModal}
                    save={() => saveTextModal(profileBioModalText)}
                />
            ) : null}

            {matchPreferencesModal ? (
                <SimpleModal
                    title="Edit Match Preferences"
                    inputs={[{ textArea: true, value: matchPreferencesModalText, setInputState: setMatchPreferencesModalText, handleChange: handleInputChange }]}
                    close={closeTextModal}
                    save={() => saveTextModal(matchPreferencesModalText)}
                />
            ) : null}

            {donorVideoModal ? (
                <SimpleModal
                    title="Edit Donor Video"
                    inputs={[{ textArea: true, value: donorVideoModalText, setInputState: setDonorVideoModalText, handleChange: handleInputChange }]}
                    close={closeTextModal}
                    save={() => saveTextModal(donorVideoModalText)}
                />
            ) : null}

            {timingModal ? (
                <SimpleModal
                    title="Edit Timing Constraints"
                    inputs={[{ textArea: true, value: timingModalText, setInputState: setTimingModalText, handleChange: handleInputChange }]}
                    close={closeTextModal}
                    save={() => saveTextModal(timingModalText)}
                />
            ) : null}

            {secondCycleModal ? (
                <SimpleModal
                    title="Edit Second Cycle Interest"
                    inputs={[{ textArea: true, value: secondCycleModalText, setInputState: setSecondCycleModalText, handleChange: handleInputChange }]}
                    close={closeTextModal}
                    save={() => saveTextModal(secondCycleModalText)}
                />
            ) : null}

            {travelModal ? (
                <SimpleModal
                    title="Edit Travel Availability"
                    inputs={[{ textArea: true, value: travelModalText, setInputState: setTravelModalText, handleChange: handleInputChange }]}
                    close={closeTextModal}
                    save={() => saveTextModal(travelModalText)}
                />
            ) : null}

            {amhModal ? (
                <SimpleModal
                    title="AMH"
                    inputs={[
                        { title: "Last Tested", value: lastTested ? lastTested.date : null, type: "datepicker", handleChange: handleDateFilter, placeholder: "AMH last tested" },
                        { title: "AMH Level", step: "any", type: "number", value: amhLevel, setInputState: setAMHLevel, handleChange: formatAndSetAMHLevel },
                    ]}
                    close={closeAMH}
                    save={saveAMH}
                />
            ) : null}

            {clinicModal ? (
                <SimpleModal
                    title="Clinic"
                    close={closeClinic}
                    save={saveClinic}
                    inputs={[
                        { title: "Clinic Name", value: clinic, setInputState: setClinic, handleChange: handleInputChange },
                        { title: "Doctor", value: doc, setInputState: setDoc, handleChange: handleInputChange },
                    ]}
                />
            ) : null}
        </div>
    );
};

export default Overview;
