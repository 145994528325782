import { useEffect, useState, useRef, useContext, createContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { AppContext } from "../../App";

export const IPDashboardContext = createContext(null);

const FamilyErrorBoundary = ({ page, message }) => {
    const [profileMenu, setProfileMenu] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(false);
    const [userRetrieved, setUserRetrieved] = useState(false);
    const { logout } = useAuth0();
    const menuRef = useRef(null);
    const { loggedInUser, refreshUser } = useContext(AppContext);

    window.scrollTo({
        top: 0,
    });

    useEffect(() => {
        const refresh = async () => {
            await refreshUser();
            setUserRetrieved(true);
        };

        if (loggedInUser === null) {
            refresh();
        } else {
            setUserRetrieved(true);
        }
    }, [loggedInUser, refreshUser, setUserRetrieved]);

    useEffect(() => {
        document.title = "Cofertility";

        const handleOutsideClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setProfileMenu(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin + "/family/login",
            },
        });
    };

    const toggleProfileMenu = () => {
        setProfileMenu(!profileMenu);
    };

    const toggleMobileMenu = () => {
        setMobileMenu(!mobileMenu);
    };

    const updatePathAndNavigate = (newPath) => {
        // Get the current URL object
        const currentUrl = new URL(window.location.href);

        // Update the pathname of the URL
        currentUrl.pathname = newPath;

        // Navigate to the updated URL in the same tab
        window.location.href = currentUrl.href;
    };

    const navigateToChecklist = () => {
        updatePathAndNavigate("/family/checklist");
    };

    const navigateToDonorSearch = () => {
        updatePathAndNavigate("/family/donors");
    };

    const navigateToDocuments = () => {
        updatePathAndNavigate("/family/documents");
    };

    const navigateToLearn = () => {
        window.open("https://www.cofertility.com/family/learn", "_blank");
    };

    const navigateToFaq = () => {
        updatePathAndNavigate("/family/faq");
    };

    const navigateToSettings = () => {
        updatePathAndNavigate("/family/settings");
    };

    const navigateToFavorites = () => {
        updatePathAndNavigate("/family/favorites");
    };

    let footerLogo = <img alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/logo_white.svg`} />;

    if (!userRetrieved) {
        return null;
    }

    return (
        <div id="inner-body" className={`min-h-screen flex flex-col h-full w-full bg-white-200 bg-cover bg-fixed bg-center sm:bg-top`}>
            <header className="h-20">
                <nav className="fixed bg-secondary w-full z-20">
                    <div>
                        <div className="flex justify-center h-20">
                            <div className="hidden lg:flex md:space-x-8 items-center navigation">
                                <div onClick={navigateToDonorSearch} className={`cursor-pointer !text-white`}>
                                    Find Donors
                                </div>
                                <div onClick={navigateToChecklist} className={`cursor-pointer !text-white`}>
                                    Checklist
                                </div>
                                <div onClick={navigateToDocuments} className={`cursor-pointer !text-white`}>
                                    Documents
                                </div>
                                <div onClick={navigateToLearn} className={`cursor-pointer !text-white`}>
                                    Learn
                                </div>
                                {loggedInUser && loggedInUser.isStaff ? (
                                    <div onClick={() => updatePathAndNavigate("/staff")} className={`cursor-pointer !text-white`}>
                                        Admin
                                    </div>
                                ) : null}
                            </div>

                            <div className="-ml-2 mr-2 flex items-center lg:hidden">
                                <button
                                    onClick={toggleMobileMenu}
                                    type="button"
                                    className="inline-flex items-center justify-center p-4 rounded-md text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                    aria-controls="mobile-menu"
                                    aria-expanded={mobileMenu}
                                >
                                    <span className="sr-only">Open main menu</span>
                                    <div className="w-6 flex items-center justify-center relative">
                                        <span className={`${mobileMenu ? "translate-y-0 rotate-45" : "-translate-y-2"} transform transition w-full h-px bg-current absolute`}></span>
                                        <span className={`${mobileMenu ? "opacity-0 translate-x-3" : "opacity-100"} transform transition w-full h-px bg-current absolute`}></span>
                                        <span className={`${mobileMenu ? "translate-y-0 -rotate-45" : "translate-y-2"} transform transition w-full h-px bg-current absolute`}></span>
                                    </div>
                                </button>
                            </div>

                            <div className="flex origin-top-right absolute right-2 top-5 items-center">
                                <div onClick={navigateToFavorites} className="flex items-center mr-7 font-normal text-white cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="#ED795E" class="bi bi-heart-fill mr-3" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314" />
                                    </svg>
                                    <span>FAVORITES</span>
                                </div>
                                <div ref={menuRef}>
                                    <div className="relative max-h-10.5">
                                        <button
                                            onClick={toggleProfileMenu}
                                            type="button"
                                            className="header-menu-button items-center !pb-0"
                                            aria-haspopup="true"
                                            aria-labelledby="alpine-menu-label-1"
                                            id="alpine-menu-button-1"
                                            aria-expanded={profileMenu}
                                        >
                                            <span className="sr-only">Open user menu</span>
                                            <span className={`${loggedInUser.user_photos ? "flex justify-center" : ""}`}>
                                                <span
                                                    className={`${loggedInUser.user_photos ? "relative" : ""} w-10 h-10 rounded-full px-2 py-1 text-black text-sm mr-2`}
                                                    style={{ backgroundColor: "#D2E9E6" }}
                                                >
                                                    {loggedInUser.user_photos ? (
                                                        <img
                                                            src={loggedInUser.user_photos[0].cropped_source}
                                                            alt={loggedInUser.user_photos[0].name}
                                                            className="absolute inset-0 w-full h-full object-cover rounded-full"
                                                        />
                                                    ) : loggedInUser.firstName ? (
                                                        loggedInUser.firstName.charAt(0).toUpperCase()
                                                    ) : (
                                                        "?"
                                                    )}
                                                </span>
                                            </span>
                                        </button>

                                        <div
                                            className="origin-top-right absolute right-2 min-w-min p-6 bg-white focus:outline-none shadow-hard border border-black border-solid rounded-xl overflow-hidden"
                                            aria-orientation="vertical"
                                            role="menu"
                                            id="alpine-menu-items-1"
                                            aria-labelledby="alpine-menu-button-1"
                                            tabIndex="0"
                                            style={profileMenu ? {} : { display: "none" }}
                                        >
                                            <div role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">
                                                <div
                                                    onClick={navigateToSettings}
                                                    className="cursor-pointer block mb-4 w-40 text-base font-normal uppercase tracking-widest"
                                                    role="menuitem"
                                                    tabIndex="-1"
                                                    id="user-menu-item-0"
                                                >
                                                    Settings
                                                </div>
                                                <div
                                                    onClick={navigateToFaq}
                                                    className="cursor-pointer block mb-4 w-40 text-base font-normal uppercase tracking-widest"
                                                    role="menuitem"
                                                    tabIndex="-1"
                                                    id="user-menu-item-1"
                                                >
                                                    FAQS
                                                </div>
                                                <div onClick={handleLogout} className="cursor-pointer block w-40 text-base font-normal uppercase tracking-widest" role="menuitem" tabIndex="-1">
                                                    Logout
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="mobile-menu" className="mobile-menu" style={mobileMenu ? {} : { display: "none" }}>
                        <div className="p-6 pb-0 flex flex-col top-menu text-white">
                            <div onClick={navigateToDonorSearch} className={`cursor-pointer`}>
                                Find Donors
                            </div>
                            <div onClick={navigateToChecklist} className={`cursor-pointer`}>
                                Checklist
                            </div>
                            <div onClick={navigateToDocuments} className={`cursor-pointer`}>
                                Documents
                            </div>
                            <div onClick={navigateToLearn} className={`cursor-pointer`}>
                                Learn
                            </div>
                            <div onClick={navigateToFavorites} className={`cursor-pointer`}>
                                Favorites
                            </div>
                        </div>

                        <div className="mt-8 mx-6 pt-8 pb-3 border-t border-black bottom-menu">
                            <div className="space-y-4">
                                <div onClick={navigateToSettings} className="block mb-4 text-base font-light active-w" role="menuitem" tabIndex="-1" id="user-menu-item-0">
                                    Settings
                                </div>
                                <div onClick={navigateToFaq} className="block mb-4 text-base font-light active-w" role="menuitem" tabIndex="-1" id="user-menu-item-1">
                                    FAQS
                                </div>
                                <div onClick={handleLogout} className="block mb-4 text-base font-light" role="menuitem" tabIndex="-1">
                                    Logout
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>

            <div className="w-full h-screen flex items-center justify-center">{message}</div>

            <footer className=" bottom-0 left-0 w-full flex justify-between bg-black text-white sm:py-4 sm:px-10 px-6 py-5 font-normal">
                {footerLogo}
                <div>
                    <span className="hidden sm:inline">We're here to help. Email us at </span>
                    <a className="underline" href="mailto:support@cofertility.com">
                        support@cofertility.com
                    </a>
                </div>
            </footer>
        </div>
    );
};

export default FamilyErrorBoundary;
