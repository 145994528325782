import React from "react";

export const MultiButtonGridFilter = ({ title, items, setFilterArray, selected, setSelected }) => {
    return (
        <div>
            <h4 className="p-1 my-1">{title}</h4>
            <div className="grid grid-cols-2 gap-2 my-1">
                {items.map((item) => (
                    <div
                        onClick={() => setFilterArray(selected, setSelected, item.slug)}
                        className={`w-full rounded-lg p-3 ${selected.includes(item.slug) ? `bg-secondary text-white` : `bg-white`}
                        hover:bg-[#D2E9E6] hover:text-black border border-black cursor-pointer`}
                        onMouseEnter={(e) => {
                            const img = e.currentTarget.querySelector("img");
                            if (img) {
                                img.style.filter = "invert(0)";
                            }
                        }}
                        onMouseLeave={(e) => {
                            const img = e.currentTarget.querySelector("img");
                            if (img) {
                                img.style.filter = selected.includes(item.slug) ? "invert(1)" : "none";
                            }
                        }}
                    >
                        {item.icon ? (
                            <img
                                className="max-w-none pb-1"
                                src={`${process.env.PUBLIC_URL}/images/${item.icon}.svg`}
                                alt="Complete your application"
                                width="16"
                                height="16"
                                style={{ filter: selected.includes(item.slug) ? "invert(1)" : "none" }}
                            />
                        ) : null}
                        <div>{(item.slug[0].toUpperCase() + item.slug.substring(1)).split("_").join(" ")}</div>
                    </div>
                ))}
            </div>
            <div className="w-full border-b border-solid border-gray-300 my-4"></div>
        </div>
    );
};
