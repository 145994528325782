import React, { useContext, useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { AppContext } from "../../../App";
import { useNavigate } from "react-router-dom";

const Documents = ({ user, userType, staff = false }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [staffOnly, setStaffOnly] = useState(true);
    const [uploadModal, setUploadModal] = useState(false);
    const [hasError, setHasError] = useState(null);
    const navigate = useNavigate();

    const { loggedInUser, refreshUser, request } = useContext(AppContext);

    useEffect(() => {
        const refresh = async () => {
            await refreshUser();
        };

        if (loggedInUser === null) {
            refresh();
        } else if (loggedInUser.freeze_member && loggedInUser.freeze_member.status === "retired") {
            navigate("/freeze/dashboard");
        }
    });

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const response = await request({
                    url: `${staff ? "/staff" : ""}/${userType}/${user.id}/documents`,
                    method: "GET",
                });

                const { files } = response;
                if (files) {
                    setDocuments(files);
                }
            } catch (err) {
                console.log(err);
                setHasError(err);
            }
        };

        fetchDocuments();
    }, [request, user, userType, staff]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        if (staff) {
            setUploadModal(true);
        }
    };

    const handleUpload = async () => {
        if (!selectedFile) return;

        const formData = new FormData();
        formData.append("document", selectedFile);
        formData.append("staff_only", staffOnly);

        try {
            let response = await request({
                url: `${staff ? "/staff" : ""}/${userType}/${user.id}/documents`,
                method: "POST",
                headers: {
                    "content-type": "multipart/form-data",
                },
                data: formData,
            });

            response = await request({
                url: `${staff ? "/staff" : ""}/${userType}/${user.id}/documents`,
                method: "GET",
            });

            const { files } = response;

            setDocuments(files);
            setSelectedFile(null);
            setUploadModal(false);
            setStaffOnly(true);
        } catch (error) {
            console.error("Upload error:", error);
        }
    };

    const extractDate = (dateTimeString) => {
        const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
        const formattedDate = new Date(dateTimeString).toLocaleDateString("en-US", options);
        return formattedDate;
    };

    const handleDownload = (docUrl, docName) => {
        saveAs(docUrl, docName);
    };

    const closeModal = () => {
        setSelectedFile(null);
        setUploadModal(false);
    };

    return (
        <div className="md:mx-30">
            {staff ? (
                <div>
                    <div className="mb-10">
                        <label htmlFor="fileInput" className="h-12 bg-secondary text-white border border-secondary rounded-full py-2 px-6 font-semibold">
                            Choose File
                        </label>
                        <input id="fileInput" className="hidden" type="file" name="document" onChange={handleFileChange} />
                    </div>

                    <h1 className={`${userType === "split-members" ? "heading-underline-forest-50" : "heading-underline-goldenrod"} mr-5 w-fit inline-block`}>Documents</h1>
                    {staff && documents.length ? <span>{documents.length} file(s)</span> : null}
                </div>
            ) : (
                <div className="md:flex md:justify-between">
                    <h1 className={`heading-underline-forest-50 mr-5 w-fit inline-block`}>Documents</h1>
                    <div className="flex items-center justify-between">
                        <label
                            htmlFor="fileInput"
                            className={`flex items-center h-12  justify-center ${
                                selectedFile ? "mr-4" : "w-48 bg-primary hover:bg-secondary text-secondary hover:text-primary border border-secondary hover:border-primary rounded-full py-2 px-6"
                            } font-semibold`}
                        >
                            {selectedFile ? selectedFile.name : "Choose File"}
                        </label>
                        <input id="fileInput" className="hidden" type="file" name="document" onChange={handleFileChange} />
                        {selectedFile ? (
                            <button
                                onClick={handleUpload}
                                type="button"
                                className="h-12 w-48 justify-center bg-primary hover:bg-secondary text-secondary hover:text-primary border border-secondary hover:border-primary rounded-full py-2 px-6 font-semibold"
                            >
                                Upload
                            </button>
                        ) : null}
                    </div>
                </div>
            )}

            {uploadModal ? (
                <div
                    className="modal"
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 10,
                    }}
                >
                    <div
                        className="modal-content w-full max-w-3xl"
                        style={{
                            backgroundColor: "white",
                            padding: "40px",
                            borderRadius: "5px",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
                        }}
                    >
                        <h3 className="mb-4">
                            <div>
                                <span className="text-2xl font-semibold heading-underline-forest-50">Upload Document</span>
                            </div>
                            <button onClick={() => setStaffOnly(!staffOnly)} className={`focus:outline-none flex items-center mt-4`}>
                                <input type="checkbox" className="accent-secondary mr-2" checked={staffOnly} onChange={() => setStaffOnly(!staffOnly)}></input>
                                <span className="mr-2">Make staff only</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                </svg>
                            </button>
                        </h3>

                        <h4>Uploading: {selectedFile.name}</h4>

                        <div className="grid grid-cols-2 gap-x-6 gap-y-4 rounded-md p-4">
                            <div className="col-span-2 flex justify-end mt-4">
                                <button onClick={closeModal} className="mr-2 px-4 py-2 rounded-md bg-gray-300 hover:bg-gray-400">
                                    Close
                                </button>
                                <button onClick={handleUpload} className="px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white">
                                    Upload
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            {staff ? (
                documents.length ? (
                    <table className="table-fixed w-full">
                        <thead>
                            <tr>
                                <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">Uploader</th>
                                <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">Name</th>
                                <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">Type</th>
                                <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">Staff Only</th>
                                <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">Uploaded</th>
                            </tr>
                        </thead>
                        <tbody>
                            {documents.map((doc) => {
                                console.log(doc);
                                return (
                                    <tr key={doc.id}>
                                        {/* TODO: update backend and db for uploader */}
                                        <td className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">
                                            {doc.uploader.firstName} {doc.uploader.lastName} {doc.uploader.isStaff ? "(staff)" : null}
                                        </td>
                                        <td className="w-1/4 text-left py-2 border-b border-dashed border-gray-300 text-secondary cursor-pointer">
                                            <span onClick={() => handleDownload(doc.source, doc.name)}>{doc.name}</span>
                                        </td>
                                        <td className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">{doc.type}</td>
                                        <td className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">{`${doc.staff_only}`}</td>
                                        <td className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">{extractDate(doc.created_at)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                ) : (
                    <div>No files uploaded yet!</div>
                )
            ) : null}

            {!staff ? (
                documents.length ? (
                    <div className="w-full">
                        {documents.map((doc) => {
                            return (
                                <div className="sm:grid grid-cols-4 p-2 bg-white border border-black-500 rounded-md w-full my-3" key={doc.id}>
                                    <div className="text-left py-2 cursor-pointer font-semibold sm:ml-10">{doc.name}</div>
                                    <div className="text-left sm:text-center py-2">{doc.uploader.isStaff ? "Cofertility" : `${doc.uploader.firstName} ${doc.uploader.lastName}`}</div>
                                    <div className="text-left sm:text-center py-2">{extractDate(doc.created_at)}</div>
                                    <div className="flex sm:justify-end items-center py-2 mr-10">
                                        <svg
                                            onClick={() => handleDownload(doc.source, doc.name)}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="black"
                                            className="bi bi-download cursor-pointer"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                                        </svg>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div>No files uploaded yet!</div>
                )
            ) : null}
        </div>
    );
};

export default Documents;
