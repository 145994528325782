import React from "react";

export const Filter = ({ children, isStaff, filterRef, clearAllFilters, showFilters, setShowFilters, haveFilters, setMobileFilter }) => {
    return (
        <div
            ref={isStaff ? filterRef : null}
            className={
                isStaff
                    ? `fixed w-[450px] px-5 inset-y-0 right-0 border-l border-solid border-black bg-[#F5F5F5] shadow-lg transform transition-transform duration-300 translate-x-0 overflow-y-auto !text-[#191919]`
                    : `fixed w-full md:w-[415px] px-5 z-20 sm:z-0 inset-y-0 top-0 sm:top-20 sm:left-0 pb-5 sm:border-l border-solid border-black bg-[#F5F5F5] shadow-lg transform transition-transform duration-300 translate-x-0 overflow-y-auto !text-[#191919]`
            }
        >
            {!isStaff ? (
                <div className="flex md:hidden w-full justify-end h-6 sticky top-0 z-30 bg-[#F5F5F5]">
                    <svg className="mt-2 -mr-3 bi bi-x" onClick={() => setMobileFilter(false)} xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                    </svg>
                </div>
            ) : null}
            {isStaff ? (
                <div className="flex justify-between sticky top-0 w-[409px] z-20 bg-[#F5F5F5]">
                    <h1 className="pt-5">Filter by</h1>
                    {haveFilters ? (
                        <button className="w-24 h-10 mt-5 flex items-center justify-end" onClick={() => clearAllFilters()}>
                            <div className="mr-1 text-black text-base font-helveticaNeue">Clear all</div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                            </svg>
                        </button>
                    ) : null}
                    <span className="cursor-pointer pt-1" onClick={() => setShowFilters(!showFilters)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                        </svg>
                    </span>
                </div>
            ) : (
                <div className="flex justify-between pt-5 sticky top-6 md:top-0 w:full md:w-[374px] z-20 bg-[#F5F5F5]">
                    <h1 className="flex items-center">
                        <svg className="mr-[5px]" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 16.25L12 16.25" stroke="#191919" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.5 16.25L3 16.25" stroke="#191919" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M21 8L16.5 8" stroke="#191919" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12 8L3 8" stroke="#191919" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.5 19.25L7.5 12.5" stroke="#191919" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M16.5 11.75L16.5 5" stroke="#191919" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span>Filter by</span>
                    </h1>
                    {haveFilters ? (
                        <button className="w-28 h-10 font-normal flex items-center justify-end" onClick={() => clearAllFilters()}>
                            <div className="mr-1 text-black text-base font-helveticaNeue">Clear all</div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                            </svg>
                        </button>
                    ) : null}
                </div>
            )}
            <div className="">{children}</div>
        </div>
    );
};
