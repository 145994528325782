import React from "react";

export const DropdownSearchFilter = ({
    title,
    placeholder,
    dropdownRef,
    filterFocus,
    setFilterFocus,
    input,
    setInput,
    handleInputChange,
    options,
    optionsMap,
    selected,
    setSelected,
    updateSelected,
    clearSelected,
}) => {
    return (
        <div className="!text-[#191919]">
            <h4 className="p-1 my-1 ">{title}</h4>
            <div ref={dropdownRef} className={`flex justify-between p-2 w-3/4 bg-white rounded-lg ${filterFocus ? "ring-2 ring-blue-700" : "border-black border"}`}>
                <input
                    value={input}
                    onFocus={() => setFilterFocus(true)}
                    className="w-3/4 focus:outline-none focus:ring-0"
                    onChange={(event) => handleInputChange(event, setInput)}
                    placeholder={placeholder}
                ></input>
                {filterFocus ? (
                    <div className="absolute mt-10 ml-5 w-[68%] py-2 left-0 right-0 bg-white rounded-md shadow-lg overflow-y-auto max-h-[300px] divide-y divide-dashed" style={{ zIndex: 1000 }}>
                        {options.map((option) => {
                            if (!selected.includes(option.value) && option.label.toLowerCase().includes(input.toLowerCase())) {
                                return (
                                    <div onClick={() => updateSelected(option.value)} key={option.value} className={`ml-2 p-1 `}>
                                        {option.label}
                                    </div>
                                );
                            }

                            return null;
                        })}
                    </div>
                ) : null}
                <button onClick={() => clearSelected(setInput, setSelected)} className="bg-none cursor-pointer -translate-x-1/2">
                    x
                </button>
            </div>
            {selected.length ? (
                <div className="mt-2">
                    {selected.map((option) => (
                        <div key={option} className={`px-2 py-1 rounded-md font-medium text-xs mx-1 w-fit flex items-center my-1`} style={{ backgroundColor: "#D2E9E6", color: "secondary" }}>
                            <span>{optionsMap[option]}</span>
                            <div onClick={() => updateSelected(option)} className="ml-1 inline-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x cursor-pointer" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                </svg>
                            </div>
                        </div>
                    ))}
                </div>
            ) : null}
            <div className="w-full border-b border-solid border-gray-300 my-4"></div>
        </div>
    );
};
