import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IPDashboardContext } from "../intendedParent/Dashboard";

const FAQ = () => {
    const [selected, setSelected] = useState([]);
    const navigate = useNavigate();
    const { setCurrentPage } = useContext(IPDashboardContext);

    useEffect(() => {
        document.title = "Cofertility | FAQ";
        setCurrentPage("faq");
    });

    const toggleSelected = (num) => {
        const filteredSelected = structuredClone(selected).filter((selected) => selected !== num);

        if (!selected.includes(num)) {
            filteredSelected.push(num);
        }

        setSelected(filteredSelected);
    };

    const goBack = (path) => {
        navigate(path);
    };

    return (
        <div className="pb-20 bg-freeze-light-dashboard bg-cover bg-no-repeat bg-center">
            <div className={`px-6 grow flex flex-col`}>
                <div id="faq" className="container mx-auto my-auto">
                    <div className="mt-6 mb-6">
                        <div onClick={() => goBack("/family/donors")} className="flex gap-x-2 items-center !no-underline cursor-pointer">
                            <span className="h-5 w-5">
                                <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M3.5711 15.4322C7.90888 15.4322 11.4356 11.9056 11.4356 7.56777C11.4356 7.25306 11.6886 7 12.0033 7C12.318 7 12.5711 7.25306 12.5711 7.56777C12.5711 10.9452 10.6991 13.8944 7.94132 15.4322H28.5758C28.8905 15.4322 29.1436 15.6853 29.1436 16C29.1436 16.3147 28.8905 16.5678 28.5758 16.5678H7.93808C10.6991 18.1056 12.5679 21.0548 12.5679 24.4322C12.5679 24.7469 12.3148 25 12.0001 25C11.6854 25 11.4323 24.7469 11.4323 24.4322C11.4323 20.0944 7.90563 16.5678 3.56785 16.5678C3.25315 16.5678 3.00008 16.3147 3.00008 16C3.00008 15.6853 3.25315 15.4322 3.56785 15.4322H3.5711Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                            </span>
                            <span className="!no-underline">Back</span>
                        </div>
                    </div>

                    <h1 className="mb-1">
                        <span className="heading-underline">Frequently asked questions</span>
                    </h1>

                    <div className="mb-6">
                        <p>If you have any questions at all, please don't hesitate to email. We're here to help.</p>
                        <p>
                            <a href="mailto:support@cofertility.com" rel="noopener noreferrer" target="_blank">
                                support@cofertility.com
                            </a>
                        </p>
                    </div>

                    <div>
                        <div className="mb-12">
                            <h1>Browsing donors</h1>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(1)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How do I find my donor?
                                        <span className={`${selected.includes(1) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(1) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Log into your account and click the "Find Donors" tab at the top of the screen. You can browse donor profiles at your own pace, filter your search based on your
                                        match preferences, and if you'd like, click the "Save current search" button to save your filter search criteria for future use.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(2)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        What criteria can I use to filter donor profiles?
                                        <span className={`${selected.includes(2) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(2) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Explore donor profiles with filters that let you focus on what’s most important to you, from physical features to personal qualities, including:
                                    </div>
                                    <br />
                                    <li className="ml-4 my-1">Race and heritage</li>
                                    <li className="ml-4 my-1">Jewish ancestry</li>
                                    <li className="ml-4 my-1">Hair color and style</li>
                                    <li className="ml-4 my-1">Eye color</li>
                                    <li className="ml-4 my-1">Height</li>
                                    <li className="ml-4 my-1">Level of education and school ranking</li>
                                    <li className="ml-4 my-1">Personality traits</li>
                                    <li className="ml-4 my-1">Aptitude and communication style</li>
                                    <br />
                                    <div>This allows you to refine your search based on the traits that matter most to you.</div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(3)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How do I narrow my search criteria using filters?
                                        <span className={`${selected.includes(3) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(3) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        To refine your donor search, log into your account and click the "Find Donors" tab at the top of your screen. Use the fields on the left-hand side to filter
                                        profiles based on physical and personal traits that matter to you. If you’d like to save your filtered search to come back to later, or to inform your email
                                        preferences, just click the “save current search” button. If you do not save your search, your filters will be reset upon your next login.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(4)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How do I save my donor searches?
                                        <span className={`${selected.includes(4) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(4) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        After logging in and applying your desired filters under the "Find Donors" tab, click the "Save current search" button to save your preferences. This allows you
                                        to easily revisit your customized search later. If you've set your email preferences to receive updates, you'll be notified when new donors matching your
                                        criteria become available.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(5)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How do I manage my saved searches?
                                        <span className={`${selected.includes(5) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(5) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        To manage your saved searches, go to the "Favorites" section and toggle to the "Favorite Searches" tab. From there, you can easily rename or delete any of your
                                        saved searches.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(6)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How do I learn more about a donor?
                                        <span className={`${selected.includes(6) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(6) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        While browsing, simply click on a donor's profile photo or donor code to view her full profile and access more detailed information.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(7)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How do I favorite a donor?
                                        <span className={`${selected.includes(7) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(7) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        While browsing on the "Find Donors" tab or viewing a donor's profile, click the heart icon to favorite a donor. You can access your favorites anytime by
                                        clicking the "Favorites" button in the top right corner of your navigation bar.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(8)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How do I access my favorited donors?
                                        <span className={`${selected.includes(8) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(8) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        To view your favorited donors, simply click the "Favorites" button located in the top right-hand corner of your navigation bar. This will show you all the
                                        donors you've favorited for easy reference.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(9)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How do I see if a donor has any travel restrictions or blackout dates?
                                        <span className={`${selected.includes(9) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(9) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Any travel restrictions or blackout dates for a donor will be listed in the 'Donor Overview' section of her profile. If no restrictions are mentioned, the donor
                                        is available to begin the match process immediately.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(10)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How do I know if a donor is in the fresh or frozen egg donation program?
                                        <span className={`${selected.includes(10) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(10) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Donors participating in our frozen program will have a note in the “About this donor” section of their profile. In frozen egg donation, the donor completes her
                                        screening at a Cofertility partner clinic before matching, and frozen eggs are then shipped to your clinic. In fresh egg donation, the donor completes her
                                        screening and retrieval after matching at a clinic you choose. To learn more about these options,{" "}
                                        <a href="https://www.cofertility.com/family-learn/cofertilitys-fresh-vs-frozen-egg-donation-program-which-is-right-for-me" target="_blank" rel="noreferrer">
                                            click here
                                        </a>
                                        .
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(11)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        Do you have waitlists for specific donors?
                                        <span className={`${selected.includes(11) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(11) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        We do not maintain waitlists for donors. If a donor appears on the platform, it means she is not currently matched with any intended parents and is available to
                                        match.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(12)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        What does it mean if a donor I previously saw disappears from the platform?
                                        <span className={`${selected.includes(12) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(12) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If a donor is no longer visible on the platform, it means she has been matched with other intended parents or is otherwise no longer available to match.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(13)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        Can I know a donor's location?
                                        <span className={`${selected.includes(13) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(13) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        To protect our donors' privacy, we do not display their locations on their profiles. If you have specific questions about a donor’s location, feel free to
                                        contact us for more information. However, with our clinic-agnostic model, geography rarely impacts our ability to facilitate a successful match.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(14)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        What if I want to know something about a donor that isn’t listed on the profile?
                                        <span className={`${selected.includes(14) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(14) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If you have any questions about a donor that aren't covered on her profile, feel free to reach out to us, and we'll do our best to get you the information
                                        you’re looking for.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(15)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        What happens after I place a match with a donor?
                                        <span className={`${selected.includes(15) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(15) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        You will have the support of a Cofertility Member Advocate through every step of this process. We know this is an incredibly important journey - your advocate
                                        will provide the guidance, compassion, and communication to make it a smooth experience for you and your donor.
                                    </div>
                                    <br />
                                    <div className="mt-4 answer whitespace-pre-line">
                                        You will be able to visualize your journey and action items as your journey progresses using the "Checklist" tab of your account. After you've matched with a
                                        donor, you will no longer be able to view donor profiles on our matching platform, and a member of our team will contact you to discuss the next steps. If you
                                        plan to match with multiple donors for your family-building goals, please reach out to{" "}
                                        <a href="mailto:support@cofertility.com" target="_blank" rel="noreferrer">
                                            support@cofertility.com
                                        </a>{" "}
                                        or your Member Advocate for further assistance.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-12">
                            <h1>Placing a match</h1>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(16)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How do I place a hold on a match with a donor?
                                        <span className={`${selected.includes(16) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(16) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        To place a hold on a potential match, select the "Hold match" button at the top of their profile. You'll need to enter your payment information to pay your $500
                                        match deposit. Once completed, the donor will be reserved for you and removed from the platform for 14 days, ensuring no other intended parents can match with
                                        her while we confirm the details of the match.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(17)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        Is a match deposit required to place a hold on a donor?
                                        <span className={`${selected.includes(17) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(17) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Yes, a $500 match deposit is required to place a hold on a match with a donor. This ensures the donor is reserved for you and removed from the platform while we
                                        kick off with both you and your donor.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(18)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How much is owed after matching, and how do I pay?
                                        <span className={`${selected.includes(18) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(18) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        A $500 deposit is required when you place a hold on a match with your donor. The remaining balance will depend on whether you've matched with a donor for a
                                        fresh cycle or a frozen cycle. For more information, please visit our{" "}
                                        <a href="https://www.cofertility.com/family/pricing" target="_blank" rel="noreferrer">
                                            pricing page
                                        </a>
                                        . A member of our team will also reach out to you with details about the remaining payments and how to complete them.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(19)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How do I know what my next steps are?
                                        <span className={`${selected.includes(19) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(19) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        After you put a hold on a match, be on the lookout for an email from your Member Advocate. They will reach out to schedule a Zoom call to discuss the process,
                                        understand your match preferences, and answer any questions you might have. Please schedule the call as soon as possible and if you need additional
                                        availability, let your Member Advocate know. We do need to hear from you within a week of paying your deposit in order to keep your donor on hold.
                                    </div>
                                    <br />
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Log into your account and click the "Checklist" tab at the top of the page. This will show you any outstanding steps and provide an overview of where you are in
                                        the process.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-12">
                            <h1>Account settings</h1>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(20)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How do I update or add my personal information?
                                        <span className={`${selected.includes(20) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(20) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        After logging in, click on your profile icon in the top right-hand corner and select "Settings" to update any information you’ve provided us in your account.
                                        All details remain confidential and will not be shared with donors, but help our team find your perfect match.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(21)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How do I update my clinic and insurance information?
                                        <span className={`${selected.includes(21) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(21) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        After logging in, click your profile icon in the top right-hand corner, select "Settings," and then choose "Clinic & Insurance" from the menu on the left side
                                        of the screen. Here, you can update any relevant details from your intake form.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(22)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How do I update or add match preferences?
                                        <span className={`${selected.includes(22) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(22) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        After logging in, click your profile icon in the top right-hand corner, select "Settings," and then choose "Match Preferences" from the left-hand menu. You can
                                        update or add any relevant details you’ve provided us in your account, so our team can help you find your perfect match.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(23)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How do I update or add email or SMS preferences?
                                        <span className={`${selected.includes(23) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(23) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        After logging in, click your profile icon in the top right-hand corner, select "Settings," and then choose "Email Preferences" from the left-hand menu. You can
                                        choose from the following options:
                                    </div>
                                    <li className="ml-4 my-1">Receive emails when a new donor matches your saved searches</li>
                                    <li className="ml-4 my-1">Receive emails whenever any new donor joins</li>
                                    <li className="ml-4 my-1">Receive a weekly summary of all new donors</li>
                                    <li className="ml-4 my-1">Opt out of emails entirely</li>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h1>Platform support</h1>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(24)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        Do you have additional educational resources or content?
                                        <span className={`${selected.includes(24) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(24) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Yes, once you log into your account, click the "Learn" tab at the top of the page to access additional educational resources and content. You can also visit{" "}
                                        <a href="https://www.cofertility.com/family/learn" target="_blank" rel="noreferrer">
                                            https://www.cofertility.com/family/learn
                                        </a>{" "}
                                        for more information.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(25)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        What is the documents tab for?
                                        <span className={`${selected.includes(25) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(25) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        In the documents section, you’ll find helpful guides and onboarding materials. As you move forward throughout your journey, your Member Advocate may upload
                                        documents for you to reference or request that you upload documents here, such as test results or previous records.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(26)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        Is my information protected?
                                        <span className={`${selected.includes(26) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(26) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Absolutely. Cofertility uses a proprietary data management system that allows us to manage and protect your personal information with advanced encryption and
                                        privacy protocols.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(27)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        What do I do if I forget or want to reset my password?
                                        <span className={`${selected.includes(27) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(27) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If you've forgotten your password or need to reset it, simply click on the "Forgot password" link on the login page. Follow the prompts to securely reset your
                                        password.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(28)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        What do I do if I accidentally make multiple accounts?
                                        <span className={`${selected.includes(28) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(28) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If you suspect you’ve accidentally created multiple accounts, please get in touch with us as soon as possible. You can email{" "}
                                        <a href="mailto:support@cofertility.com" target="_blank" rel="noreferrer">
                                            support@cofertility.com
                                        </a>{" "}
                                        with the details, and our team will help rectify the issue promptly.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(29)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        Who can I get in touch with if I have additional questions or concerns?
                                        <span className={`${selected.includes(29) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(29) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If you've already been in touch with a member of our team during your application process, feel free to reach out to them directly. If not, you can email us at{" "}
                                        <a href="mailto:support@cofertility.com" target="_blank" rel="noreferrer">
                                            support@cofertility.com
                                        </a>
                                        .
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQ;
