import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../App";

export const Redirect = () => {
    const location = useLocation();
    const { isLoading, user } = useAuth0();
    const { setLoggedInUser, request } = useContext(AppContext);
    const [hasError, setHasError] = useState(null);
    const navigate = useNavigate();
    const appState = location.state;

    useEffect(() => {
        const redirect = async () => {
            // check if sign up or login
            let returnTo = "/error";
            if (appState.operation === "signup") {
                // if sign up create new user
                try {
                    const resp = await request({
                        url: `/signup`,
                        method: "POST",
                        data: {
                            email: user.email,
                            firstName: appState.firstName,
                            lastName: appState.lastName,
                            responderUuid: appState.responderUuid,
                            program: appState.program,
                            flow: appState.flow,
                        },
                    });

                    setLoggedInUser(resp.user);

                    if (resp.user.isStaff) {
                        returnTo = "/staff/split-members";
                        // else navigate to either welcome, application, or dashboard
                    } else if (resp.user.freeze_member && resp.user.freeze_member.status === "failed") {
                        returnTo = "/keep/dashboard";
                    } else if (resp.user.freeze_member) {
                        returnTo = "/freeze/welcome";
                    } else if (resp.user.intended_parent) {
                        let formsortURL = `https://forms.cofertility.com/flow/family-intake-form/variant/main?responderUuid=${resp.user.responderUuid}`;
                        let env = process.env.REACT_APP_ENV;
                        if (env === "local") {
                            env = process.env.REACT_APP_STAFF_FIRST_NAME + "_" + env;
                            formsortURL += `&formsortEnv=${env}`;
                        } else if (env !== "prod") {
                            formsortURL += `&formsortEnv=${env}`;
                        }
                        // to prevent double render navigate to a different page that then solely redirects to formsort
                        window.location.href = formsortURL;
                        return;
                    } else {
                        returnTo = "/";
                    }

                    navigate(returnTo);
                } catch (err) {
                    // TODO: handle this error properly after turning off strictmode
                    console.log(err);
                    // setHasError(err);
                }
            } else if (appState.operation === "login") {
                // must be login or refresh login
                try {
                    const resp = await request({
                        url: `/login`,
                        method: "POST",
                    });

                    setLoggedInUser(resp.user);

                    if (resp.user.isStaff) {
                        returnTo = "/staff/split-members";
                        // else navigate to either welcome, application, or dashboard
                    } else if (resp.user.freeze_member) {
                        if (resp.user.freeze_member.status === "failed") {
                            returnTo = "/keep/dashboard";
                        } else if (resp.user.appFinished) {
                            returnTo = "/freeze/dashboard";
                        } else if (resp.user.isWelcomed) {
                            returnTo = "/freeze/application";
                        } else {
                            returnTo = "/freeze/welcome";
                        }
                    } else if (resp.user.intended_parent) {
                        if (resp.user.appFinished) {
                            returnTo = "/family/donors";
                        } else {
                            // try navigate to a set formsort redirect component
                            let formsortURL = `https://forms.cofertility.com/flow/family-intake-form/variant/main?responderUuid=${resp.user.responderUuid}`;
                            let env = process.env.REACT_APP_ENV;
                            if (env === "local") {
                                env = process.env.REACT_APP_STAFF_FIRST_NAME + "_" + env;
                                formsortURL += `&formsortEnv=${env}`;
                            } else if (env !== "prod") {
                                formsortURL += `&formsortEnv=${env}`;
                            }
                            // to prevent double render navigate to a different page that then solely redirects to formsort
                            window.location.href = formsortURL;
                            return;
                        }
                    } else {
                        returnTo = "/";
                    }

                    navigate(returnTo);
                } catch (err) {
                    // TODO: handle this error properly after turning off strictmode
                    console.log(err);
                    // setHasError(err);
                }
            } else if (appState.operation === "activation") {
                // if sign up create new user
                try {
                    const resp = await request({
                        url: `/activate`,
                        method: "POST",
                        data: {
                            email: user.email,
                            activation_token: decodeURIComponent(appState.activationToken),
                        },
                    });

                    setLoggedInUser(resp.user);

                    if (resp.user.isStaff) {
                        returnTo = "/staff/split-members";
                        // else navigate to either welcome, application, or dashboard
                    } else if (resp.user.freeze_member) {
                        if (resp.user.freeze_member.status === "failed") {
                            returnTo = "/keep/dashboard";
                        } else if (resp.user.appFinished) {
                            returnTo = "/freeze/dashboard";
                        } else if (resp.user.isWelcomed) {
                            returnTo = "/freeze/application";
                        } else {
                            returnTo = "/freeze/welcome";
                        }
                    } else if (resp.user.intended_parent) {
                        if (resp.user.appFinished) {
                            returnTo = "/family/donors";
                        } else {
                            // try navigate to a set formsort redirect component
                            let formsortURL = `https://forms.cofertility.com/flow/family-intake-form/variant/main?responderUuid=${resp.user.responderUuid}`;
                            let env = process.env.REACT_APP_ENV;
                            if (env === "local") {
                                env = process.env.REACT_APP_STAFF_FIRST_NAME + "_" + env;
                                formsortURL += `&formsortEnv=${env}`;
                            } else if (env !== "prod") {
                                formsortURL += `&formsortEnv=${env}`;
                            }
                            // to prevent double render navigate to a different page that then solely redirects to formsort
                            window.location.href = formsortURL;
                            return;
                        }
                    } else {
                        returnTo = "/";
                    }

                    navigate(returnTo);
                } catch (err) {
                    // TODO: handle this error properly after turning off strictmode
                    console.log(err);
                    // setHasError(err);
                }
            } else if (appState.returnTo) {
                navigate(appState.returnTo);
            }
        };

        redirect();
    });

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    if (isLoading) {
        return <div></div>;
    }

    return null;
};
