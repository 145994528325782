import React from "react";

export const SingleSelectCheckboxFilter = ({ title, options, setPage, selected, setSelected }) => {
    return (
        <div>
            <h4 className="p-1 my-1">{title}</h4>
            {options &&
                options.map((item) => {
                    if (item.value === "no") {
                        return null;
                    }

                    return (
                        <label className="flex items-center ml-2">
                            <svg
                                onClick={() => {
                                    setSelected(item.value);
                                    setPage(1);
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-check-square cursor-pointer"
                                viewBox="0 0 16 16"
                            >
                                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                                {selected === item.value ? (
                                    <path fill="green" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                                ) : null}
                            </svg>
                            <span className="mx-2">{item.label}</span>
                        </label>
                    );
                })}
            <label className="flex items-center ml-2">
                <svg
                    onClick={() => {
                        setSelected("");
                        setPage(1);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-check-square cursor-pointer"
                    viewBox="0 0 16 16"
                >
                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                    {!selected.length ? (
                        <path fill="green" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                    ) : null}
                </svg>
                <span className="mx-2">All Donors</span>
            </label>
            <div className="w-full border-b border-solid border-gray-300 my-4"></div>
        </div>
    );
};
