import React, { createContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import Staff from "./components/staff/Staff";
import { CallbackPage } from "./components/auth/CallbackPage";
import { AuthenticationGuard } from "./components/auth/AuthenticationGuard";
import { Redirect } from "./components/auth/Redirect";
import { StaffAuthenticationGuard } from "./components/auth/StaffAuthenticationGuard";
import WelcomeFlow from "./components/splitMember/WelcomeFlow";
import Timeline from "./components/splitMember/Timeline";
import Application from "./components/splitMember/Application";
import Dashboard from "./components/splitMember/Dashboard";
import FamilyDashboard from "./components/intendedParent/Dashboard";
import DonorSearch from "./components/intendedParent/DonorSearch";
import Favorites from "./components/intendedParent/Favorites";
import KeepDashboard from "./components/keepMember/KeepDashboard";
import Intakes from "./components/intendedParent/Intake";
import DonorProfile from "./components/intendedParent/DonorProfile";
import SMChecklist from "./components/splitMember/Checklist";
import SMProfile from "./components/splitMember/SMProfile";
import SMPhotos from "./components/splitMember/SMPhotos";
import SMDocuments from "./components/splitMember/SMDocuments";
import SMSettings from "./components/splitMember/SMSettings";
import IPChecklist from "./components/intendedParent/IPChecklist";
import IPDocuments from "./components/intendedParent/IPDocuments";
import IPDonorPhotos from "./components/intendedParent/IPDonorPhotos";
import IPSettings from "./components/intendedParent/IPSettings";
import FreezeAppFAQ from "./components/faq/FreezeAppFAQ";
import FreezeAppSubmittedFAQ from "./components/faq/FreezeAppSubmittedFAQ";
import FamilyFAQ from "./components/faq/FamilyFAQ";
import { setRefreshUser, setRequest } from "./util/helpers";
import FreezeLoginPage from "./components/auth/FreezeLoginPage";
import FamilyLoginPage from "./components/auth/FamilyLoginPage";
import FreezeSignupPage from "./components/auth/FreezeSignupPage";
import FamilySignupPage from "./components/auth/FamilySignupPage";
import FreezeActivationPage from "./components/auth/FreezeActivationPage";
import FamilyActivationPage from "./components/auth/FamilyActivationPage";
import StaffLoginPage from "./components/auth/StaffLoginPage";
import KeepChecklist from "./components/keepMember/KeepChecklist";
import KeepSettings from "./components/keepMember/KeepSettings";
import PDFProfile from "./components/staff/split_members/split_member/pdf_profile";
import { PageNotFoundCatchAll } from "./components/errors/RootErrorBoundary";
import { RootFallback } from './components/errors/RootErrorBoundary';
import LandingPage from "./components/auth/LandingPage";
import Searches from "./components/intendedParent/Searches";


export const AppContext = createContext(null)

const App = () => {
    const [loggedInUser, setLoggedInUser] = useState(null)
    const { isLoading, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate()

    if (isLoading) {
        return <div></div>;
    }

    const request = setRequest(navigate, getAccessTokenSilently)
    const refreshUser = setRefreshUser(navigate, setLoggedInUser, request)

    return (
        <AppContext.Provider value={{ loggedInUser, setLoggedInUser, refreshUser, request }}>
            <ErrorBoundary FallbackComponent={RootFallback}>
                <Routes>
                    <Route exact path="/callback" element={<CallbackPage />} />
                    <Route exact path="/callback/" element={<CallbackPage />} />
                    <Route exact path="/redirect" element={<AuthenticationGuard component={Redirect} />} />
                    <Route exact path="/redirect/" element={<AuthenticationGuard component={Redirect} />} />

                    <Route exact path="/freeze/login" element={<FreezeLoginPage />} />
                    <Route exact path="/freeze/login/" element={<FreezeLoginPage />} />
                    <Route exact path="/freeze/signup" element={<FreezeSignupPage />} />
                    <Route exact path="/freeze/signup/" element={<FreezeSignupPage />} />
                    <Route exact path="/freeze/activation" element={<FreezeActivationPage />} />
                    <Route exact path="/freeze/activation/" element={<FreezeActivationPage />} />

                    <Route path="/freeze/welcome" element={<AuthenticationGuard component={WelcomeFlow} />} />
                    <Route path="/freeze/welcome/" element={<AuthenticationGuard component={WelcomeFlow} />} />
                    <Route path="/freeze/timeline" element={<AuthenticationGuard component={Timeline} />} />
                    <Route path="/freeze/timeline/" element={<AuthenticationGuard component={Timeline} />} />
                    <Route path="/freeze/application/faq" element={<AuthenticationGuard component={FreezeAppFAQ} />} />
                    <Route path="/freeze/application/faq/" element={<AuthenticationGuard component={FreezeAppFAQ} />} />
                    <Route path="/freeze/application" element={<AuthenticationGuard component={Application} />} />
                    <Route path="/freeze/application/" element={<AuthenticationGuard component={Application} />} />

                    <Route path="/freeze" element={<AuthenticationGuard component={Dashboard} />}>
                        <Route index path="dashboard" element={<SMChecklist />} />
                        <Route path="profile/photos/upload" element={<SMPhotos />} />
                        <Route path="profile" element={<SMProfile />} />
                        <Route path="documents" element={<SMDocuments />} />
                        <Route path="settings" element={<SMSettings />} />
                        <Route path="faq" element={<AuthenticationGuard component={FreezeAppSubmittedFAQ} />} />
                    </Route>
                    
                    <Route exact path="/family/login" element={<FamilyLoginPage />} />
                    <Route exact path="/family/login/" element={<FamilyLoginPage />} />
                    <Route exact path="/family/signup" element={<FamilySignupPage />} />
                    <Route exact path="/family/signup/" element={<FamilySignupPage />} />
                    <Route exact path="/family/activation" element={<FamilyActivationPage />} />
                    <Route exact path="/family/activation/" element={<FamilyActivationPage />} />

                    <Route exact path="/family/application" element={<AuthenticationGuard component={Intakes} />} />
                    <Route exact path="/family/application/" element={<AuthenticationGuard component={Intakes} />} />

                    <Route path="/family" element={<AuthenticationGuard component={FamilyDashboard} />} >
                        <Route index path="checklist" element={<IPChecklist />} />
                        <Route path="donors/:userId/photos" element={<IPDonorPhotos />} />
                        <Route path="donors/:userId" element={<DonorProfile />} />
                        <Route path="favorites" element={<Favorites tab="favorites" />} />
                        <Route path="filters" element={<Searches tab="searches" />} />
                        <Route path="donors" element={<DonorSearch />} />
                        <Route path="documents" element={<IPDocuments />} />
                        <Route path="settings" element={<IPSettings />} />
                        <Route path="faq" element={<AuthenticationGuard component={FamilyFAQ} />} />
                    </Route>

                    <Route path="/keep" element={<AuthenticationGuard component={KeepDashboard} />}>
                        <Route index path="checklist" element={<AuthenticationGuard component={KeepChecklist} />} />
                        <Route path="settings" element={<KeepSettings />} />
                        <Route path="faq" element={<AuthenticationGuard component={FreezeAppSubmittedFAQ} />} />
                    </Route>

                    <Route exact path="/staff/login" element={<StaffLoginPage />} />
                    
                    <Route exact path="/staff" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMembers", tab: "splitMembers" }} />} />
                    <Route exact path="/staff/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMembers", tab: "splitMembers" }} />} />
                    <Route exact path="/staff/split-members" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMembers", tab: "splitMembers" }} />} />
                    <Route exact path="/staff/split-members/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMembers", tab: "splitMembers" }} />} />
                    <Route exact path="/staff/split-members/edit-requests" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMembers", tab: "editRequests" }} />} />
                    <Route exact path="/staff/split-members/edit-requests/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMembers", tab: "editRequests" }} />} />
                    <Route exact path="/staff/split-members/photo-requests" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMembers", tab: "photoRequests" }} />} />
                    <Route exact path="/staff/split-members/photo-requests/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMembers", tab: "photoRequests" }} />} />
                    <Route exact path="/staff/split-members/check-ins" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMembers", tab: "checkIns" }} />} />
                    <Route exact path="/staff/split-members/check-ins/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMembers", tab: "checkIns" }} />} />

                    <Route exact path="/staff/split-members/:userId" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMember", tab: "overview" }} />} />
                    <Route exact path="/staff/split-members/:userId/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMember", tab: "overview" }} />} />
                    <Route exact path="/staff/split-members/:userId/overview" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMember", tab: "overview" }} />} />
                    <Route exact path="/staff/split-members/:userId/overview/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMember", tab: "overview" }} />} />
                    <Route exact path="/staff/split-members/:userId/profile" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMember", tab: "profile" }} />} />
                    <Route exact path="/staff/split-members/:userId/profile/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMember", tab: "profile" }} />} />
                    <Route exact path="/staff/split-members/:userId/public-profile/photos" element={<StaffAuthenticationGuard component={Staff} options={{ page: "publicProfilePhotos" }} />} />
                    <Route exact path="/staff/split-members/:userId/public-profile/photos/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "publicProfilePhotos" }} />} />
                    <Route exact path="/staff/split-members/:userId/public-profile" element={<StaffAuthenticationGuard component={Staff} options={{ page: "publicProfile" }} />} />
                    <Route exact path="/staff/split-members/:userId/public-profile/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "publicProfile" }} />} />
                    <Route exact path="/staff/split-members/:userId/ip-pdf" element={<StaffAuthenticationGuard component={PDFProfile} options={{ admin: false }} />} />
                    <Route exact path="/staff/split-members/:userId/ip-pdf/" element={<StaffAuthenticationGuard component={PDFProfile} options={{ admin: false }}  />} />
                    <Route exact path="/staff/split-members/:userId/admin-pdf" element={<StaffAuthenticationGuard component={PDFProfile} options={{ admin: true }} />} />
                    <Route exact path="/staff/split-members/:userId/admin-pdf/" element={<StaffAuthenticationGuard component={PDFProfile} options={{ admin: true }}  />} />
                    <Route exact path="/staff/split-members/:userId/activity" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMember", tab: "activity", subtab: "favorites" }} />} />
                    <Route exact path="/staff/split-members/:userId/activity/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMember", tab: "activity", subtab: "favorites" }} />} />
                    <Route exact path="/staff/split-members/:userId/activity/favorites" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMember", tab: "activity", subtab: "favorites" }} />} />
                    <Route exact path="/staff/split-members/:userId/activity/favorites/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMember", tab: "activity", subtab: "favorites" }} />} />
                    <Route exact path="/staff/split-members/:userId/activity/views" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMember", tab: "activity", subtab: "views" }} />} />
                    <Route exact path="/staff/split-members/:userId/activity/views/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMember", tab: "activity", subtab: "views" }} />} />
                    <Route exact path="/staff/split-members/:userId/documents" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMember", tab: "documents" }} />} />
                    <Route exact path="/staff/split-members/:userId/documents/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMember", tab: "documents" }} />} />
                    <Route exact path="/staff/split-members/:userId/notes" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMember", tab: "notes" }} />} />
                    <Route exact path="/staff/split-members/:userId/notes/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMember", tab: "notes" }} />} />
                    <Route exact path="/staff/split-members/:userId/photos" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMember", tab: "photos" }} />} />
                    <Route exact path="/staff/split-members/:userId/photos/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMember", tab: "photos" }} />} />
                    <Route exact path="/staff/split-members/:userId/logs" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMember", tab: "logs" }} />} />
                    <Route exact path="/staff/split-members/:userId/logs/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMember", tab: "logs" }} />} />
                    <Route exact path="/staff/split-members/:userId/checklist" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMember", tab: "checklist" }} />} />
                    <Route exact path="/staff/split-members/:userId/checklist/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "splitMember", tab: "checklist" }} />} />

                    <Route exact path="/staff/intended-parents" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParents", tab: "intendedParents" }} />} />
                    <Route exact path="/staff/intended-parents/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParents", tab: "intendedParents" }} />} />
                    <Route exact path="/staff/intended-parents/edit-requests" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParents", tab: "editRequests" }} />} />
                    <Route exact path="/staff/intended-parents/edit-requests/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParents", tab: "editRequests" }} />} />
                    <Route exact path="/staff/intended-parents/check-ins" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParents", tab: "checkIns" }} />} />
                    <Route exact path="/staff/intended-parents/check-ins/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParents", tab: "checkIns" }} />} />

                    <Route exact path="/staff/intended-parents/:userId" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParent", tab: "overview" }} />} />
                    <Route exact path="/staff/intended-parents/:userId/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParent", tab: "overview" }} />} />

                    <Route exact path="/staff/intended-parents/:userId/activity" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParent", tab: "activity", subtab: "favorites" }} />} />
                    <Route exact path="/staff/intended-parents/:userId/overview" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParent", tab: "overview" }} />} />
                    <Route exact path="/staff/intended-parents/:userId/activity/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParent", tab: "activity", subtab: "favorites" }} />} />
                    <Route exact path="/staff/intended-parents/:userId/overview" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParent", tab: "overview" }} />} />
                    <Route exact path="/staff/intended-parents/:userId/activity/favorites" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParent", tab: "activity", subtab: "favorites" }} />} />
                    <Route exact path="/staff/intended-parents/:userId/activity/favorites/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParent", tab: "activity", subtab: "favorites" }} />} />
                    <Route exact path="/staff/intended-parents/:userId/activity/viewed" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParent", tab: "activity", subtab: "viewed" }} />} />
                    <Route exact path="/staff/intended-parents/:userId/activity/viewed/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParent", tab: "activity", subtab: "viewed" }} />} />
                    <Route exact path="/staff/intended-parents/:userId/activity/searches" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParent", tab: "activity", subtab: "searches" }} />} />
                    <Route exact path="/staff/intended-parents/:userId/activity/searches/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParent", tab: "activity", subtab: "searches" }} />} />
                    <Route exact path="/staff/intended-parents/:userId/documents" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParent", tab: "documents" }} />} />
                    <Route exact path="/staff/intended-parents/:userId/documents/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParent", tab: "documents" }} />} />
                    <Route exact path="/staff/intended-parents/:userId/notes" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParent", tab: "notes" }} />} />
                    <Route exact path="/staff/intended-parents/:userId/notes/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParent", tab: "notes" }} />} />
                    <Route exact path="/staff/intended-parents/:userId/photos" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParent", tab: "photos" }} />} />
                    <Route exact path="/staff/intended-parents/:userId/photos/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParent", tab: "photos" }} />} />
                    <Route exact path="/staff/intended-parents/:userId/logs" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParent", tab: "logs" }} />} />
                    <Route exact path="/staff/intended-parents/:userId/logs/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "intendedParent", tab: "logs" }} />} />

                    <Route exact path="/staff/matches" element={<StaffAuthenticationGuard component={Staff} options={{ page: "matches" }} />} />
                    <Route exact path="/staff/matches/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "matches" }} />} />
                    <Route exact path="/staff/matches/:matchId" element={<StaffAuthenticationGuard component={Staff} options={{ page: "match" }} />} />
                    <Route exact path="/staff/matches/:matchId/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "match" }} />} />

                    <Route exact path="/staff/clinics" element={<StaffAuthenticationGuard component={Staff} options={{ page: "clinics" }} />} />
                    <Route exact path="/staff/clinics/:clinicId" element={<StaffAuthenticationGuard component={Staff} options={{ page: "clinic", tab: "staff" }} />} />
                    <Route exact path="/staff/clinics/:clinicId/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "clinic", tab: "staff" }} />} />
                    <Route exact path="/staff/clinics/:clinicId/staff" element={<StaffAuthenticationGuard component={Staff} options={{ page: "clinic", tab: "staff" }} />} />
                    <Route exact path="/staff/clinics/:clinicId/staff/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "clinic", tab: "staff" }} />} />
                    <Route exact path="/staff/clinics/:clinicId/intended-parents" element={<StaffAuthenticationGuard component={Staff} options={{ page: "clinic", tab: "intended-parents" }} />} />
                    <Route exact path="/staff/clinics/:clinicId/intended-parents/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "clinic", tab: "intended-parents" }} />} />
                    <Route exact path="/staff/clinics/:clinicId/matches" element={<StaffAuthenticationGuard component={Staff} options={{ page: "clinic", tab: "matches" }} />} />
                    <Route exact path="/staff/clinics/:clinicId/matches/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "clinic", tab: "matches" }} />} />

                    <Route exact path="/staff/account" element={<StaffAuthenticationGuard component={Staff} options={{ page: "settings" }} />} />
                    <Route exact path="/staff/account/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "settings" }} />} />

                    <Route path="/" element={<LandingPage />} />
                    <Route path="*" element={PageNotFoundCatchAll()} />
                </Routes>
            </ErrorBoundary>
        </AppContext.Provider>
    );
};

export default App;
